.kpi-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  padding: 0 3%;
}
.kpi-left-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-left: 4px;
  /* margin-right: -4px; */
  margin-bottom: 4px;
  /* height: 698px; */
  width: 75%;
  /* justify-content: space-between; */
}
.kpi-right-div {
  display: "flex";
  flex-direction: column;
  width: 25%;
  flex-wrap: wrap;
  margin-top: "0px";
  margin-left: "0px";
  height: "698px";
  margin-right: 4px;
  margin-bottom: 4px;
}

@media (max-width: 1440px) and (min-width: 1050px) {
  .kpi-left-div {
    width: 65%;
  }
  .kpi-right-div {
    width: 35%;
  }
}
