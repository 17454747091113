.metric-name {
    font-size: 20px;
    font-weight: 800;
    color: black;
}

.metric-score {
    /* float: left;
   */
    width: 100%;
    float: left;
    font-size: 28px;
    font-weight: 800;

}

.metric-stat {
    float: left;
    font-size: 15px;
}


#dashboard-metrics {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-height: 80px;
    min-width: 145px;
}

#dashboard-metrics .metric-score {
    /* font-size: 44px;
    font-weight: 600;
    line-height: 44px; */
    width: 100%;
    float: left;

}


.metric-red-arrow {
    color: #EB5757;
}

.metric-green-arrow {
    color: #196F3D;
}

.dashboard-page-content {
    max-height: 100vh;
    min-height: 100vh;
    overflow: scroll;
    padding: 32px 16px;
    background: #EFEFEF
}

.metric-nochange {
    color: black;
    padding-top: 5px !important
}
.metric-unit{
    line-height: 24px;
    font-size: 19px;
    font-weight: 500;
}

.widgets-container {
    display: flex;
    flex-direction: column;

}

.metric-desc {

    text-transform: lowercase;
    /* color: #27AE60; */
    font-weight: 500;

}

/* Extra Large screens 100 % zoom*/

@media (max-width: 1920px) {


    /* CSS styles for extra large screens */
    .metric-stat-dash {
        float: left;
        font-size: 17px;
    }
}

/* Extra Large screens 125 % zoom*/

@media only screen and (max-width: 1536px) {
    .metric-name {
        font-size: 17px;
        font-weight: 500;
        color: black;
    }

    .metric-stat-dash {
        float: left;
        font-size: 14px;
    }

    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-height: 60px;
        height: 80px;
    }
}

/* Extra Large screens 150 % zoom*/

@media (max-width: 1440px) {
    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-height: 60px;
    }

    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        /* min-height: 136px; */
        height: 80px;
    }

    .metric-score {

        width: 100%;
        float: left;
        font-size: 25px;
        font-weight: 800;
    }

    .metric-name {
        font-size: 12px;
        font-weight: 500;
        color: black;
    }

    .metric-stat-dash {
        float: left;
        font-size: 13px;
    }

}