.power-flow-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.power-flow-resizable-chart {
  width: 895px;
  height: 380px;
}

@media (max-width: 1440px) and (min-width: 1050px) {
  .power-flow-resizable-chart {
    width: 560px;
    height: 300px;
  }
}
.legend{
  margin-bottom: 20px;
}