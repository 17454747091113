.network-container {
    /* margin-top: 8px; */
    width: 100%;
    background: #FFFFFF;
    height: 558px !important;
    padding: 32px;
}

.mg-card-update-notification-network {
    padding-left: 20px;
    padding-top: 6px;
    height: 14px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: #686868;
}

.network-container-title {
    display: flex;
    justify-content: space-between;
}

.network-chart-title {
    padding-left: 22px;
    padding-top: 0px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    width: 330px;
    display: flex;

    text-align: left;
}