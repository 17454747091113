/* #load-profiles-tab-content.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__content {
    background-color: #ebebeb !important;
    display: "flex";
    flex-wrap: "wrap"
} */
#load-profiles-tab-content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    /* width: max-content; */
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__content {
    background-color: #ebebeb !important;
}

#mg-dashboard-tabs-container .ABB_CommonUX_TabControl__header {
    padding-left: 24px !important;
    /* padding-top: 16px !important; */
    width: inherit;

}



#mg-dashboard-tabs-container .ABB_CommonUX_TabControl__content {
    /* ABB_CommonUX_TabControl__tabItemRoot.ABB_CommonUX_TabControl__activeContent { */
    width: -webkit-fill-available;
    overflow: hidden !important;
    padding-left: 24px !important;
  
   
    /* padding-top: 16px !important; */
}

#mg-dashboard-tabs-container{
    min-width: 1400px;
    min-height: 500px;
} 
/* 
.internal-filter-dropdown {
    width: 100%;
    flex-direction: row;
    padding: 10px 8px;
    display: inline-flex;
    margin-left: 0px !important;
} */


.internal-filter-dropdown .css-1llg279-menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
    background-color: hsl(0, 0%, 100%);
    border-radius: 0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
    margin-bottom: 8px;
    margin-top: 1px;
    box-sizing: border-box;
    margin-left: 0px !important;
    height: 100px;
}

.internal-filter-dropdown .css-cz4p66-MenuList {
    max-height: 130px !important;
    overflow-y: auto;
    /* position: inherit; */
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
    white-space: pre;
    z-index: 99999 !important;
    height: 100px;
}