.energy-monitoring .indicator-container-line>.ABB_CommonUX_Dropdown__root {
    font-size: 14px;
    text-align: left;
    padding: 8px;
    max-width: 182px;
}

.energy-moni-chart {
    width: 1200px;
    height: 280px;
    margin-top: -30px;

}

.energy-monitoring {
    width: 100%;
  
}

@media only screen and (min-width: 1200px) {

    .energy-monitoring .indicator-container-line>.ABB_CommonUX_Dropdown__root {
        font-size: 14px;
        text-align: left;
        padding: 8px;
        max-width: 182px;

    }

    .energy-moni-chart {
        width: 1100px;
        height: 280px;
        margin-left: -50px;
    }

}