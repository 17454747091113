.settings-page-content {
    width: 100%;
    max-height: 100vh;
    min-height: 100vh;
    /* overflow: scroll; */
    padding: 24px 36px;

}

.go-back {
    cursor: pointer;
    text-align: left;
    margin: 0px 16px;
    width: auto;
    font-weight: 600;
    font-size: 18px;
}

.opt-input {
    text-align: left;
    margin: 16px 20px;
    width: auto;
    font-weight: 600;
    font-size: 18px;
}



.manage-user-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    background: #EBEBEB;
}

.manage-substaion-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    background: #EBEBEB;
}

#mg-input-params-tabs-container .ABB_CommonUX_TabControl__content {
    /* ABB_CommonUX_TabControl__tabItemRoot.ABB_CommonUX_TabControl__activeContent { */
    width: -webkit-fill-available;
    overflow: hidden !important;
}

#mg-input-params-tabs-container {
    width: 100%;
    padding-left: 24px;
}