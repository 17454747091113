.divider {
  border: 1px solid lightgray;
  padding-left: 5px;
}
.number {
  font-size: -webkit-xxx-large;
  text-align: right;
  width: 86%;
}
.consumption-by-occupancy-container {
  margin-top: auto;
  margin-bottom: auto;
}
