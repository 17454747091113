.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__loading:not(.ABB_CommonUX_LoginScreen__noMaxHeight) {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
}


.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light.login-container.ABB_CommonUX_LoginScreen__loading {
    width: 100% !important;
}

/* 
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light {
    background: url(http://localhost:3000/static/media/bg-light-6a790b8….6a790b8….png) no-repeat !important;
} */

.login-container {

    max-height: 100% !important;
    margin: 150px auto !important;
}

.ABB_CommonUX_LoginScreen__root {

    min-height: 360px;
    overflow: visible !important;
    /* overflow: hidden; */
    /* position: relative; */
    /* width: 720px;*/
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header {
    display: block;
    height: 50px;
    position: relative !important;
    text-align: center;
    /* top: 16px; */
    width: 100%;
    float: left;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
    background-image: url(../../assets/Logo.png) !important;
    /* background-size: auto; */
    height: 60px;
    position: relative;
    width: 240px;
    float: right;
    max-width: 240px;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
    /* background-size: 130px auto; */
    /* background-position: 0px 0px; */
    max-height: 50px;
    position: relative;
    width: 130px;
    float: right;
    max-width: 240px;
    top: 0px;
    height: 100%;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header {
    display: block;
    height: 50px;
    position: relative !important;
    text-align: center;
    /* top: 16px; */
    width: 100%;
    float: left;
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper {
    box-sizing: border-box;
    /* min-height: 360px; */
    padding: 30px 32px 0;
    position: relative;
    float: left;
    width: 100%;
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__footer {
    background: #fff;
    border-top: 1px solid silver;
    color: #1f1f1f;
    float: left;
    position: relative;
}

/* .ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light {
    background: none !important;
} */

.ABB_CommonUX_LoginScreen__formRow {
    text-align: left;
}

.ABB_CommonUX_LoginScreen__productName {
    text-align: left;
}


.login-container .ABB_CommonUX_LoginScreen__formRow .ABB_CommonUX_Checkbox__monochrome {
    display: none;
}

.incorrect {
    vertical-align: middle;
}

.ad-container {
    display: flex;
    flex-direction: row;
}

#abb-login-container .ABB_CommonUX_LoginScreen__formRow .ABB_CommonUX_Input__light {
    width: 300px;
}