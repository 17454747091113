.cls-input-field {

    font-size: 14px;
    font-weight: 400;

}

.edit-user-container {
    overflow-y: auto;
    max-height: 850px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    font-weight: 400;
    font-size: 14px;
}

.inside-header {
    text-align: left;
    width: auto;
    font-weight: bold;
    font-size: 14px;
}

.ip-nw-file {
    border: 1px solid #ccc;
    /* Light gray border */
    padding: 5px;
    border-radius: 4px;
    /* Rounded corners */
    background-color: #f9f9f9;
    /* Light background */
    cursor: pointer;
    margin: 4px;
}

.nw-file-upload-notification {
    padding-top: 6px;
    height: 14px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: #686868;
}

.ip-nw-file:hover {
    border-color: #888;
    /* Darker border on hover */
}

.uploaded {

    color: #0CA919;
    margin: 2px 10px;

}

.edit-user-col {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

#edit-user-table tr {
    padding-top: 0;
}

#edit-user-table {
    display: flex;
    flex-direction: column;
}

.nw-section {
    display: flex;
    flex-direction: column;
}