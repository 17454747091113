#energy-cost-compare thead {

    grid-template-columns: none !important;
    grid-template-columns: 48px 0.4fr 0.6fr 0.7fr !important
}

#energy-cost-compare tbody {

    grid-template-columns: none !important;
    grid-template-columns: 48px 0.5fr 0.5fr 0.4fr !important
}


#energy-data-asset thead {
    grid-template-columns: none !important;
    grid-template-columns: 1fr 1fr 1fr !important
}

#energy-data-asset tbody {
    grid-template-columns: none !important;
    grid-template-columns: 1fr 1fr 1fr !important
}