.home-container {
  display: flex;
  /* flex-wrap: wrap; */
  /* margin: 8px; */
  justify-content: center;
  /* flex-direction: row; */
  /* background-color: blue; */
  overflow-y: hidden;
}

.home-container-left-div {
  display: flex;
  flex-direction: column;
  margin: 8px;
  /* padding: 8px; */
  width: 50%;
  /* height: 855px; */
}

.home-container-right-div {
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: 50%;
  /* margin: 8px; */
  /* height: 855px; */
}
