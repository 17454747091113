.weather-container {
  width: 100%;
  text-align: left;
  margin: 4px;
  padding-left: 6%;
}

.cloud-icon {
  text-align: center;
}

.cloud-img {
  width: 130px;
  height: 130px;
}
