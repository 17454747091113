#energy-audit-summary thead {

    grid-template-columns: none !important;
    grid-template-columns: 48px 1fr 1fr !important
}

#energy-audit-summary tbody {

    grid-template-columns: none !important;
    grid-template-columns: 48px 1fr 1fr !important
}