#proofer-datagrid {
    width: 229vh;
    height: 800px;
}
#ic-refresh:hover{
    cursor: pointer;
}
.filter-header-proof{
    color: azure;
    text-align: left;
    font-size:18px;
    font-weight: 700;
    left: 32px;
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead>tr:not(.ABB_CommonUX_Datagrid__columnFilterRow)>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):not(.ABB_CommonUX_Datagrid__isSorted) {
    color: azure;
}

/* #proofer-datagrid .ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead {
    grid-template-columns:unset; 
} */

.proofer-datagrid .ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead {
    grid-template-columns: 2fr 1fr 1fr 1.2fr 0.7fr 0.7fr 1fr !important;
    

}


.proofer-datagrid .ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody {
    grid-template-columns: 2fr 1fr 1fr 1.2fr 0.7fr 0.7fr 1fr !important
}