.table-style {
  width: 100%;
  position: relative;
  height: 230px;
  background: #FFFFFF;
}

.dist-records-rows {
  cursor: pointer;
  color: blue;
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight thead {
  grid-template-columns: 1.1fr 2fr !important;
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight tbody {
  grid-template-columns: 1.1fr 2fr !important;
}

.open-window .ABB_CommonUX_Icon__icon_abb_16 {
  font-size: 14px !important;
}


.dialog-container {
  width: 500px !important;
  height: auto;
  position: absolute !important;

  top: 16% !important;
  left: 37% !important;
  display: flex !important;
  flex-direction: column !important;
  /* background-color: #3498db; */
  z-index: 100 !important;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.dialog-section-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.analysis-failed-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.analysis-failed-dropdowns {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 195px;
  justify-content: space-evenly;
  overflow-y: scroll;
}

.dialog-section-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dialog-content span {
  padding-top: 10px;
}

.dr-reason {
  padding-top: 0px;
  font-size: 14px;
  font-weight: 600;
}

.charged {
  color: #0CA919;
  margin: 0px 5px;
}

.dialog-container .ABB_CommonUX_Dialog__header {
  text-align: left;
}

.dialog-container .ABB_CommonUX_Dialog__standardButtonsOnBottom {
  justify-content: flex-start;
}

.dialog-container .button-lists .ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize {
  height: 32px;
  width: auto !important;
  display: flex;
}

.dialog-container .ABB_CommonUX_Button__root>div>span.ABB_CommonUX_Button__text {
  line-height: 16px;
  text-align: center;
  padding-top: 0px;

}