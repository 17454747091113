.chart-card {
    display: flex;
    flex-direction: column;
    /* width: 24%; */
    max-width: 800px !important;
    /* min-width: 342px; */
    height: 320px !important;
    margin: 8px;
    justify-content: left;

    background: #FFFFFF!important;
}

.chart-header {
    left: 32px;
    right: 32px;
    top: 11.03%;
    bottom: 78.28%;
    width: 100%;
    display: flex;
}

/* .chart-title {
   
    padding: 4%;
    height: 14px;
    left: 32px;
    right: 258px;
    top: 35px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
   

    display: flex;
   
    text-transform: uppercase;

    color: #1F1F1F;
} */

.chart-title {
    padding: 16px 32px 12px 32px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    text-transform: uppercase;
    color: #1F1F1F;
}

.card-update-notification {
    padding: 5% 5% 5% 13%;
    height: 14px;
    left: 60.62%;
    right: 7.08%;
    top: 32px;

    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    display: flex;
    /* align-items: center; */
    text-align: right;

    color: #686868;
}

.card-header-divider {
    border: 1px solid red;

}

.card-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
   
    overflow: auto;

}

.card-content-scroll {
    overflow-y: scroll;

}

.card-content-scroll .ABB_CommonUX_Table__root {
    width: 100%;
}

hr {
    border: 1px solid black;
    margin: 0 32px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.card-content-even-align {
    justify-content: space-evenly;
}

.event-log-chart-header-update {
    padding-left: 3%;
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>th {
    padding: 8px 0px;
    line-height: 22px;
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td {
    padding: auto 0px;
}


.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>a>.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label {
    font-size: 24px;
    alignment-baseline: auto;
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: visible;
}


.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart {
    display: block;
    flex: 1 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 96px;
    min-width: 96px;
    overflow: hidden;
    margin: auto 0px;
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg {
    height: 100%;
    left: 0;
    overflow: visible;
    top: 0;
    width: 100%;
    max-width: 124px;
    max-height: 124px;
}


/* 
.ABB_CommonUX_Table__root th {
    text-align: left;
}


.ABB_CommonUX_Table__root td {
    text-align: left;
    padding: 8px 0px; 
    line-height: 22px; 
}


.ABB_CommonUX_Table__root td:nth-child(2) {
    text-align: left;
}  */