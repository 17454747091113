.load-profile-chart-container {
    background: rgb(255, 255, 255);
    padding: 8px;
    margin: 26px;
    width: 15%;
    min-width: 400px;
    height: 200px;
}

.load-profile-chart {
    width: 405px;
    height: 250px;
    top: -40px;
}

/* 
.load-profile-chart-container .indicator-container-line>.ABB_CommonUX_Dropdown__root {
    font-size: 14px;
    text-align: left;
    padding: 8px 16px 0px 0;
    max-width: 182px;
    min-width: 150px;

} */