.dist-events-bar .recharts-default-legend {
    padding: 0px !important;
    margin: 0px !important;
    text-align: right !important;
    text-transform: capitalize;
}

.text-color-class {
    color: black;
}

.text-rotate {
    writing-mode: vertical-rl;
}

.recharts-tooltip-item {
    color: black !important;
}

.recharts-default-tooltip {
    text-align: left;
}

.recharts-tooltip-label {
    font-weight: 600;
}

.recharts-layer .recharts-cartesian-axis .recharts-xAxis .xAxis>.recharts-text .recharts-cartesian-axis-tick-value {
    font-size: 10px !important;
}