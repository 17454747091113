.battery-soc-chart-container {
    background: rgb(255, 255, 255);
    padding: 8px;
    margin: 26px;
    width: 15%;
    min-width: 400px;
    height: 200px;
}

.battery-soc-chart {
    width: 415px;
    height: 250px;
    top: -40px;
}