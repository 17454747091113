.sld-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25px;
  width: 100px;
  justify-content: center;
  background-color: green;
  border: 2px solid black;
  padding: 5px;
  color: white;
}

.power-box.opened {
  border-color: green;
}

.power-box.closed {
  border-color: red;
}

.power-box.tripped {
  border-color: yellow;
}

.power-box.isIsolated {
  border-color: blue;
}

.power-device-content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
