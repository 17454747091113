tbody {
  width: 100%;
  /* font-size: 12px;
  padding: 10px;
  line-height: 1.5; */
}

tr {
  width: 100%;
}

.siteInfoName {
  text-align: left;
}

.siteInfoValue {
  text-align: right;
}

#site-info {
  height: 35%;
  /* justify-content: space-between; */
  /* width: 407px;
  margin-right: -41px; */
}
