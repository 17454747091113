.popup {
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;
    margin-top: 40px;
}

/* 
button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

button:hover {
    background-color: #0056b3;
} */

form {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-top: 10px;
}

#proofer-form-container input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#proofer-form-container input[type="file"] {
    padding: 3px;
}

.tech-requirements,
.comm-requirements {
    display: flex;
    justify-content: space-between;
}

.loading {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
}

.ABB_CommonUX_Dialog__title.ABB_CommonUX_Dialog__titleWithCloseButton {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: azure
}

#popup-header {
    font-size: 16px !important;
    font-weight: 600 !important;

}

#popup-header .ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__closeButton {
    display: block;
    color: azure;
}

.page-range {
    height: 30px;
    margin-top: 15px !important;
    margin-left: 10px;
    width: 100px;
}

.product-group-select {
    width: 100%;
    /* Full width */
    padding: 8px;
    /* Padding for inner spacing */
    margin-top: 5px;
    /* Space between label and select */
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    background-color: #f9f9f9;
    /* Light background */
}

.ABB_CommonUX_Dialog__dialog {
    margin-top: 4% !important;
}