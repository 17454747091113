.dp-predict-model{
   float: right;
   width: 560px;
   margin-left: auto;
}

/* 

.dp-predict-model.css-15g569d-control {
   height: 500px !important
   
} */