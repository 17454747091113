.outer-container {
  display: flex;
  flex-direction: row;
  margin: 4px;
  padding: 4px;
  width: 100%;
}

.inner-conatianer-1 {
  width: 50%;
  padding: 10%;
}

.inner-conatianer-2 {
  width: 50%;
  padding: 10%;
}

.number {
  font-size: -webkit-xxx-large;
  text-align: right;
  width: 86%;
}
