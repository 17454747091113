.cls-input-field {

    font-size: 14px;
    font-weight: 400;

}

.edit-user-container-obj {
    overflow-y: hidden;
    max-height: 950px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    font-weight: 400;
    font-size: 14px;
}

.edit-user-col {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

#edit-user-table tr {
    padding-top: 0;
}

#edit-user-table {
    display: flex;
    flex-direction: column;
}