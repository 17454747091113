.ABB_CommonUX_Shell_AppContainer__root {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100%;
    width: 100%
}

.ABB_CommonUX_Shell_AppContainer__root:not(.ABB_CommonUX_Shell_AppContainer__allow-scroll) {
    height: 100%;
    max-height: 100%;
    overflow: hidden
}

.ABB_CommonUX_Shell_AppContainer__root {
    grid-gap: 0 0;
    display: grid;
    gap: 0 0;
    grid-template-areas: "title-bar title-bar title-bar" "header header header" "content content content" "app-footer app-footer app-footer";
    grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: -webkit-min-content -webkit-min-content 1fr -webkit-min-content;
    grid-template-rows: min-content min-content 1fr min-content;
    position: relative
}

.ABB_CommonUX_Shell_AppContainer__root>* {
    box-sizing: border-box;
    position: relative
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__title-bar {
    grid-area: title-bar
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__header {
    grid-area: header
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content {
    grid-gap: 0 0;
    display: grid;
    gap: 0 0;
    grid-area: content;
    grid-template-areas: "empty content-header content-header" "left-pane main-content right-pane" "left-pane content-footer content-footer";
    grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
    grid-template-rows: min-content 1fr min-content;
    height: 100%;
    min-height: 0;
    min-width: 0;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__content-footer,
.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__content-header,
.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__empty,
.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__left-pane,
.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__main-content,
.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content>.ABB_CommonUX_Shell_AppContainer__right-pane {
    box-sizing: border-box;
    height: 100%;
    min-height: 0;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__empty {
    grid-area: empty;
    min-width: 0
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content-header {
    grid-area: content-header
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__left-pane {
    grid-area: left-pane
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__main-content {
    grid-area: main-content;
    overflow-x: hidden
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__right-pane {
    grid-area: right-pane
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__content-footer {
    grid-area: content-footer
}

.ABB_CommonUX_Shell_AppContainer__root .ABB_CommonUX_Shell_AppContainer__app-footer {
    grid-area: app-footer
}

.ABB_CommonUX_Shell_AppContainer__root.ABB_CommonUX_Shell_AppContainer__light.ABB_CommonUX_Shell_AppContainer__normal {
    background: #f5f5f5;
    color: #1f1f1f
}

.ABB_CommonUX_Shell_AppContainer__root.ABB_CommonUX_Shell_AppContainer__light.ABB_CommonUX_Shell_AppContainer__inverted {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Shell_AppContainer__root.ABB_CommonUX_Shell_AppContainer__dark.ABB_CommonUX_Shell_AppContainer__normal {
    background: #1f1f1f;
    color: #dbdbdb
}

.ABB_CommonUX_Shell_AppContainer__root.ABB_CommonUX_Shell_AppContainer__dark.ABB_CommonUX_Shell_AppContainer__inverted {
    background: #050505;
    color: #dbdbdb
}

.ABB_CommonUX_Shell_BrandPlate__root {
    align-items: center;
    display: flex;
    flex-direction: row
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__logo {
    display: none;
    font-size: 12px;
    height: 20px;
    margin: 20px 40px 20px 32px
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__logo.ABB_CommonUX_Shell_BrandPlate__big {
    height: 32px;
    margin: 32px
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__logo>* {
    height: 100%
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__logo.ABB_CommonUX_Shell_BrandPlate__red {
    fill: #ff000f
}

@media only screen and (min-width:768px) {
    .ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__logo {
        display: block
    }
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product {
    padding-right: 16px
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product.ABB_CommonUX_Shell_BrandPlate__prevent-wrapping {
    white-space: nowrap
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div {
    border-left: 1px solid transparent;
    padding-left: 16px
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div:first-child {
    margin-top: 9px
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div:first-child:before {
    color: #ff000f;
    content: "\2014";
    display: block
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div:last-child {
    margin-bottom: 9px
}

@media only screen and (min-width:768px) {
    .ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div {
        border-left-color: initial
    }

    .ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div:first-child:before {
        display: none
    }
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div.ABB_CommonUX_Shell_BrandPlate__product-name {
    font-weight: 700
}

.ABB_CommonUX_Shell_BrandPlate__root>.ABB_CommonUX_Shell_BrandPlate__product>div.ABB_CommonUX_Shell_BrandPlate__additional-name {
    font-weight: 100
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-logo>.ABB_CommonUX_Shell_BrandPlate__logo {
    display: block !important
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-logo>.ABB_CommonUX_Shell_BrandPlate__product>div {
    border-left-color: initial
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-cursor>.ABB_CommonUX_Shell_BrandPlate__logo,
.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-logo>.ABB_CommonUX_Shell_BrandPlate__product>div:first-child:before {
    display: none !important
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-cursor>.ABB_CommonUX_Shell_BrandPlate__product>div {
    border-left-color: transparent
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__force-cursor>.ABB_CommonUX_Shell_BrandPlate__product>div:first-child:before {
    display: block !important
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__light-inverted>.ABB_CommonUX_Shell_BrandPlate__logo:not(.ABB_CommonUX_Shell_BrandPlate__red),
.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__light>.ABB_CommonUX_Shell_BrandPlate__logo:not(.ABB_CommonUX_Shell_BrandPlate__red) {
    fill: #000
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__light-inverted>.ABB_CommonUX_Shell_BrandPlate__product.ABB_CommonUX_Shell_BrandPlate__monochrome>div:first-child:before,
.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__light>.ABB_CommonUX_Shell_BrandPlate__product.ABB_CommonUX_Shell_BrandPlate__monochrome>div:first-child:before {
    color: #1f1f1f
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__dark-inverted>.ABB_CommonUX_Shell_BrandPlate__logo:not(.ABB_CommonUX_Shell_BrandPlate__red),
.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__dark>.ABB_CommonUX_Shell_BrandPlate__logo:not(.ABB_CommonUX_Shell_BrandPlate__red) {
    fill: #fff
}

.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__dark-inverted>.ABB_CommonUX_Shell_BrandPlate__product.ABB_CommonUX_Shell_BrandPlate__monochrome>div:first-child:before,
.ABB_CommonUX_Shell_BrandPlate__root.ABB_CommonUX_Shell_BrandPlate__dark>.ABB_CommonUX_Shell_BrandPlate__product.ABB_CommonUX_Shell_BrandPlate__monochrome>div:first-child:before {
    color: #dbdbdb
}

.ABB_CommonUX_Shell_ContentHeader__root {
    padding: 24px 32px 12px 0
}

.ABB_CommonUX_Shell_ContentHeader__root>.ABB_CommonUX_Shell_ContentHeader__breadcrumb {
    margin-bottom: 4px
}

.ABB_CommonUX_Shell_ContentHeader__root>div:last-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start
}

.ABB_CommonUX_Shell_ContentHeader__root>div:last-child>.ABB_CommonUX_Shell_ContentHeader__title {
    flex: 10;
    font-size: 40px;
    line-height: 1;
    margin: 0 32px 12px 0
}

.ABB_CommonUX_Shell_ContentHeader__root>div:last-child>.ABB_CommonUX_Shell_ContentHeader__spacer {
    flex: 1;
    height: 0;
    pointer-events: none
}

.ABB_CommonUX_Shell_ContentHeader__root>div:last-child>.ABB_CommonUX_Shell_ContentHeader__contentActions {
    align-items: center;
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-end
}

.ABB_CommonUX_Shell_ContentHeader__root>div:last-child>.ABB_CommonUX_Shell_ContentHeader__contentActions>* {
    margin: 0 12px 12px 0
}

.ABB_CommonUX_Shell_Header__root {
    grid-gap: 0 0;
    display: grid;
    gap: 0 0;
    grid-template-areas: "brand primary-navi global-controls system-controls";
    grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
    grid-template-columns: min-content 1fr min-content;
    position: relative
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__revealing {
    left: 0;
    position: fixed;
    top: 0;
    transition: transform .3s ease-in-out;
    width: 100%;
    z-index: 100
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__revealing.ABB_CommonUX_Shell_Header__scrollDown {
    transform: translateY(-100%)
}

.ABB_CommonUX_Shell_Header__root>div {
    margin: auto 0
}

.ABB_CommonUX_Shell_Header__root>div.ABB_CommonUX_Shell_Header__brand-plate-container {
    grid-area: brand;
    min-width: 0
}

.ABB_CommonUX_Shell_Header__root>div.ABB_CommonUX_Shell_Header__primary-navi-container {
    grid-area: primary-navi;
    min-width: 0
}

.ABB_CommonUX_Shell_Header__root>div.ABB_CommonUX_Shell_Header__global-controls-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-area: global-controls
}

.ABB_CommonUX_Shell_Header__root>div.ABB_CommonUX_Shell_Header__global-controls-container>* {
    margin-right: 32px
}

.ABB_CommonUX_Shell_Header__root>div.ABB_CommonUX_Shell_Header__system-controls-container {
    grid-area: system-controls;
    margin: 0
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__light.ABB_CommonUX_Shell_Header__normal {
    background: #f5f5f5;
    color: #1f1f1f
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__light.ABB_CommonUX_Shell_Header__inverted {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__dark.ABB_CommonUX_Shell_Header__normal {
    background: #1f1f1f;
    color: #dbdbdb
}

.ABB_CommonUX_Shell_Header__root.ABB_CommonUX_Shell_Header__dark.ABB_CommonUX_Shell_Header__inverted {
    background: #050505;
    color: #dbdbdb
}

.ABB_CommonUX_Shell_HeaderNavi__root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 12px;
    position: relative
}

.ABB_CommonUX_Shell_HeaderNavi__root.ABB_CommonUX_Shell_HeaderNavi__left {
    justify-content: flex-start
}

.ABB_CommonUX_Shell_HeaderNavi__root.ABB_CommonUX_Shell_HeaderNavi__center {
    justify-content: center
}

.ABB_CommonUX_Shell_HeaderNavi__root.ABB_CommonUX_Shell_HeaderNavi__right {
    justify-content: flex-end
}

.ABB_CommonUX_Shell_HeaderNavi__item {
    margin-right: 24px;
    position: relative
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title {
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 16px;
    text-decoration: none
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__disabled {
    opacity: .8;
    pointer-events: none
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected.ABB_CommonUX_Shell_HeaderNavi__underline:after {
    bottom: 0;
    content: " ";
    height: 4px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected.ABB_CommonUX_Shell_HeaderNavi__underline.ABB_CommonUX_Shell_HeaderNavi__underlineRed:after {
    background: #ff000f
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected.ABB_CommonUX_Shell_HeaderNavi__underline.ABB_CommonUX_Shell_HeaderNavi__underlineBlue:after {
    background: #36f
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__underline {
    padding: 12px 0
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>.ABB_CommonUX_Shell_HeaderNavi__titleText {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>.ABB_CommonUX_Shell_HeaderNavi__titleText:after {
    content: attr(data-text);
    font-weight: 700;
    height: 0;
    visibility: hidden
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>.ABB_CommonUX_Shell_HeaderNavi__caret {
    display: flex
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>.ABB_CommonUX_Shell_HeaderNavi__caret>.ABB_CommonUX_Shell_HeaderNavi__menuBackdrop {
    bottom: 0;
    cursor: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>.ABB_CommonUX_Shell_HeaderNavi__caret>.ABB_CommonUX_Shell_HeaderNavi__menuContainer {
    position: fixed;
    transform: translate(16px, 24px);
    z-index: 1001
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title>:not(:last-child) {
    margin-right: 8px
}

.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected,
.ABB_CommonUX_Shell_HeaderNavi__item>.ABB_CommonUX_Shell_HeaderNavi__title:hover {
    font-weight: 700
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__light>.ABB_CommonUX_Shell_HeaderNavi__title {
    color: #696969
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__light>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected,
.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__light>.ABB_CommonUX_Shell_HeaderNavi__title:hover {
    color: #1f1f1f
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__light>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected.ABB_CommonUX_Shell_HeaderNavi__underline:not(.ABB_CommonUX_Shell_HeaderNavi__underlineRed):not(.ABB_CommonUX_Shell_HeaderNavi__underlineBlue):after {
    background: #1f1f1f
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__dark>.ABB_CommonUX_Shell_HeaderNavi__title {
    color: #868686
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__dark>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected,
.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__dark>.ABB_CommonUX_Shell_HeaderNavi__title:hover {
    color: #dbdbdb
}

.ABB_CommonUX_Shell_HeaderNavi__item.ABB_CommonUX_Shell_HeaderNavi__dark>.ABB_CommonUX_Shell_HeaderNavi__title.ABB_CommonUX_Shell_HeaderNavi__selected.ABB_CommonUX_Shell_HeaderNavi__underline:not(.ABB_CommonUX_Shell_HeaderNavi__underlineRed):not(.ABB_CommonUX_Shell_HeaderNavi__underlineBlue):after {
    background: #dbdbdb
}

.ABB_CommonUX_Icon-16-style__icon-abb_add-user_16:before {
    content: "\e900"
}

.ABB_CommonUX_Icon-16-style__icon-abb_alarm-bell_16:before {
    content: "\e901"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-bottom_16:before {
    content: "\e902"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-horizontal-center_16:before {
    content: "\e903"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-left_16:before {
    content: "\e904"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-right_16:before {
    content: "\e905"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-top_16:before {
    content: "\e906"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align-vertical-center_16:before {
    content: "\e907"
}

.ABB_CommonUX_Icon-16-style__icon-abb_analyze-document_16:before {
    content: "\e908"
}

.ABB_CommonUX_Icon-16-style__icon-abb_anchor_16:before {
    content: "\e909"
}

.ABB_CommonUX_Icon-16-style__icon-abb_audio-off_16:before {
    content: "\e90a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_audio-on_16:before {
    content: "\e90b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_background_16:before {
    content: "\e90c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_bar-chart_16:before {
    content: "\e90d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-charging_16:before {
    content: "\e90e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-empty_16:before {
    content: "\e90f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-full_16:before {
    content: "\e910"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-half_16:before {
    content: "\e911"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-high_16:before {
    content: "\e912"
}

.ABB_CommonUX_Icon-16-style__icon-abb_battery-low_16:before {
    content: "\e913"
}

.ABB_CommonUX_Icon-16-style__icon-abb_binary-data_16:before {
    content: "\e914"
}

.ABB_CommonUX_Icon-16-style__icon-abb_bluetooth_16:before {
    content: "\e915"
}

.ABB_CommonUX_Icon-16-style__icon-abb_boolean_16:before {
    content: "\e916"
}

.ABB_CommonUX_Icon-16-style__icon-abb_briefcase_16:before {
    content: "\e917"
}

.ABB_CommonUX_Icon-16-style__icon-abb_bring-to-front_16:before {
    content: "\e918"
}

.ABB_CommonUX_Icon-16-style__icon-abb_broadcast_16:before {
    content: "\e919"
}

.ABB_CommonUX_Icon-16-style__icon-abb_calculator_16:before {
    content: "\e91a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_calendar_16:before {
    content: "\e91b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_call_16:before {
    content: "\e91c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_camera_16:before {
    content: "\e91d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_cart_16:before {
    content: "\e91e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_certificate-document-1_16:before {
    content: "\e91f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_certificate-document-2_16:before {
    content: "\e920"
}

.ABB_CommonUX_Icon-16-style__icon-abb_chat_16:before {
    content: "\e921"
}

.ABB_CommonUX_Icon-16-style__icon-abb_check-mark_16:before {
    content: "\e922"
}

.ABB_CommonUX_Icon-16-style__icon-abb_check-mark-circle-1_16:before {
    content: "\e923"
}

.ABB_CommonUX_Icon-16-style__icon-abb_check-mark-circle-2_16:before {
    content: "\e924"
}

.ABB_CommonUX_Icon-16-style__icon-abb_close_16:before {
    content: "\e925"
}

.ABB_CommonUX_Icon-16-style__icon-abb_comment_16:before {
    content: "\e926"
}

.ABB_CommonUX_Icon-16-style__icon-abb_computer_16:before {
    content: "\e927"
}

.ABB_CommonUX_Icon-16-style__icon-abb_connect_16:before {
    content: "\e928"
}

.ABB_CommonUX_Icon-16-style__icon-abb_controller-cabinet_16:before {
    content: "\e929"
}

.ABB_CommonUX_Icon-16-style__icon-abb_controls-and-settings_16:before {
    content: "\e92a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_copy_16:before {
    content: "\e92b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_cut_16:before {
    content: "\e92c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_dashboard_16:before {
    content: "\e92d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_database_16:before {
    content: "\e92e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_database-new_16:before {
    content: "\e92f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_decimal_16:before {
    content: "\e930"
}

.ABB_CommonUX_Icon-16-style__icon-abb_devices_16:before {
    content: "\e931"
}

.ABB_CommonUX_Icon-16-style__icon-abb_disconnect_16:before {
    content: "\e932"
}

.ABB_CommonUX_Icon-16-style__icon-abb_distribution_16:before {
    content: "\e933"
}

.ABB_CommonUX_Icon-16-style__icon-abb_document_16:before {
    content: "\e934"
}

.ABB_CommonUX_Icon-16-style__icon-abb_dollar_16:before {
    content: "\e935"
}

.ABB_CommonUX_Icon-16-style__icon-abb_double-down_16:before {
    content: "\e936"
}

.ABB_CommonUX_Icon-16-style__icon-abb_double-left_16:before {
    content: "\e937"
}

.ABB_CommonUX_Icon-16-style__icon-abb_double-right_16:before {
    content: "\e938"
}

.ABB_CommonUX_Icon-16-style__icon-abb_double-up_16:before {
    content: "\e939"
}

.ABB_CommonUX_Icon-16-style__icon-abb_down_16:before {
    content: "\e93a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_down-arrow_16:before {
    content: "\e93b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_download_16:before {
    content: "\e93c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_drive_16:before {
    content: "\e93d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_edit_16:before {
    content: "\e93e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_end-call_16:before {
    content: "\e93f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_envelope_16:before {
    content: "\e940"
}

.ABB_CommonUX_Icon-16-style__icon-abb_error-circle-1_16:before {
    content: "\e941"
}

.ABB_CommonUX_Icon-16-style__icon-abb_error-circle-2_16:before {
    content: "\e942"
}

.ABB_CommonUX_Icon-16-style__icon-abb_euro_16:before {
    content: "\e943"
}

.ABB_CommonUX_Icon-16-style__icon-abb_event_16:before {
    content: "\e944"
}

.ABB_CommonUX_Icon-16-style__icon-abb_export_16:before {
    content: "\e945"
}

.ABB_CommonUX_Icon-16-style__icon-abb_factory_16:before {
    content: "\e946"
}

.ABB_CommonUX_Icon-16-style__icon-abb_filter_16:before {
    content: "\e947"
}

.ABB_CommonUX_Icon-16-style__icon-abb_folder_16:before {
    content: "\e948"
}

.ABB_CommonUX_Icon-16-style__icon-abb_folder-new_16:before {
    content: "\e949"
}

.ABB_CommonUX_Icon-16-style__icon-abb_folder-open_16:before {
    content: "\e94a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_forward_16:before {
    content: "\e94b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_fullscreen_16:before {
    content: "\e94c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_gauge_16:before {
    content: "\e94d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_gbp_16:before {
    content: "\e94e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_globe_16:before {
    content: "\e94f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_group_16:before {
    content: "\e950"
}

.ABB_CommonUX_Icon-16-style__icon-abb_help-circle-1_16:before {
    content: "\e951"
}

.ABB_CommonUX_Icon-16-style__icon-abb_help-circle-2_16:before {
    content: "\e952"
}

.ABB_CommonUX_Icon-16-style__icon-abb_hide_16:before {
    content: "\e953"
}

.ABB_CommonUX_Icon-16-style__icon-abb_history_16:before {
    content: "\e954"
}

.ABB_CommonUX_Icon-16-style__icon-abb_home_16:before {
    content: "\e955"
}

.ABB_CommonUX_Icon-16-style__icon-abb_hour-glass_16:before {
    content: "\e956"
}

.ABB_CommonUX_Icon-16-style__icon-abb_image_16:before {
    content: "\e957"
}

.ABB_CommonUX_Icon-16-style__icon-abb_import_16:before {
    content: "\e958"
}

.ABB_CommonUX_Icon-16-style__icon-abb_information-circle-1_16:before {
    content: "\e959"
}

.ABB_CommonUX_Icon-16-style__icon-abb_information-circle-2_16:before {
    content: "\e95a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_key_16:before {
    content: "\e95b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_label_16:before {
    content: "\e95c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_left_16:before {
    content: "\e95d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_left-arrow_16:before {
    content: "\e95e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_level_16:before {
    content: "\e95f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_light-bulb_16:before {
    content: "\e960"
}

.ABB_CommonUX_Icon-16-style__icon-abb_link_16:before {
    content: "\e961"
}

.ABB_CommonUX_Icon-16-style__icon-abb_list_16:before {
    content: "\e962"
}

.ABB_CommonUX_Icon-16-style__icon-abb_location_16:before {
    content: "\e963"
}

.ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16:before {
    content: "\e964"
}

.ABB_CommonUX_Icon-16-style__icon-abb_lock-open_16:before {
    content: "\e965"
}

.ABB_CommonUX_Icon-16-style__icon-abb_log-in_16:before {
    content: "\e966"
}

.ABB_CommonUX_Icon-16-style__icon-abb_log-out_16:before {
    content: "\e967"
}

.ABB_CommonUX_Icon-16-style__icon-abb_magnet_16:before {
    content: "\e968"
}

.ABB_CommonUX_Icon-16-style__icon-abb_make-same-height_16:before {
    content: "\e969"
}

.ABB_CommonUX_Icon-16-style__icon-abb_make-same-size_16:before {
    content: "\e96a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_make-same-width_16:before {
    content: "\e96b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_map_16:before {
    content: "\e96c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_matrix_16:before {
    content: "\e96d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_maximize_16:before {
    content: "\e96e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_menu_16:before {
    content: "\e96f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_menu-narrow_16:before {
    content: "\e970"
}

.ABB_CommonUX_Icon-16-style__icon-abb_microphone_16:before {
    content: "\e971"
}

.ABB_CommonUX_Icon-16-style__icon-abb_microphone-off_16:before {
    content: "\e972"
}

.ABB_CommonUX_Icon-16-style__icon-abb_minimize_16:before {
    content: "\e973"
}

.ABB_CommonUX_Icon-16-style__icon-abb_minus_16:before {
    content: "\e974"
}

.ABB_CommonUX_Icon-16-style__icon-abb_minus-in-circle_16:before {
    content: "\e975"
}

.ABB_CommonUX_Icon-16-style__icon-abb_missed-call_16:before {
    content: "\e976"
}

.ABB_CommonUX_Icon-16-style__icon-abb_moon_16:before {
    content: "\e977"
}

.ABB_CommonUX_Icon-16-style__icon-abb_more_16:before {
    content: "\e978"
}

.ABB_CommonUX_Icon-16-style__icon-abb_motor_16:before {
    content: "\e979"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network_16:before {
    content: "\e97a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_new-document_16:before {
    content: "\e97b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_numericals_16:before {
    content: "\e97c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_object_16:before {
    content: "\e97d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_object-container-1_16:before {
    content: "\e97e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_object-container-2_16:before {
    content: "\e97f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_open-in-new-window_16:before {
    content: "\e980"
}

.ABB_CommonUX_Icon-16-style__icon-abb_panel_16:before {
    content: "\e981"
}

.ABB_CommonUX_Icon-16-style__icon-abb_paper-clip_16:before {
    content: "\e982"
}

.ABB_CommonUX_Icon-16-style__icon-abb_paste_16:before {
    content: "\e983"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pause_16:before {
    content: "\e984"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pie-chart_16:before {
    content: "\e985"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pin_16:before {
    content: "\e986"
}

.ABB_CommonUX_Icon-16-style__icon-abb_play_16:before {
    content: "\e987"
}

.ABB_CommonUX_Icon-16-style__icon-abb_plugin_16:before {
    content: "\e988"
}

.ABB_CommonUX_Icon-16-style__icon-abb_plus_16:before {
    content: "\e989"
}

.ABB_CommonUX_Icon-16-style__icon-abb_plus-in-circle_16:before {
    content: "\e98a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pop-out-window_16:before {
    content: "\e98b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_power-on-off_16:before {
    content: "\e98c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pressure_16:before {
    content: "\e98d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_print_16:before {
    content: "\e98e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_pump_16:before {
    content: "\e98f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_qr-code_16:before {
    content: "\e990"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rearrange_16:before {
    content: "\e991"
}

.ABB_CommonUX_Icon-16-style__icon-abb_redo_16:before {
    content: "\e992"
}

.ABB_CommonUX_Icon-16-style__icon-abb_refresh_16:before {
    content: "\e993"
}

.ABB_CommonUX_Icon-16-style__icon-abb_reports_16:before {
    content: "\e994"
}

.ABB_CommonUX_Icon-16-style__icon-abb_resistor_16:before {
    content: "\e995"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rewind_16:before {
    content: "\e996"
}

.ABB_CommonUX_Icon-16-style__icon-abb_right_16:before {
    content: "\e997"
}

.ABB_CommonUX_Icon-16-style__icon-abb_right-arrow_16:before {
    content: "\e998"
}

.ABB_CommonUX_Icon-16-style__icon-abb_robot_16:before {
    content: "\e999"
}

.ABB_CommonUX_Icon-16-style__icon-abb_robot-cabinet_16:before {
    content: "\e99a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_robot-tool_16:before {
    content: "\e99b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-left-90_16:before {
    content: "\e99c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-left-180_16:before {
    content: "\e99d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-right-270_16:before {
    content: "\e99f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-left-270_16:before {
    content: "\e99e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-right-90_16:before {
    content: "\e9a0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rotate-right-180_16:before {
    content: "\e9a1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_save_16:before {
    content: "\e9a2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_save-as_16:before {
    content: "\e9a3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_screen_16:before {
    content: "\e9a4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_search_16:before {
    content: "\e9a5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_send-to-back_16:before {
    content: "\e9a6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_server_16:before {
    content: "\e9a7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_settings_16:before {
    content: "\e9a8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_share_16:before {
    content: "\e9a9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_shortcut_16:before {
    content: "\e9aa"
}

.ABB_CommonUX_Icon-16-style__icon-abb_shuffle_16:before {
    content: "\e9ab"
}

.ABB_CommonUX_Icon-16-style__icon-abb_skip-to-end_16:before {
    content: "\e9ac"
}

.ABB_CommonUX_Icon-16-style__icon-abb_skip-to-start_16:before {
    content: "\e9ad"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sld-1_16:before {
    content: "\e9ae"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sld-2_16:before {
    content: "\e9af"
}

.ABB_CommonUX_Icon-16-style__icon-abb_smartphone_16:before {
    content: "\e9b0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-alphabet-down_16:before {
    content: "\e9b1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-alphabet-up_16:before {
    content: "\e9b2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-date-down-1_16:before {
    content: "\e9b3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-date-down-2_16:before {
    content: "\e9b4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-date-up-1_16:before {
    content: "\e9b5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-date-up-2_16:before {
    content: "\e9b6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_specification_16:before {
    content: "\e9b7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_stairs_16:before {
    content: "\e9b8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_star_16:before {
    content: "\e9b9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_stop_16:before {
    content: "\e9ba"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sun_16:before {
    content: "\e9bb"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sun-2_16:before {
    content: "\e9bc"
}

.ABB_CommonUX_Icon-16-style__icon-abb_synchronize_16:before {
    content: "\e9bd"
}

.ABB_CommonUX_Icon-16-style__icon-abb_system_16:before {
    content: "\e9be"
}

.ABB_CommonUX_Icon-16-style__icon-abb_table_16:before {
    content: "\e9bf"
}

.ABB_CommonUX_Icon-16-style__icon-abb_temperature_16:before {
    content: "\e9c0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_temperature-celsius-1_16:before {
    content: "\e9c1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_temperature-celsius-2_16:before {
    content: "\e9c2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_temperature-fahrenheit-1_16:before {
    content: "\e9c3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_temperature-fahrenheit-2_16:before {
    content: "\e9c4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_template_16:before {
    content: "\e9c5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_template-new_16:before {
    content: "\e9c6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_test_16:before {
    content: "\e9c7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_text_16:before {
    content: "\e9c8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_time_16:before {
    content: "\e9c9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_time-and-date_16:before {
    content: "\e9ca"
}

.ABB_CommonUX_Icon-16-style__icon-abb_track-1_16:before {
    content: "\e9cb"
}

.ABB_CommonUX_Icon-16-style__icon-abb_track-2_16:before {
    content: "\e9cc"
}

.ABB_CommonUX_Icon-16-style__icon-abb_transformer_16:before {
    content: "\e9cd"
}

.ABB_CommonUX_Icon-16-style__icon-abb_trash_16:before {
    content: "\e9ce"
}

.ABB_CommonUX_Icon-16-style__icon-abb_tree-view_16:before {
    content: "\e9cf"
}

.ABB_CommonUX_Icon-16-style__icon-abb_trend-1_16:before {
    content: "\e9d0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_trend-2_16:before {
    content: "\e9d1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_turbocharger_16:before {
    content: "\e9d2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_undo_16:before {
    content: "\e9d3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_unpin_16:before {
    content: "\e9d4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_up_16:before {
    content: "\e9d5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_up-arrow_16:before {
    content: "\e9d6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_upload_16:before {
    content: "\e9d7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_usb_16:before {
    content: "\e9d8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_user_16:before {
    content: "\e9d9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_user-in-circle_16:before {
    content: "\e9da"
}

.ABB_CommonUX_Icon-16-style__icon-abb_video-camera-off_16:before {
    content: "\e9db"
}

.ABB_CommonUX_Icon-16-style__icon-abb_video-camera-on_16:before {
    content: "\e9dc"
}

.ABB_CommonUX_Icon-16-style__icon-abb_video-chat_16:before {
    content: "\e9dd"
}

.ABB_CommonUX_Icon-16-style__icon-abb_view_16:before {
    content: "\e9de"
}

.ABB_CommonUX_Icon-16-style__icon-abb_voltage_16:before {
    content: "\e9df"
}

.ABB_CommonUX_Icon-16-style__icon-abb_warning-circle-1_16:before {
    content: "\e9e0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_warning-circle-2_16:before {
    content: "\e9e1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_warning-triangle_16:before {
    content: "\e9e2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_wrench_16:before {
    content: "\e9e3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_zoom-in_16:before {
    content: "\e9e4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_zoom-out_16:before {
    content: "\e9e5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_trend-3_16:before {
    content: "\e9e6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_trend-scatter_16:before {
    content: "\e9e7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-ascending_16:before {
    content: "\e9e8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_sort-descending_16:before {
    content: "\e9e9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_advanced-settings_16:before {
    content: "\e9ea"
}

.ABB_CommonUX_Icon-16-style__icon-abb_align_16:before {
    content: "\e9eb"
}

.ABB_CommonUX_Icon-16-style__icon-abb_robot-axis_16:before {
    content: "\e9ec"
}

.ABB_CommonUX_Icon-16-style__icon-abb_calibration_16:before {
    content: "\e9ed"
}

.ABB_CommonUX_Icon-16-style__icon-abb_connection_16:before {
    content: "\e9ee"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-status_16:before {
    content: "\e9ef"
}

.ABB_CommonUX_Icon-16-style__icon-abb_create-backup_16:before {
    content: "\e9f0"
}

.ABB_CommonUX_Icon-16-style__icon-abb_data-editor_16:before {
    content: "\e9f1"
}

.ABB_CommonUX_Icon-16-style__icon-abb_execution_16:before {
    content: "\e9f2"
}

.ABB_CommonUX_Icon-16-style__icon-abb_hardware-devices_16:before {
    content: "\e9f3"
}

.ABB_CommonUX_Icon-16-style__icon-abb_io-devices_16:before {
    content: "\e9f4"
}

.ABB_CommonUX_Icon-16-style__icon-abb_joystick_16:before {
    content: "\e9f5"
}

.ABB_CommonUX_Icon-16-style__icon-abb_lead-through_16:before {
    content: "\e9f6"
}

.ABB_CommonUX_Icon-16-style__icon-abb_jog-linear_16:before {
    content: "\e9f7"
}

.ABB_CommonUX_Icon-16-style__icon-abb_document-excel_16:before {
    content: "\e9f8"
}

.ABB_CommonUX_Icon-16-style__icon-abb_document-pdf_16:before {
    content: "\e9f9"
}

.ABB_CommonUX_Icon-16-style__icon-abb_document-word_16:before {
    content: "\e9fa"
}

.ABB_CommonUX_Icon-16-style__icon-abb_modify-rapid-position_16:before {
    content: "\e9fb"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-module_16:before {
    content: "\e9fc"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-status-1_16:before {
    content: "\e9fe"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-status-2_16:before {
    content: "\e9ff"
}

.ABB_CommonUX_Icon-16-style__icon-abb_payload_16:before {
    content: "\ea00"
}

.ABB_CommonUX_Icon-16-style__icon-abb_jog-planar_16:before {
    content: "\ea01"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-program-and-modules_16:before {
    content: "\ea02"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-program-editor_16:before {
    content: "\ea03"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-program_16:before {
    content: "\ea04"
}

.ABB_CommonUX_Icon-16-style__icon-abb_program-pointer_16:before {
    content: "\ea05"
}

.ABB_CommonUX_Icon-16-style__icon-abb_rapid-tasks_16:before {
    content: "\ea06"
}

.ABB_CommonUX_Icon-16-style__icon-abb_jog-reorient_16:before {
    content: "\ea07"
}

.ABB_CommonUX_Icon-16-style__icon-abb_reset-user-data_16:before {
    content: "\ea09"
}

.ABB_CommonUX_Icon-16-style__icon-abb_restore-backup_16:before {
    content: "\ea0a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_select_16:before {
    content: "\ea0b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-routins_16:before {
    content: "\ea0c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-signal-strength-level-1_16:before {
    content: "\ea0d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-signal-strength-level-2_16:before {
    content: "\ea0e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_network-signal-strength-level-3_16:before {
    content: "\ea0f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_io-signals_16:before {
    content: "\ea10"
}

.ABB_CommonUX_Icon-16-style__icon-abb_software-resources_16:before {
    content: "\ea11"
}

.ABB_CommonUX_Icon-16-style__icon-abb_start-installer_16:before {
    content: "\ea12"
}

.ABB_CommonUX_Icon-16-style__icon-abb_step-in_16:before {
    content: "\ea13"
}

.ABB_CommonUX_Icon-16-style__icon-abb_step-out_16:before {
    content: "\ea14"
}

.ABB_CommonUX_Icon-16-style__icon-abb_step-over_16:before {
    content: "\ea15"
}

.ABB_CommonUX_Icon-16-style__icon-abb_touch_16:before {
    content: "\ea16"
}

.ABB_CommonUX_Icon-16-style__icon-abb_video_16:before {
    content: "\ea17"
}

.ABB_CommonUX_Icon-16-style__icon-abb_turn-clockwise_16:before {
    content: "\ea18"
}

.ABB_CommonUX_Icon-16-style__icon-abb_turn-clockwise-off-target-speed_16:before {
    content: "\ea19"
}

.ABB_CommonUX_Icon-16-style__icon-abb_turn-counter-clockwise-off-target-speed_16:before {
    content: "\ea1a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_add-drives_16:before {
    content: "\ea1b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_bearing_16:before {
    content: "\ea1d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_cloud_16:before {
    content: "\ea1e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_delete-drives_16:before {
    content: "\ea1f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_download-from-cloud_16:before {
    content: "\ea20"
}

.ABB_CommonUX_Icon-16-style__icon-abb_drive-connected_16:before {
    content: "\ea21"
}

.ABB_CommonUX_Icon-16-style__icon-abb_drives-error_16:before {
    content: "\ea22"
}

.ABB_CommonUX_Icon-16-style__icon-abb_fieldbus_16:before {
    content: "\ea23"
}

.ABB_CommonUX_Icon-16-style__icon-abb_generator_16:before {
    content: "\ea24"
}

.ABB_CommonUX_Icon-16-style__icon-abb_maximum-value_16:before {
    content: "\ea26"
}

.ABB_CommonUX_Icon-16-style__icon-abb_minimum-value_16:before {
    content: "\ea27"
}

.ABB_CommonUX_Icon-16-style__icon-abb_not-allowed_16:before {
    content: "\ea28"
}

.ABB_CommonUX_Icon-16-style__icon-abb_powertrain_16:before {
    content: "\ea29"
}

.ABB_CommonUX_Icon-16-style__icon-abb_prevent-turning-fault_16:before {
    content: "\ea2a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_target-value-range_16:before {
    content: "\ea2b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_thumbnail-view_16:before {
    content: "\ea2c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_train_16:before {
    content: "\ea2d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_turn-counter-clockwise_16:before {
    content: "\ea2e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_wheel_16:before {
    content: "\ea2f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_wifi-network-disconnected_16:before {
    content: "\ea31"
}

.ABB_CommonUX_Icon-16-style__icon-abb_write-to-default_16:before {
    content: "\ea32"
}

.ABB_CommonUX_Icon-16-style__icon-abb_write-to-default-disabled_16:before {
    content: "\ea33"
}

.ABB_CommonUX_Icon-16-style__icon-abb_caret-down_16:before {
    content: "\ea08"
}

.ABB_CommonUX_Icon-16-style__icon-abb_caret-left_16:before {
    content: "\ea1c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_caret-right_16:before {
    content: "\ea30"
}

.ABB_CommonUX_Icon-16-style__icon-abb_caret-up_16:before {
    content: "\ea34"
}

.ABB_CommonUX_Icon-16-style__icon-abb_triangle-down_16:before {
    content: "\ea36"
}

.ABB_CommonUX_Icon-16-style__icon-abb_triangle-left_16:before {
    content: "\ea37"
}

.ABB_CommonUX_Icon-16-style__icon-abb_triangle-right_16:before {
    content: "\ea38"
}

.ABB_CommonUX_Icon-16-style__icon-abb_triangle-up_16:before {
    content: "\ea39"
}

.ABB_CommonUX_Icon-16-style__icon-abb_check-mark-light_16:before {
    content: "\ea3a"
}

.ABB_CommonUX_Icon-16-style__icon-abb_numbered-list_16:before {
    content: "\ea3b"
}

.ABB_CommonUX_Icon-16-style__icon-abb_text-bold_16:before {
    content: "\ea3c"
}

.ABB_CommonUX_Icon-16-style__icon-abb_text-italic_16:before {
    content: "\ea3d"
}

.ABB_CommonUX_Icon-16-style__icon-abb_text-underline_16:before {
    content: "\ea3e"
}

.ABB_CommonUX_Icon-16-style__icon-abb_call-outline_16:before {
    content: "\e9fd"
}

.ABB_CommonUX_Icon-16-style__icon-abb_location-outline_16:before {
    content: "\ea25"
}

.ABB_CommonUX_Icon-16-style__icon-abb_user-outline_16:before {
    content: "\ea3f"
}

.ABB_CommonUX_Icon-16-style__icon-abb_close-light_16:before {
    content: "\ea35"
}

.ABB_CommonUX_Icon-16-style__icon-abb_smart-gripper_16:before {
    content: "\ea43"
}

.ABB_CommonUX_Icon-16-style__icon-abb_bookmark_16:before {
    content: "\f101"
}

.ABB_CommonUX_Icon-16-style__icon-abb_tiles_16:before {
    content: "\f102"
}

.ABB_CommonUX_Icon-16-style__icon-abb_edge_16:before {
    content: "\f103"
}

.ABB_CommonUX_Icon-16-style__icon-abb_hierarchy_16:before {
    content: "\f104"
}

.ABB_CommonUX_Icon-16-style__icon-abb_list-2_16:before {
    content: "\f105"
}

.ABB_CommonUX_Icon-24-style__icon-abb_add-user_24:before {
    content: "\e900"
}

.ABB_CommonUX_Icon-24-style__icon-abb_alarm-bell_24:before {
    content: "\e901"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-bottom_24:before {
    content: "\e902"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-horizontal-center_24:before {
    content: "\e903"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-left_24:before {
    content: "\e904"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-right_24:before {
    content: "\e905"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-top_24:before {
    content: "\e906"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align-vertical-center_24:before {
    content: "\e907"
}

.ABB_CommonUX_Icon-24-style__icon-abb_analyze-document_24:before {
    content: "\e908"
}

.ABB_CommonUX_Icon-24-style__icon-abb_anchor_24:before {
    content: "\e909"
}

.ABB_CommonUX_Icon-24-style__icon-abb_audio-off_24:before {
    content: "\e90a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_audio-on_24:before {
    content: "\e90b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_background_24:before {
    content: "\e90c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_bar-chart_24:before {
    content: "\e90d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-charging_24:before {
    content: "\e90e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-empty_24:before {
    content: "\e90f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-full_24:before {
    content: "\e910"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-half_24:before {
    content: "\e911"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-high_24:before {
    content: "\e912"
}

.ABB_CommonUX_Icon-24-style__icon-abb_battery-low_24:before {
    content: "\e913"
}

.ABB_CommonUX_Icon-24-style__icon-abb_binary-data_24:before {
    content: "\e914"
}

.ABB_CommonUX_Icon-24-style__icon-abb_bluetooth_24:before {
    content: "\e915"
}

.ABB_CommonUX_Icon-24-style__icon-abb_boolean_24:before {
    content: "\e916"
}

.ABB_CommonUX_Icon-24-style__icon-abb_briefcase_24:before {
    content: "\e917"
}

.ABB_CommonUX_Icon-24-style__icon-abb_bring-to-front_24:before {
    content: "\e918"
}

.ABB_CommonUX_Icon-24-style__icon-abb_broadcast_24:before {
    content: "\e919"
}

.ABB_CommonUX_Icon-24-style__icon-abb_calculator_24:before {
    content: "\e91a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_calendar_24:before {
    content: "\e91b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_call_24:before {
    content: "\e91c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_camera_24:before {
    content: "\e91d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_cart_24:before {
    content: "\e91e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_certificate-document-1_24:before {
    content: "\e91f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_certificate-document-2_24:before {
    content: "\e920"
}

.ABB_CommonUX_Icon-24-style__icon-abb_chat_24:before {
    content: "\e921"
}

.ABB_CommonUX_Icon-24-style__icon-abb_check-mark_24:before {
    content: "\e922"
}

.ABB_CommonUX_Icon-24-style__icon-abb_check-mark-circle-1_24:before {
    content: "\e923"
}

.ABB_CommonUX_Icon-24-style__icon-abb_check-mark-circle-2_24:before {
    content: "\e924"
}

.ABB_CommonUX_Icon-24-style__icon-abb_close_24:before {
    content: "\e925"
}

.ABB_CommonUX_Icon-24-style__icon-abb_comment_24:before {
    content: "\e926"
}

.ABB_CommonUX_Icon-24-style__icon-abb_computer_24:before {
    content: "\e927"
}

.ABB_CommonUX_Icon-24-style__icon-abb_connect_24:before {
    content: "\e928"
}

.ABB_CommonUX_Icon-24-style__icon-abb_controller-cabinet_24:before {
    content: "\e929"
}

.ABB_CommonUX_Icon-24-style__icon-abb_controls-and-settings_24:before {
    content: "\e92a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_copy_24:before {
    content: "\e92b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_cut_24:before {
    content: "\e92c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_dashboard_24:before {
    content: "\e92d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_database_24:before {
    content: "\e92e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_database-new_24:before {
    content: "\e92f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_decimal_24:before {
    content: "\e930"
}

.ABB_CommonUX_Icon-24-style__icon-abb_devices_24:before {
    content: "\e931"
}

.ABB_CommonUX_Icon-24-style__icon-abb_disconnect_24:before {
    content: "\e932"
}

.ABB_CommonUX_Icon-24-style__icon-abb_distribution_24:before {
    content: "\e933"
}

.ABB_CommonUX_Icon-24-style__icon-abb_document_24:before {
    content: "\e934"
}

.ABB_CommonUX_Icon-24-style__icon-abb_dollar_24:before {
    content: "\e935"
}

.ABB_CommonUX_Icon-24-style__icon-abb_double-down_24:before {
    content: "\e936"
}

.ABB_CommonUX_Icon-24-style__icon-abb_double-left_24:before {
    content: "\e937"
}

.ABB_CommonUX_Icon-24-style__icon-abb_double-right_24:before {
    content: "\e938"
}

.ABB_CommonUX_Icon-24-style__icon-abb_double-up_24:before {
    content: "\e939"
}

.ABB_CommonUX_Icon-24-style__icon-abb_down_24:before {
    content: "\e93a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_down-arrow_24:before {
    content: "\e93b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_download_24:before {
    content: "\e93c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_drive_24:before {
    content: "\e93d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_edit_24:before {
    content: "\e93e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_end-call_24:before {
    content: "\e93f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_envelope_24:before {
    content: "\e940"
}

.ABB_CommonUX_Icon-24-style__icon-abb_error-circle-1_24:before {
    content: "\e941"
}

.ABB_CommonUX_Icon-24-style__icon-abb_error-circle-2_24:before {
    content: "\e942"
}

.ABB_CommonUX_Icon-24-style__icon-abb_euro_24:before {
    content: "\e943"
}

.ABB_CommonUX_Icon-24-style__icon-abb_event_24:before {
    content: "\e944"
}

.ABB_CommonUX_Icon-24-style__icon-abb_export_24:before {
    content: "\e945"
}

.ABB_CommonUX_Icon-24-style__icon-abb_factory_24:before {
    content: "\e946"
}

.ABB_CommonUX_Icon-24-style__icon-abb_filter_24:before {
    content: "\e947"
}

.ABB_CommonUX_Icon-24-style__icon-abb_folder_24:before {
    content: "\e948"
}

.ABB_CommonUX_Icon-24-style__icon-abb_folder-new_24:before {
    content: "\e949"
}

.ABB_CommonUX_Icon-24-style__icon-abb_folder-open_24:before {
    content: "\e94a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_forward_24:before {
    content: "\e94b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_fullscreen_24:before {
    content: "\e94c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_gauge_24:before {
    content: "\e94d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_gbp_24:before {
    content: "\e94e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_globe_24:before {
    content: "\e94f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_group_24:before {
    content: "\e950"
}

.ABB_CommonUX_Icon-24-style__icon-abb_help-circle-1_24:before {
    content: "\e951"
}

.ABB_CommonUX_Icon-24-style__icon-abb_help-circle-2_24:before {
    content: "\e952"
}

.ABB_CommonUX_Icon-24-style__icon-abb_hide_24:before {
    content: "\e953"
}

.ABB_CommonUX_Icon-24-style__icon-abb_history_24:before {
    content: "\e954"
}

.ABB_CommonUX_Icon-24-style__icon-abb_home_24:before {
    content: "\e955"
}

.ABB_CommonUX_Icon-24-style__icon-abb_hour-glass_24:before {
    content: "\e956"
}

.ABB_CommonUX_Icon-24-style__icon-abb_image_24:before {
    content: "\e957"
}

.ABB_CommonUX_Icon-24-style__icon-abb_import_24:before {
    content: "\e958"
}

.ABB_CommonUX_Icon-24-style__icon-abb_information-circle-1_24:before {
    content: "\e959"
}

.ABB_CommonUX_Icon-24-style__icon-abb_information-circle-2_24:before {
    content: "\e95a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_key_24:before {
    content: "\e95b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_label_24:before {
    content: "\e95c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_left_24:before {
    content: "\e95d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_left-arrow_24:before {
    content: "\e95e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_level_24:before {
    content: "\e95f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_light-bulb_24:before {
    content: "\e960"
}

.ABB_CommonUX_Icon-24-style__icon-abb_link_24:before {
    content: "\e961"
}

.ABB_CommonUX_Icon-24-style__icon-abb_list_24:before {
    content: "\e962"
}

.ABB_CommonUX_Icon-24-style__icon-abb_location_24:before {
    content: "\e963"
}

.ABB_CommonUX_Icon-24-style__icon-abb_lock-closed_24:before {
    content: "\e964"
}

.ABB_CommonUX_Icon-24-style__icon-abb_lock-open_24:before {
    content: "\e965"
}

.ABB_CommonUX_Icon-24-style__icon-abb_log-in_24:before {
    content: "\e966"
}

.ABB_CommonUX_Icon-24-style__icon-abb_log-out_24:before {
    content: "\e967"
}

.ABB_CommonUX_Icon-24-style__icon-abb_magnet_24:before {
    content: "\e968"
}

.ABB_CommonUX_Icon-24-style__icon-abb_make-same-height_24:before {
    content: "\e969"
}

.ABB_CommonUX_Icon-24-style__icon-abb_make-same-size_24:before {
    content: "\e96a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_make-same-width_24:before {
    content: "\e96b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_map_24:before {
    content: "\e96c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_matrix_24:before {
    content: "\e96d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_maximize_24:before {
    content: "\e96e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_menu_24:before {
    content: "\e96f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_menu-narrow_24:before {
    content: "\e970"
}

.ABB_CommonUX_Icon-24-style__icon-abb_microphone_24:before {
    content: "\e971"
}

.ABB_CommonUX_Icon-24-style__icon-abb_microphone-off_24:before {
    content: "\e972"
}

.ABB_CommonUX_Icon-24-style__icon-abb_minimize_24:before {
    content: "\e973"
}

.ABB_CommonUX_Icon-24-style__icon-abb_minus_24:before {
    content: "\e974"
}

.ABB_CommonUX_Icon-24-style__icon-abb_minus-in-circle_24:before {
    content: "\e975"
}

.ABB_CommonUX_Icon-24-style__icon-abb_missed-call_24:before {
    content: "\e976"
}

.ABB_CommonUX_Icon-24-style__icon-abb_moon_24:before {
    content: "\e977"
}

.ABB_CommonUX_Icon-24-style__icon-abb_more_24:before {
    content: "\e978"
}

.ABB_CommonUX_Icon-24-style__icon-abb_motor_24:before {
    content: "\e979"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network_24:before {
    content: "\e97a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_new-document_24:before {
    content: "\e97b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_numericals_24:before {
    content: "\e97c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_object_24:before {
    content: "\e97d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_object-container-1_24:before {
    content: "\e97e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_object-container-2_24:before {
    content: "\e97f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_open-in-new-window_24:before {
    content: "\e980"
}

.ABB_CommonUX_Icon-24-style__icon-abb_panel_24:before {
    content: "\e981"
}

.ABB_CommonUX_Icon-24-style__icon-abb_paper-clip_24:before {
    content: "\e982"
}

.ABB_CommonUX_Icon-24-style__icon-abb_paste_24:before {
    content: "\e983"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pause_24:before {
    content: "\e984"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pie-chart_24:before {
    content: "\e985"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pin_24:before {
    content: "\e986"
}

.ABB_CommonUX_Icon-24-style__icon-abb_play_24:before {
    content: "\e987"
}

.ABB_CommonUX_Icon-24-style__icon-abb_plugin_24:before {
    content: "\e988"
}

.ABB_CommonUX_Icon-24-style__icon-abb_plus_24:before {
    content: "\e989"
}

.ABB_CommonUX_Icon-24-style__icon-abb_plus-in-circle_24:before {
    content: "\e98a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pop-out-window_24:before {
    content: "\e98b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_power-on-off_24:before {
    content: "\e98c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pressure_24:before {
    content: "\e98d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_print_24:before {
    content: "\e98e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_pump_24:before {
    content: "\e98f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_qr-code_24:before {
    content: "\e990"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rearrange_24:before {
    content: "\e991"
}

.ABB_CommonUX_Icon-24-style__icon-abb_redo_24:before {
    content: "\e992"
}

.ABB_CommonUX_Icon-24-style__icon-abb_refresh_24:before {
    content: "\e993"
}

.ABB_CommonUX_Icon-24-style__icon-abb_reports_24:before {
    content: "\e994"
}

.ABB_CommonUX_Icon-24-style__icon-abb_resistor_24:before {
    content: "\e995"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rewind_24:before {
    content: "\e996"
}

.ABB_CommonUX_Icon-24-style__icon-abb_right_24:before {
    content: "\e997"
}

.ABB_CommonUX_Icon-24-style__icon-abb_right-arrow_24:before {
    content: "\e998"
}

.ABB_CommonUX_Icon-24-style__icon-abb_robot_24:before {
    content: "\e999"
}

.ABB_CommonUX_Icon-24-style__icon-abb_robot-cabinet_24:before {
    content: "\e99a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_robot-tool_24:before {
    content: "\e99b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-left-90_24:before {
    content: "\e99c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-left-180_24:before {
    content: "\e99d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-right-270_24:before {
    content: "\e99f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-left-270_24:before {
    content: "\e99e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-right-90_24:before {
    content: "\e9a0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rotate-right-180_24:before {
    content: "\e9a1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_save_24:before {
    content: "\e9a2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_save-as_24:before {
    content: "\e9a3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_screen_24:before {
    content: "\e9a4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_search_24:before {
    content: "\e9a5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_send-to-back_24:before {
    content: "\e9a6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_server_24:before {
    content: "\e9a7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_settings_24:before {
    content: "\e9a8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_share_24:before {
    content: "\e9a9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_shortcut_24:before {
    content: "\e9aa"
}

.ABB_CommonUX_Icon-24-style__icon-abb_shuffle_24:before {
    content: "\e9ab"
}

.ABB_CommonUX_Icon-24-style__icon-abb_skip-to-end_24:before {
    content: "\e9ac"
}

.ABB_CommonUX_Icon-24-style__icon-abb_skip-to-start_24:before {
    content: "\e9ad"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sld-1_24:before {
    content: "\e9ae"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sld-2_24:before {
    content: "\e9af"
}

.ABB_CommonUX_Icon-24-style__icon-abb_smartphone_24:before {
    content: "\e9b0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-alphabet-down_24:before {
    content: "\e9b1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-alphabet-up_24:before {
    content: "\e9b2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-date-down-1_24:before {
    content: "\e9b3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-date-down-2_24:before {
    content: "\e9b4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-date-up-1_24:before {
    content: "\e9b5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-date-up-2_24:before {
    content: "\e9b6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_specification_24:before {
    content: "\e9b7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_stairs_24:before {
    content: "\e9b8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_star_24:before {
    content: "\e9b9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_stop_24:before {
    content: "\e9ba"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sun_24:before {
    content: "\e9bb"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sun-2_24:before {
    content: "\e9bc"
}

.ABB_CommonUX_Icon-24-style__icon-abb_synchronize_24:before {
    content: "\e9bd"
}

.ABB_CommonUX_Icon-24-style__icon-abb_system_24:before {
    content: "\e9be"
}

.ABB_CommonUX_Icon-24-style__icon-abb_table_24:before {
    content: "\e9bf"
}

.ABB_CommonUX_Icon-24-style__icon-abb_temperature_24:before {
    content: "\e9c0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_temperature-celsius-1_24:before {
    content: "\e9c1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_temperature-celsius-2_24:before {
    content: "\e9c2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_temperature-fahrenheit-1_24:before {
    content: "\e9c3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_temperature-fahrenheit-2_24:before {
    content: "\e9c4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_template_24:before {
    content: "\e9c5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_template-new_24:before {
    content: "\e9c6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_test_24:before {
    content: "\e9c7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_text_24:before {
    content: "\e9c8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_time_24:before {
    content: "\e9c9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_time-and-date_24:before {
    content: "\e9ca"
}

.ABB_CommonUX_Icon-24-style__icon-abb_track-1_24:before {
    content: "\e9cb"
}

.ABB_CommonUX_Icon-24-style__icon-abb_track-2_24:before {
    content: "\e9cc"
}

.ABB_CommonUX_Icon-24-style__icon-abb_transformer_24:before {
    content: "\e9cd"
}

.ABB_CommonUX_Icon-24-style__icon-abb_trash_24:before {
    content: "\e9ce"
}

.ABB_CommonUX_Icon-24-style__icon-abb_tree-view_24:before {
    content: "\e9cf"
}

.ABB_CommonUX_Icon-24-style__icon-abb_trend-1_24:before {
    content: "\e9d0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_trend-2_24:before {
    content: "\e9d1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_turbocharger_24:before {
    content: "\e9d2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_undo_24:before {
    content: "\e9d3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_unpin_24:before {
    content: "\e9d4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_up_24:before {
    content: "\e9d5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_up-arrow_24:before {
    content: "\e9d6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_upload_24:before {
    content: "\e9d7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_usb_24:before {
    content: "\e9d8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_user_24:before {
    content: "\e9d9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_user-in-circle_24:before {
    content: "\e9da"
}

.ABB_CommonUX_Icon-24-style__icon-abb_video-camera-off_24:before {
    content: "\e9db"
}

.ABB_CommonUX_Icon-24-style__icon-abb_video-camera-on_24:before {
    content: "\e9dc"
}

.ABB_CommonUX_Icon-24-style__icon-abb_video-chat_24:before {
    content: "\e9dd"
}

.ABB_CommonUX_Icon-24-style__icon-abb_view_24:before {
    content: "\e9de"
}

.ABB_CommonUX_Icon-24-style__icon-abb_voltage_24:before {
    content: "\e9df"
}

.ABB_CommonUX_Icon-24-style__icon-abb_warning-circle-1_24:before {
    content: "\e9e0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_warning-circle-2_24:before {
    content: "\e9e1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_warning-triangle_24:before {
    content: "\e9e2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_wrench_24:before {
    content: "\e9e3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_zoom-in_24:before {
    content: "\e9e4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_zoom-out_24:before {
    content: "\e9e5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_trend-3_24:before {
    content: "\e9e6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_trend-scatter_24:before {
    content: "\e9e7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-ascending_24:before {
    content: "\e9e8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_sort-descending_24:before {
    content: "\e9e9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_advanced-settings_24:before {
    content: "\e9ea"
}

.ABB_CommonUX_Icon-24-style__icon-abb_align_24:before {
    content: "\e9eb"
}

.ABB_CommonUX_Icon-24-style__icon-abb_robot-axis_24:before {
    content: "\e9ec"
}

.ABB_CommonUX_Icon-24-style__icon-abb_calibration_24:before {
    content: "\e9ed"
}

.ABB_CommonUX_Icon-24-style__icon-abb_connection_24:before {
    content: "\e9ee"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-status_24:before {
    content: "\e9ef"
}

.ABB_CommonUX_Icon-24-style__icon-abb_create-backup_24:before {
    content: "\e9f0"
}

.ABB_CommonUX_Icon-24-style__icon-abb_data-editor_24:before {
    content: "\e9f1"
}

.ABB_CommonUX_Icon-24-style__icon-abb_execution_24:before {
    content: "\e9f2"
}

.ABB_CommonUX_Icon-24-style__icon-abb_hardware-devices_24:before {
    content: "\e9f3"
}

.ABB_CommonUX_Icon-24-style__icon-abb_io-devices_24:before {
    content: "\e9f4"
}

.ABB_CommonUX_Icon-24-style__icon-abb_joystick_24:before {
    content: "\e9f5"
}

.ABB_CommonUX_Icon-24-style__icon-abb_lead-through_24:before {
    content: "\e9f6"
}

.ABB_CommonUX_Icon-24-style__icon-abb_jog-linear_24:before {
    content: "\e9f7"
}

.ABB_CommonUX_Icon-24-style__icon-abb_document-excel_24:before {
    content: "\e9f8"
}

.ABB_CommonUX_Icon-24-style__icon-abb_document-pdf_24:before {
    content: "\e9f9"
}

.ABB_CommonUX_Icon-24-style__icon-abb_document-word_24:before {
    content: "\e9fa"
}

.ABB_CommonUX_Icon-24-style__icon-abb_modify-rapid-position_24:before {
    content: "\e9fb"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-module_24:before {
    content: "\e9fc"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-status-1_24:before {
    content: "\e9fe"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-status-2_24:before {
    content: "\e9ff"
}

.ABB_CommonUX_Icon-24-style__icon-abb_payload_24:before {
    content: "\ea00"
}

.ABB_CommonUX_Icon-24-style__icon-abb_jog-planar_24:before {
    content: "\ea01"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-program-and-modules_24:before {
    content: "\ea02"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-program-editor_24:before {
    content: "\ea03"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-program_24:before {
    content: "\ea04"
}

.ABB_CommonUX_Icon-24-style__icon-abb_program-pointer_24:before {
    content: "\ea05"
}

.ABB_CommonUX_Icon-24-style__icon-abb_rapid-tasks_24:before {
    content: "\ea06"
}

.ABB_CommonUX_Icon-24-style__icon-abb_jog-reorient_24:before {
    content: "\ea07"
}

.ABB_CommonUX_Icon-24-style__icon-abb_reset-user-data_24:before {
    content: "\ea09"
}

.ABB_CommonUX_Icon-24-style__icon-abb_restore-backup_24:before {
    content: "\ea0a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_select_24:before {
    content: "\ea0b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-routins_24:before {
    content: "\ea0c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-signal-strength-level-1_24:before {
    content: "\ea0d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-signal-strength-level-2_24:before {
    content: "\ea0e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_network-signal-strength-level-3_24:before {
    content: "\ea0f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_io-signals_24:before {
    content: "\ea10"
}

.ABB_CommonUX_Icon-24-style__icon-abb_software-resources_24:before {
    content: "\ea11"
}

.ABB_CommonUX_Icon-24-style__icon-abb_start-installer_24:before {
    content: "\ea12"
}

.ABB_CommonUX_Icon-24-style__icon-abb_step-in_24:before {
    content: "\ea13"
}

.ABB_CommonUX_Icon-24-style__icon-abb_step-out_24:before {
    content: "\ea14"
}

.ABB_CommonUX_Icon-24-style__icon-abb_step-over_24:before {
    content: "\ea15"
}

.ABB_CommonUX_Icon-24-style__icon-abb_touch_24:before {
    content: "\ea16"
}

.ABB_CommonUX_Icon-24-style__icon-abb_video_24:before {
    content: "\ea17"
}

.ABB_CommonUX_Icon-24-style__icon-abb_turn-clockwise_24:before {
    content: "\ea18"
}

.ABB_CommonUX_Icon-24-style__icon-abb_turn-clockwise-off-target-speed_24:before {
    content: "\ea19"
}

.ABB_CommonUX_Icon-24-style__icon-abb_turn-counter-clockwise-off-target-speed_24:before {
    content: "\ea1a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_add-drives_24:before {
    content: "\ea1b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_bearing_24:before {
    content: "\ea1d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_cloud_24:before {
    content: "\ea1e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_delete-drives_24:before {
    content: "\ea1f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_download-from-cloud_24:before {
    content: "\ea20"
}

.ABB_CommonUX_Icon-24-style__icon-abb_drive-connected_24:before {
    content: "\ea21"
}

.ABB_CommonUX_Icon-24-style__icon-abb_drives-error_24:before {
    content: "\ea22"
}

.ABB_CommonUX_Icon-24-style__icon-abb_fieldbus_24:before {
    content: "\ea23"
}

.ABB_CommonUX_Icon-24-style__icon-abb_generator_24:before {
    content: "\ea24"
}

.ABB_CommonUX_Icon-24-style__icon-abb_maximum-value_24:before {
    content: "\ea26"
}

.ABB_CommonUX_Icon-24-style__icon-abb_minimum-value_24:before {
    content: "\ea27"
}

.ABB_CommonUX_Icon-24-style__icon-abb_not-allowed_24:before {
    content: "\ea28"
}

.ABB_CommonUX_Icon-24-style__icon-abb_powertrain_24:before {
    content: "\ea29"
}

.ABB_CommonUX_Icon-24-style__icon-abb_prevent-turning-fault_24:before {
    content: "\ea2a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_target-value-range_24:before {
    content: "\ea2b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_thumbnail-view_24:before {
    content: "\ea2c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_train_24:before {
    content: "\ea2d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_turn-counter-clockwise_24:before {
    content: "\ea2e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_wheel_24:before {
    content: "\ea2f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_wifi-network-disconnected_24:before {
    content: "\ea31"
}

.ABB_CommonUX_Icon-24-style__icon-abb_write-to-default_24:before {
    content: "\ea32"
}

.ABB_CommonUX_Icon-24-style__icon-abb_write-to-default-disabled_24:before {
    content: "\ea33"
}

.ABB_CommonUX_Icon-24-style__icon-abb_caret-down_24:before {
    content: "\ea08"
}

.ABB_CommonUX_Icon-24-style__icon-abb_caret-left_24:before {
    content: "\ea1c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_caret-right_24:before {
    content: "\ea30"
}

.ABB_CommonUX_Icon-24-style__icon-abb_caret-up_24:before {
    content: "\ea34"
}

.ABB_CommonUX_Icon-24-style__icon-abb_triangle-down_24:before {
    content: "\ea36"
}

.ABB_CommonUX_Icon-24-style__icon-abb_triangle-left_24:before {
    content: "\ea37"
}

.ABB_CommonUX_Icon-24-style__icon-abb_triangle-right_24:before {
    content: "\ea38"
}

.ABB_CommonUX_Icon-24-style__icon-abb_triangle-up_24:before {
    content: "\ea39"
}

.ABB_CommonUX_Icon-24-style__icon-abb_check-mark-light_24:before {
    content: "\ea3a"
}

.ABB_CommonUX_Icon-24-style__icon-abb_numbered-list_24:before {
    content: "\ea3b"
}

.ABB_CommonUX_Icon-24-style__icon-abb_text-bold_24:before {
    content: "\ea3c"
}

.ABB_CommonUX_Icon-24-style__icon-abb_text-italic_24:before {
    content: "\ea3d"
}

.ABB_CommonUX_Icon-24-style__icon-abb_text-underline_24:before {
    content: "\ea3e"
}

.ABB_CommonUX_Icon-24-style__icon-abb_call-outline_24:before {
    content: "\e9fd"
}

.ABB_CommonUX_Icon-24-style__icon-abb_location-outline_24:before {
    content: "\ea25"
}

.ABB_CommonUX_Icon-24-style__icon-abb_user-outline_24:before {
    content: "\ea3f"
}

.ABB_CommonUX_Icon-24-style__icon-abb_close-light_24:before {
    content: "\ea35"
}

.ABB_CommonUX_Icon-24-style__icon-abb_smart-gripper_24:before {
    content: "\ea43"
}

.ABB_CommonUX_Icon-24-style__icon-abb_bookmark_24:before {
    content: "\f101"
}

.ABB_CommonUX_Icon-24-style__icon-abb_tiles_24:before {
    content: "\f102"
}

.ABB_CommonUX_Icon-24-style__icon-abb_edge_24:before {
    content: "\f103"
}

.ABB_CommonUX_Icon-24-style__icon-abb_hierarchy_24:before {
    content: "\f104"
}

.ABB_CommonUX_Icon-24-style__icon-abb_list-2_24:before {
    content: "\f105"
}

.ABB_CommonUX_Icon-32-style__icon-abb_add-user_32:before {
    content: "\e900"
}

.ABB_CommonUX_Icon-32-style__icon-abb_alarm-bell_32:before {
    content: "\e901"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-bottom_32:before {
    content: "\e902"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-horizontal-center_32:before {
    content: "\e903"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-left_32:before {
    content: "\e904"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-right_32:before {
    content: "\e905"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-top_32:before {
    content: "\e906"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align-vertical-center_32:before {
    content: "\e907"
}

.ABB_CommonUX_Icon-32-style__icon-abb_analyze-document_32:before {
    content: "\e908"
}

.ABB_CommonUX_Icon-32-style__icon-abb_anchor_32:before {
    content: "\e909"
}

.ABB_CommonUX_Icon-32-style__icon-abb_audio-off_32:before {
    content: "\e90a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_audio-on_32:before {
    content: "\e90b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_background_32:before {
    content: "\e90c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_bar-chart_32:before {
    content: "\e90d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-charging_32:before {
    content: "\e90e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-empty_32:before {
    content: "\e90f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-full_32:before {
    content: "\e910"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-half_32:before {
    content: "\e911"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-high_32:before {
    content: "\e912"
}

.ABB_CommonUX_Icon-32-style__icon-abb_battery-low_32:before {
    content: "\e913"
}

.ABB_CommonUX_Icon-32-style__icon-abb_binary-data_32:before {
    content: "\e914"
}

.ABB_CommonUX_Icon-32-style__icon-abb_bluetooth_32:before {
    content: "\e915"
}

.ABB_CommonUX_Icon-32-style__icon-abb_boolean_32:before {
    content: "\e916"
}

.ABB_CommonUX_Icon-32-style__icon-abb_briefcase_32:before {
    content: "\e917"
}

.ABB_CommonUX_Icon-32-style__icon-abb_bring-to-front_32:before {
    content: "\e918"
}

.ABB_CommonUX_Icon-32-style__icon-abb_broadcast_32:before {
    content: "\e919"
}

.ABB_CommonUX_Icon-32-style__icon-abb_calculator_32:before {
    content: "\e91a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_calendar_32:before {
    content: "\e91b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_call_32:before {
    content: "\e91c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_camera_32:before {
    content: "\e91d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_cart_32:before {
    content: "\e91e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_certificate-document-1_32:before {
    content: "\e91f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_certificate-document-2_32:before {
    content: "\e920"
}

.ABB_CommonUX_Icon-32-style__icon-abb_chat_32:before {
    content: "\e921"
}

.ABB_CommonUX_Icon-32-style__icon-abb_check-mark_32:before {
    content: "\e922"
}

.ABB_CommonUX_Icon-32-style__icon-abb_check-mark-circle-1_32:before {
    content: "\e923"
}

.ABB_CommonUX_Icon-32-style__icon-abb_check-mark-circle-2_32:before {
    content: "\e924"
}

.ABB_CommonUX_Icon-32-style__icon-abb_close_32:before {
    content: "\e925"
}

.ABB_CommonUX_Icon-32-style__icon-abb_comment_32:before {
    content: "\e926"
}

.ABB_CommonUX_Icon-32-style__icon-abb_computer_32:before {
    content: "\e927"
}

.ABB_CommonUX_Icon-32-style__icon-abb_connect_32:before {
    content: "\e928"
}

.ABB_CommonUX_Icon-32-style__icon-abb_controller-cabinet_32:before {
    content: "\e929"
}

.ABB_CommonUX_Icon-32-style__icon-abb_controls-and-settings_32:before {
    content: "\e92a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_copy_32:before {
    content: "\e92b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_cut_32:before {
    content: "\e92c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_dashboard_32:before {
    content: "\e92d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_database_32:before {
    content: "\e92e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_database-new_32:before {
    content: "\e92f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_decimal_32:before {
    content: "\e930"
}

.ABB_CommonUX_Icon-32-style__icon-abb_devices_32:before {
    content: "\e931"
}

.ABB_CommonUX_Icon-32-style__icon-abb_disconnect_32:before {
    content: "\e932"
}

.ABB_CommonUX_Icon-32-style__icon-abb_distribution_32:before {
    content: "\e933"
}

.ABB_CommonUX_Icon-32-style__icon-abb_document_32:before {
    content: "\e934"
}

.ABB_CommonUX_Icon-32-style__icon-abb_dollar_32:before {
    content: "\e935"
}

.ABB_CommonUX_Icon-32-style__icon-abb_double-down_32:before {
    content: "\e936"
}

.ABB_CommonUX_Icon-32-style__icon-abb_double-left_32:before {
    content: "\e937"
}

.ABB_CommonUX_Icon-32-style__icon-abb_double-right_32:before {
    content: "\e938"
}

.ABB_CommonUX_Icon-32-style__icon-abb_double-up_32:before {
    content: "\e939"
}

.ABB_CommonUX_Icon-32-style__icon-abb_down_32:before {
    content: "\e93a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_down-arrow_32:before {
    content: "\e93b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_download_32:before {
    content: "\e93c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_drive_32:before {
    content: "\e93d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_edit_32:before {
    content: "\e93e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_end-call_32:before {
    content: "\e93f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_envelope_32:before {
    content: "\e940"
}

.ABB_CommonUX_Icon-32-style__icon-abb_error-circle-1_32:before {
    content: "\e941"
}

.ABB_CommonUX_Icon-32-style__icon-abb_error-circle-2_32:before {
    content: "\e942"
}

.ABB_CommonUX_Icon-32-style__icon-abb_euro_32:before {
    content: "\e943"
}

.ABB_CommonUX_Icon-32-style__icon-abb_event_32:before {
    content: "\e944"
}

.ABB_CommonUX_Icon-32-style__icon-abb_export_32:before {
    content: "\e945"
}

.ABB_CommonUX_Icon-32-style__icon-abb_factory_32:before {
    content: "\e946"
}

.ABB_CommonUX_Icon-32-style__icon-abb_filter_32:before {
    content: "\e947"
}

.ABB_CommonUX_Icon-32-style__icon-abb_folder_32:before {
    content: "\e948"
}

.ABB_CommonUX_Icon-32-style__icon-abb_folder-new_32:before {
    content: "\e949"
}

.ABB_CommonUX_Icon-32-style__icon-abb_folder-open_32:before {
    content: "\e94a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_forward_32:before {
    content: "\e94b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_fullscreen_32:before {
    content: "\e94c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_gauge_32:before {
    content: "\e94d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_gbp_32:before {
    content: "\e94e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_globe_32:before {
    content: "\e94f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_group_32:before {
    content: "\e950"
}

.ABB_CommonUX_Icon-32-style__icon-abb_help-circle-1_32:before {
    content: "\e951"
}

.ABB_CommonUX_Icon-32-style__icon-abb_help-circle-2_32:before {
    content: "\e952"
}

.ABB_CommonUX_Icon-32-style__icon-abb_hide_32:before {
    content: "\e953"
}

.ABB_CommonUX_Icon-32-style__icon-abb_history_32:before {
    content: "\e954"
}

.ABB_CommonUX_Icon-32-style__icon-abb_home_32:before {
    content: "\e955"
}

.ABB_CommonUX_Icon-32-style__icon-abb_hour-glass_32:before {
    content: "\e956"
}

.ABB_CommonUX_Icon-32-style__icon-abb_image_32:before {
    content: "\e957"
}

.ABB_CommonUX_Icon-32-style__icon-abb_import_32:before {
    content: "\e958"
}

.ABB_CommonUX_Icon-32-style__icon-abb_information-circle-1_32:before {
    content: "\e959"
}

.ABB_CommonUX_Icon-32-style__icon-abb_information-circle-2_32:before {
    content: "\e95a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_key_32:before {
    content: "\e95b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_label_32:before {
    content: "\e95c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_left_32:before {
    content: "\e95d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_left-arrow_32:before {
    content: "\e95e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_level_32:before {
    content: "\e95f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_light-bulb_32:before {
    content: "\e960"
}

.ABB_CommonUX_Icon-32-style__icon-abb_link_32:before {
    content: "\e961"
}

.ABB_CommonUX_Icon-32-style__icon-abb_list_32:before {
    content: "\e962"
}

.ABB_CommonUX_Icon-32-style__icon-abb_location_32:before {
    content: "\e963"
}

.ABB_CommonUX_Icon-32-style__icon-abb_lock-closed_32:before {
    content: "\e964"
}

.ABB_CommonUX_Icon-32-style__icon-abb_lock-open_32:before {
    content: "\e965"
}

.ABB_CommonUX_Icon-32-style__icon-abb_log-in_32:before {
    content: "\e966"
}

.ABB_CommonUX_Icon-32-style__icon-abb_log-out_32:before {
    content: "\e967"
}

.ABB_CommonUX_Icon-32-style__icon-abb_magnet_32:before {
    content: "\e968"
}

.ABB_CommonUX_Icon-32-style__icon-abb_make-same-height_32:before {
    content: "\e969"
}

.ABB_CommonUX_Icon-32-style__icon-abb_make-same-size_32:before {
    content: "\e96a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_make-same-width_32:before {
    content: "\e96b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_map_32:before {
    content: "\e96c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_matrix_32:before {
    content: "\e96d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_maximize_32:before {
    content: "\e96e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_menu_32:before {
    content: "\e96f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_menu-narrow_32:before {
    content: "\e970"
}

.ABB_CommonUX_Icon-32-style__icon-abb_microphone_32:before {
    content: "\e971"
}

.ABB_CommonUX_Icon-32-style__icon-abb_microphone-off_32:before {
    content: "\e972"
}

.ABB_CommonUX_Icon-32-style__icon-abb_minimize_32:before {
    content: "\e973"
}

.ABB_CommonUX_Icon-32-style__icon-abb_minus_32:before {
    content: "\e974"
}

.ABB_CommonUX_Icon-32-style__icon-abb_minus-in-circle_32:before {
    content: "\e975"
}

.ABB_CommonUX_Icon-32-style__icon-abb_missed-call_32:before {
    content: "\e976"
}

.ABB_CommonUX_Icon-32-style__icon-abb_moon_32:before {
    content: "\e977"
}

.ABB_CommonUX_Icon-32-style__icon-abb_more_32:before {
    content: "\e978"
}

.ABB_CommonUX_Icon-32-style__icon-abb_motor_32:before {
    content: "\e979"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network_32:before {
    content: "\e97a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_new-document_32:before {
    content: "\e97b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_numericals_32:before {
    content: "\e97c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_object_32:before {
    content: "\e97d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_object-container-1_32:before {
    content: "\e97e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_object-container-2_32:before {
    content: "\e97f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_open-in-new-window_32:before {
    content: "\e980"
}

.ABB_CommonUX_Icon-32-style__icon-abb_panel_32:before {
    content: "\e981"
}

.ABB_CommonUX_Icon-32-style__icon-abb_paper-clip_32:before {
    content: "\e982"
}

.ABB_CommonUX_Icon-32-style__icon-abb_paste_32:before {
    content: "\e983"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pause_32:before {
    content: "\e984"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pie-chart_32:before {
    content: "\e985"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pin_32:before {
    content: "\e986"
}

.ABB_CommonUX_Icon-32-style__icon-abb_play_32:before {
    content: "\e987"
}

.ABB_CommonUX_Icon-32-style__icon-abb_plugin_32:before {
    content: "\e988"
}

.ABB_CommonUX_Icon-32-style__icon-abb_plus_32:before {
    content: "\e989"
}

.ABB_CommonUX_Icon-32-style__icon-abb_plus-in-circle_32:before {
    content: "\e98a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pop-out-window_32:before {
    content: "\e98b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_power-on-off_32:before {
    content: "\e98c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pressure_32:before {
    content: "\e98d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_print_32:before {
    content: "\e98e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_pump_32:before {
    content: "\e98f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_qr-code_32:before {
    content: "\e990"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rearrange_32:before {
    content: "\e991"
}

.ABB_CommonUX_Icon-32-style__icon-abb_redo_32:before {
    content: "\e992"
}

.ABB_CommonUX_Icon-32-style__icon-abb_refresh_32:before {
    content: "\e993"
}

.ABB_CommonUX_Icon-32-style__icon-abb_reports_32:before {
    content: "\e994"
}

.ABB_CommonUX_Icon-32-style__icon-abb_resistor_32:before {
    content: "\e995"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rewind_32:before {
    content: "\e996"
}

.ABB_CommonUX_Icon-32-style__icon-abb_right_32:before {
    content: "\e997"
}

.ABB_CommonUX_Icon-32-style__icon-abb_right-arrow_32:before {
    content: "\e998"
}

.ABB_CommonUX_Icon-32-style__icon-abb_robot_32:before {
    content: "\e999"
}

.ABB_CommonUX_Icon-32-style__icon-abb_robot-cabinet_32:before {
    content: "\e99a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_robot-tool_32:before {
    content: "\e99b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-left-90_32:before {
    content: "\e99c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-left-180_32:before {
    content: "\e99d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-right-270_32:before {
    content: "\e99f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-left-270_32:before {
    content: "\e99e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-right-90_32:before {
    content: "\e9a0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rotate-right-180_32:before {
    content: "\e9a1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_save_32:before {
    content: "\e9a2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_save-as_32:before {
    content: "\e9a3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_screen_32:before {
    content: "\e9a4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_search_32:before {
    content: "\e9a5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_send-to-back_32:before {
    content: "\e9a6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_server_32:before {
    content: "\e9a7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_settings_32:before {
    content: "\e9a8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_share_32:before {
    content: "\e9a9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_shortcut_32:before {
    content: "\e9aa"
}

.ABB_CommonUX_Icon-32-style__icon-abb_shuffle_32:before {
    content: "\e9ab"
}

.ABB_CommonUX_Icon-32-style__icon-abb_skip-to-end_32:before {
    content: "\e9ac"
}

.ABB_CommonUX_Icon-32-style__icon-abb_skip-to-start_32:before {
    content: "\e9ad"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sld-1_32:before {
    content: "\e9ae"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sld-2_32:before {
    content: "\e9af"
}

.ABB_CommonUX_Icon-32-style__icon-abb_smartphone_32:before {
    content: "\e9b0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-alphabet-down_32:before {
    content: "\e9b1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-alphabet-up_32:before {
    content: "\e9b2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-date-down-1_32:before {
    content: "\e9b3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-date-down-2_32:before {
    content: "\e9b4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-date-up-1_32:before {
    content: "\e9b5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-date-up-2_32:before {
    content: "\e9b6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_specification_32:before {
    content: "\e9b7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_stairs_32:before {
    content: "\e9b8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_star_32:before {
    content: "\e9b9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_stop_32:before {
    content: "\e9ba"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sun_32:before {
    content: "\e9bb"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sun-2_32:before {
    content: "\e9bc"
}

.ABB_CommonUX_Icon-32-style__icon-abb_synchronize_32:before {
    content: "\e9bd"
}

.ABB_CommonUX_Icon-32-style__icon-abb_system_32:before {
    content: "\e9be"
}

.ABB_CommonUX_Icon-32-style__icon-abb_table_32:before {
    content: "\e9bf"
}

.ABB_CommonUX_Icon-32-style__icon-abb_temperature_32:before {
    content: "\e9c0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_temperature-celsius-1_32:before {
    content: "\e9c1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_temperature-celsius-2_32:before {
    content: "\e9c2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_temperature-fahrenheit-1_32:before {
    content: "\e9c3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_temperature-fahrenheit-2_32:before {
    content: "\e9c4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_template_32:before {
    content: "\e9c5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_template-new_32:before {
    content: "\e9c6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_test_32:before {
    content: "\e9c7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_text_32:before {
    content: "\e9c8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_time_32:before {
    content: "\e9c9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_time-and-date_32:before {
    content: "\e9ca"
}

.ABB_CommonUX_Icon-32-style__icon-abb_track-1_32:before {
    content: "\e9cb"
}

.ABB_CommonUX_Icon-32-style__icon-abb_track-2_32:before {
    content: "\e9cc"
}

.ABB_CommonUX_Icon-32-style__icon-abb_transformer_32:before {
    content: "\e9cd"
}

.ABB_CommonUX_Icon-32-style__icon-abb_trash_32:before {
    content: "\e9ce"
}

.ABB_CommonUX_Icon-32-style__icon-abb_tree-view_32:before {
    content: "\e9cf"
}

.ABB_CommonUX_Icon-32-style__icon-abb_trend-1_32:before {
    content: "\e9d0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_trend-2_32:before {
    content: "\e9d1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_turbocharger_32:before {
    content: "\e9d2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_undo_32:before {
    content: "\e9d3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_unpin_32:before {
    content: "\e9d4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_up_32:before {
    content: "\e9d5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_up-arrow_32:before {
    content: "\e9d6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_upload_32:before {
    content: "\e9d7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_usb_32:before {
    content: "\e9d8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_user_32:before {
    content: "\e9d9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_user-in-circle_32:before {
    content: "\e9da"
}

.ABB_CommonUX_Icon-32-style__icon-abb_video-camera-off_32:before {
    content: "\e9db"
}

.ABB_CommonUX_Icon-32-style__icon-abb_video-camera-on_32:before {
    content: "\e9dc"
}

.ABB_CommonUX_Icon-32-style__icon-abb_video-chat_32:before {
    content: "\e9dd"
}

.ABB_CommonUX_Icon-32-style__icon-abb_view_32:before {
    content: "\e9de"
}

.ABB_CommonUX_Icon-32-style__icon-abb_voltage_32:before {
    content: "\e9df"
}

.ABB_CommonUX_Icon-32-style__icon-abb_warning-circle-1_32:before {
    content: "\e9e0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_warning-circle-2_32:before {
    content: "\e9e1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_warning-triangle_32:before {
    content: "\e9e2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_wrench_32:before {
    content: "\e9e3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_zoom-in_32:before {
    content: "\e9e4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_zoom-out_32:before {
    content: "\e9e5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_trend-3_32:before {
    content: "\e9e6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_trend-scatter_32:before {
    content: "\e9e7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-ascending_32:before {
    content: "\e9e8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_sort-descending_32:before {
    content: "\e9e9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_advanced-settings_32:before {
    content: "\e9ea"
}

.ABB_CommonUX_Icon-32-style__icon-abb_align_32:before {
    content: "\e9eb"
}

.ABB_CommonUX_Icon-32-style__icon-abb_robot-axis_32:before {
    content: "\e9ec"
}

.ABB_CommonUX_Icon-32-style__icon-abb_calibration_32:before {
    content: "\e9ed"
}

.ABB_CommonUX_Icon-32-style__icon-abb_connection_32:before {
    content: "\e9ee"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-status_32:before {
    content: "\e9ef"
}

.ABB_CommonUX_Icon-32-style__icon-abb_create-backup_32:before {
    content: "\e9f0"
}

.ABB_CommonUX_Icon-32-style__icon-abb_data-editor_32:before {
    content: "\e9f1"
}

.ABB_CommonUX_Icon-32-style__icon-abb_execution_32:before {
    content: "\e9f2"
}

.ABB_CommonUX_Icon-32-style__icon-abb_hardware-devices_32:before {
    content: "\e9f3"
}

.ABB_CommonUX_Icon-32-style__icon-abb_io-devices_32:before {
    content: "\e9f4"
}

.ABB_CommonUX_Icon-32-style__icon-abb_joystick_32:before {
    content: "\e9f5"
}

.ABB_CommonUX_Icon-32-style__icon-abb_lead-through_32:before {
    content: "\e9f6"
}

.ABB_CommonUX_Icon-32-style__icon-abb_jog-linear_32:before {
    content: "\e9f7"
}

.ABB_CommonUX_Icon-32-style__icon-abb_document-excel_32:before {
    content: "\e9f8"
}

.ABB_CommonUX_Icon-32-style__icon-abb_document-pdf_32:before {
    content: "\e9f9"
}

.ABB_CommonUX_Icon-32-style__icon-abb_document-word_32:before {
    content: "\e9fa"
}

.ABB_CommonUX_Icon-32-style__icon-abb_modify-rapid-position_32:before {
    content: "\e9fb"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-module_32:before {
    content: "\e9fc"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-status-1_32:before {
    content: "\e9fe"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-status-2_32:before {
    content: "\e9ff"
}

.ABB_CommonUX_Icon-32-style__icon-abb_payload_32:before {
    content: "\ea00"
}

.ABB_CommonUX_Icon-32-style__icon-abb_jog-planar_32:before {
    content: "\ea01"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-program-and-modules_32:before {
    content: "\ea02"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-program-editor_32:before {
    content: "\ea03"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-program_32:before {
    content: "\ea04"
}

.ABB_CommonUX_Icon-32-style__icon-abb_program-pointer_32:before {
    content: "\ea05"
}

.ABB_CommonUX_Icon-32-style__icon-abb_rapid-tasks_32:before {
    content: "\ea06"
}

.ABB_CommonUX_Icon-32-style__icon-abb_jog-reorient_32:before {
    content: "\ea07"
}

.ABB_CommonUX_Icon-32-style__icon-abb_reset-user-data_32:before {
    content: "\ea09"
}

.ABB_CommonUX_Icon-32-style__icon-abb_restore-backup_32:before {
    content: "\ea0a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_select_32:before {
    content: "\ea0b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-routins_32:before {
    content: "\ea0c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-signal-strength-level-1_32:before {
    content: "\ea0d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-signal-strength-level-2_32:before {
    content: "\ea0e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_network-signal-strength-level-3_32:before {
    content: "\ea0f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_io-signals_32:before {
    content: "\ea10"
}

.ABB_CommonUX_Icon-32-style__icon-abb_software-resources_32:before {
    content: "\ea11"
}

.ABB_CommonUX_Icon-32-style__icon-abb_start-installer_32:before {
    content: "\ea12"
}

.ABB_CommonUX_Icon-32-style__icon-abb_step-in_32:before {
    content: "\ea13"
}

.ABB_CommonUX_Icon-32-style__icon-abb_step-out_32:before {
    content: "\ea14"
}

.ABB_CommonUX_Icon-32-style__icon-abb_step-over_32:before {
    content: "\ea15"
}

.ABB_CommonUX_Icon-32-style__icon-abb_touch_32:before {
    content: "\ea16"
}

.ABB_CommonUX_Icon-32-style__icon-abb_video_32:before {
    content: "\ea17"
}

.ABB_CommonUX_Icon-32-style__icon-abb_turn-clockwise_32:before {
    content: "\ea18"
}

.ABB_CommonUX_Icon-32-style__icon-abb_turn-clockwise-off-target-speed_32:before {
    content: "\ea19"
}

.ABB_CommonUX_Icon-32-style__icon-abb_turn-counter-clockwise-off-target-speed_32:before {
    content: "\ea1a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_add-drives_32:before {
    content: "\ea1b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_bearing_32:before {
    content: "\ea1d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_cloud_32:before {
    content: "\ea1e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_delete-drives_32:before {
    content: "\ea1f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_download-from-cloud_32:before {
    content: "\ea20"
}

.ABB_CommonUX_Icon-32-style__icon-abb_drive-connected_32:before {
    content: "\ea21"
}

.ABB_CommonUX_Icon-32-style__icon-abb_drives-error_32:before {
    content: "\ea22"
}

.ABB_CommonUX_Icon-32-style__icon-abb_fieldbus_32:before {
    content: "\ea23"
}

.ABB_CommonUX_Icon-32-style__icon-abb_generator_32:before {
    content: "\ea24"
}

.ABB_CommonUX_Icon-32-style__icon-abb_maximum-value_32:before {
    content: "\ea26"
}

.ABB_CommonUX_Icon-32-style__icon-abb_minimum-value_32:before {
    content: "\ea27"
}

.ABB_CommonUX_Icon-32-style__icon-abb_not-allowed_32:before {
    content: "\ea28"
}

.ABB_CommonUX_Icon-32-style__icon-abb_powertrain_32:before {
    content: "\ea29"
}

.ABB_CommonUX_Icon-32-style__icon-abb_prevent-turning-fault_32:before {
    content: "\ea2a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_target-value-range_32:before {
    content: "\ea2b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_thumbnail-view_32:before {
    content: "\ea2c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_train_32:before {
    content: "\ea2d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_turn-counter-clockwise_32:before {
    content: "\ea2e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_wheel_32:before {
    content: "\ea2f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_wifi-network-disconnected_32:before {
    content: "\ea31"
}

.ABB_CommonUX_Icon-32-style__icon-abb_write-to-default_32:before {
    content: "\ea32"
}

.ABB_CommonUX_Icon-32-style__icon-abb_write-to-default-disabled_32:before {
    content: "\ea33"
}

.ABB_CommonUX_Icon-32-style__icon-abb_caret-down_32:before {
    content: "\ea08"
}

.ABB_CommonUX_Icon-32-style__icon-abb_caret-left_32:before {
    content: "\ea1c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_caret-right_32:before {
    content: "\ea30"
}

.ABB_CommonUX_Icon-32-style__icon-abb_caret-up_32:before {
    content: "\ea34"
}

.ABB_CommonUX_Icon-32-style__icon-abb_triangle-down_32:before {
    content: "\ea36"
}

.ABB_CommonUX_Icon-32-style__icon-abb_triangle-left_32:before {
    content: "\ea37"
}

.ABB_CommonUX_Icon-32-style__icon-abb_triangle-right_32:before {
    content: "\ea38"
}

.ABB_CommonUX_Icon-32-style__icon-abb_triangle-up_32:before {
    content: "\ea39"
}

.ABB_CommonUX_Icon-32-style__icon-abb_check-mark-light_32:before {
    content: "\ea3a"
}

.ABB_CommonUX_Icon-32-style__icon-abb_numbered-list_32:before {
    content: "\ea3b"
}

.ABB_CommonUX_Icon-32-style__icon-abb_text-bold_32:before {
    content: "\ea3c"
}

.ABB_CommonUX_Icon-32-style__icon-abb_text-italic_32:before {
    content: "\ea3d"
}

.ABB_CommonUX_Icon-32-style__icon-abb_text-underline_32:before {
    content: "\ea3e"
}

.ABB_CommonUX_Icon-32-style__icon-abb_call-outline_32:before {
    content: "\e9fd"
}

.ABB_CommonUX_Icon-32-style__icon-abb_location-outline_32:before {
    content: "\ea25"
}

.ABB_CommonUX_Icon-32-style__icon-abb_user-outline_32:before {
    content: "\ea3f"
}

.ABB_CommonUX_Icon-32-style__icon-abb_close-light_32:before {
    content: "\ea35"
}

.ABB_CommonUX_Icon-32-style__icon-abb_smart-gripper_32:before {
    content: "\ea43"
}

.ABB_CommonUX_Icon-32-style__icon-abb_bookmark_32:before {
    content: "\f101"
}

.ABB_CommonUX_Icon-32-style__icon-abb_tiles_32:before {
    content: "\f102"
}

.ABB_CommonUX_Icon-32-style__icon-abb_edge_32:before {
    content: "\f103"
}

.ABB_CommonUX_Icon-32-style__icon-abb_hierarchy_32:before {
    content: "\f104"
}

.ABB_CommonUX_Icon-32-style__icon-abb_list-2_32:before {
    content: "\f105"
}

.ABB_CommonUX_Icon__root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    display: inline-flex;
    position: relative
}

.ABB_CommonUX_Icon__root.ABB_CommonUX_Icon__isTagged>i {
    -webkit-mask-image: radial-gradient(circle at 100% 0, transparent 18%, #000 21%);
    mask-image: radial-gradient(circle at 100% 0, transparent 18%, #000 21%)
}

.ABB_CommonUX_Icon__root.ABB_CommonUX_Icon__isTagged>.ABB_CommonUX_Icon__tag {
    border-radius: 50%;
    display: inline-flex;
    height: 40%;
    position: absolute;
    right: -20%;
    top: -20%;
    width: 40%
}

.ABB_CommonUX_Icon__root:before {
    box-sizing: border-box;
    display: inline-block;
    font-size: inherit
}

.ABB_CommonUX_Icon__root>i {
    speak: never;
    font-feature-settings: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    vertical-align: top
}

.ABB_CommonUX_Icon__root>i.ABB_CommonUX_Icon__icon_abb_16 {
    font-family: ABB-UI-Icons-16x16 !important;
    font-size: 16px;
    height: 16px;
    width: 16px
}

.ABB_CommonUX_Icon__root>i.ABB_CommonUX_Icon__icon_abb_24 {
    font-family: ABB-UI-Icons-24x24 !important;
    font-size: 24px;
    height: 24px;
    width: 24px
}

.ABB_CommonUX_Icon__root>i.ABB_CommonUX_Icon__icon_abb_32 {
    font-family: ABB-UI-Icons-32x32 !important;
    font-size: 32px;
    height: 32px;
    width: 32px
}

.ABB_CommonUX_Shell_LeftPane__root {
    box-sizing: border-box;
    height: 100%;
    margin-left: 16px;
    position: relative;
    transition: all .3s ease-in-out;
    width: 0;
    z-index: 2
}

.ABB_CommonUX_Shell_LeftPane__root.ABB_CommonUX_Shell_LeftPane__resizing {
    transition: none
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__resize-button {
    align-items: center;
    border-radius: 50%;
    bottom: 32px;
    display: flex;
    font-size: 12px;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: -24px;
    touch-action: none;
    width: 32px
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__resize-button>svg {
    width: 16px
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__resize-button>svg * {
    stroke: #fff;
    fill: #fff
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__resize-button {
    cursor: pointer
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__resize-button.ABB_CommonUX_Shell_LeftPane__draggable {
    cursor: ew-resize
}

.ABB_CommonUX_Shell_LeftPane__root>.ABB_CommonUX_Shell_LeftPane__content {
    height: 100%;
    overflow: hidden
}

.ABB_CommonUX_Shell_LeftPane__root.ABB_CommonUX_Shell_LeftPane__light>.ABB_CommonUX_Shell_LeftPane__resize-button {
    background: #000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    color: #fff
}

.ABB_CommonUX_Shell_LeftPane__root.ABB_CommonUX_Shell_LeftPane__light>.ABB_CommonUX_Shell_LeftPane__resize-button>svg * {
    stroke: #fff;
    fill: #fff
}

.ABB_CommonUX_Shell_LeftPane__root.ABB_CommonUX_Shell_LeftPane__dark>.ABB_CommonUX_Shell_LeftPane__resize-button {
    background: #fff;
    box-shadow: 0 4px 4px hsla(0, 0%, 100%, .3);
    color: #000
}

.ABB_CommonUX_Shell_LeftPane__root.ABB_CommonUX_Shell_LeftPane__dark>.ABB_CommonUX_Shell_LeftPane__resize-button>svg * {
    stroke: #000;
    fill: #000
}

.ABB_CommonUX_Shell_TitleBar__root {
    align-items: center;
    display: flex;
    flex-direction: row
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__logo {
    height: 16px;
    margin: 8px 20px 8px 8px
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__logo>* {
    height: 100%
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__logo.ABB_CommonUX_Shell_TitleBar__red {
    fill: #ff000f
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__application-name {
    font-size: 12px;
    margin-right: 32px;
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__application-name.ABB_CommonUX_Shell_TitleBar__cursor:after {
    border-top-color: initial;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__application-name.ABB_CommonUX_Shell_TitleBar__red:after {
    background: #ff000f
}

.ABB_CommonUX_Shell_TitleBar__root>.ABB_CommonUX_Shell_TitleBar__spacer {
    flex: 1
}

.ABB_CommonUX_Shell_TitleBar__root.ABB_CommonUX_Shell_TitleBar__light>.ABB_CommonUX_Shell_TitleBar__logo:not(.ABB_CommonUX_Shell_TitleBar__red) {
    fill: #000
}

.ABB_CommonUX_Shell_TitleBar__root.ABB_CommonUX_Shell_TitleBar__dark>.ABB_CommonUX_Shell_TitleBar__logo:not(.ABB_CommonUX_Shell_TitleBar__red) {
    fill: #fff
}

.ABB_CommonUX_Shell_TitleBar__root>div.ABB_CommonUX_Shell_TitleBar__systemControlsContainer {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: flex-end
}

.ABB_CommonUX_Shell_AppFooter__root,
.ABB_CommonUX_Shell_ContentFooter__root {
    padding: 8px
}

.ABB_CommonUX_Shell_MainContent__root {
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    position: relative
}

.ABB_CommonUX_Shell_MainContent__root.ABB_CommonUX_Shell_MainContent__paddings {
    padding: 0 16px 16px 0
}

.ABB_CommonUX_AbbBar__root {
    align-items: center;
    display: flex;
    font-size: 12px;
    height: 32px;
    justify-content: space-between;
    white-space: nowrap;
    width: 100%
}

.ABB_CommonUX_AbbBar__root>.ABB_CommonUX_AbbBar__logo {
    display: block;
    height: 15px;
    margin: 4px 8px;
    min-width: 40px;
    position: relative
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__customLogo>.ABB_CommonUX_AbbBar__logo>span {
    left: 8px;
    margin: 0;
    padding: 0;
    position: absolute
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__abbLogo>.ABB_CommonUX_AbbBar__logo>span {
    background: url("data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23ff000f%27 viewBox=%27-1 -1 88.2 35%27><path d=%27M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27 y=%2717%27/><path d=%27M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27/><path d=%27M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27 y=%2717%27/><path d=%27M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27/><polygon points=%275.7,17 0,33 8.3,33 10.7,26 16,26 16,17%27/><polygon points=%2716,0 11.7,0 6,16 16,16%27/><polygon points=%2717,26 22.3,26 24.7,33 33,33 27.3,17 17,17%27/><polygon points=%2727,16 21.3,0 17,0 17,16%27/></svg>") no-repeat;
    background-size: contain;
    display: block;
    height: 100%;
    width: 40px
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__abbLogo>.ABB_CommonUX_AbbBar__logo {
    height: 15px
}

.ABB_CommonUX_AbbBar__root>.ABB_CommonUX_AbbBar__productName {
    font-family: ABBVoice-medium
}

.ABB_CommonUX_AbbBar__root>.ABB_CommonUX_AbbBar__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__light {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__light.ABB_CommonUX_AbbBar__abbLogo.ABB_CommonUX_AbbBar__bwLogo>.ABB_CommonUX_AbbBar__logo>span {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__dark {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__dark.ABB_CommonUX_AbbBar__abbLogo.ABB_CommonUX_AbbBar__bwLogo>.ABB_CommonUX_AbbBar__logo>span {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_AboutBox__root {
    height: 420px;
    position: relative;
    width: 868px
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__header {
    margin-right: 96px;
    position: relative
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__header>.ABB_CommonUX_AboutBox__productName {
    display: block;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 40px;
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__header>.ABB_CommonUX_AboutBox__productName:after {
    color: #ff000f;
    content: "\2014";
    left: 0;
    position: absolute;
    top: -40px
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__productLogo {
    max-width: 140px;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__productLogo>img {
    display: block;
    height: auto;
    width: 100%
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__content .ABB_CommonUX_AboutBox__tabsContent {
    height: 200px
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__footer {
    margin-top: 16px;
    text-align: right
}

.ABB_CommonUX_AboutBox__root>.ABB_CommonUX_AboutBox__footer>* {
    font-size: 12px
}

.ABB_CommonUX_IconButton__root {
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: default;
    display: inline-flex;
    justify-content: center;
    line-height: 16px
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__clickable {
    cursor: pointer
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__disabled {
    cursor: not-allowed;
    opacity: .16
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__extrasmall {
    height: 16px;
    min-width: 16px;
    padding: 0
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__small {
    height: 20px;
    min-width: 20px;
    padding: 2px
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__medium {
    height: 24px;
    min-width: 24px;
    padding: 4px
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__large {
    height: 32px;
    min-width: 32px;
    padding: 4px
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__light {
    color: #1f1f1f
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__light.ABB_CommonUX_IconButton__clickable:not(.ABB_CommonUX_IconButton__disabled):hover {
    background-color: hsla(0, 0%, 53%, .3)
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__light.ABB_CommonUX_IconButton__clickable:not(.ABB_CommonUX_IconButton__disabled):active {
    background-color: hsla(0, 0%, 53%, .6)
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__dark {
    color: #dbdbdb
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__dark.ABB_CommonUX_IconButton__clickable:not(.ABB_CommonUX_IconButton__disabled):hover {
    background-color: hsla(0, 0%, 100%, .3)
}

.ABB_CommonUX_IconButton__root.ABB_CommonUX_IconButton__dark.ABB_CommonUX_IconButton__clickable:not(.ABB_CommonUX_IconButton__disabled):active {
    background-color: hsla(0, 0%, 100%, .6)
}

.ABB_CommonUX_TabControl__root {
    align-items: stretch;
    background: inherit;
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 0;
    padding: 0
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header {
    background: inherit;
    display: inline-flex;
    flex-direction: row;
    height: 32px;
    position: relative
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div {
    background: inherit
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul {
    border: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-family: ABBVoice;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: padding .3s
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li {
    cursor: pointer;
    display: inline-flex;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li>.ABB_CommonUX_TabControl__headerPadding {
    flex: 1 1 auto;
    max-width: 16px;
    min-width: 16px;
    width: 16px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li>.ABB_CommonUX_TabControl__headerPaddingWithClose {
    flex: 1 1 auto;
    max-width: 32px;
    min-width: 32px;
    width: 32px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li>a {
    color: #1f1f1f;
    display: inline-block;
    flex: 2 0 auto;
    font-weight: 400;
    line-height: 32px;
    padding: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: all .3s cubic-bezier(.18, .24, .29, .96);
    vertical-align: top;
    white-space: nowrap
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__tabAddControl {
    max-width: 32px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__tabAddControl>a {
    align-items: center;
    display: inline-flex;
    padding: 0 8px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li>span {
    padding: 6px 4px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li:after {
    background: transparent;
    content: " ";
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s cubic-bezier(.18, .24, .29, .96);
    width: 100%
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled>a {
    cursor: not-allowed
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul.ABB_CommonUX_TabControl__touchscreen {
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul.ABB_CommonUX_TabControl__touchscreen::-webkit-scrollbar {
    height: 0;
    width: 0
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul:not(.ABB_CommonUX_TabControl__touchscreen)>li:hover>span,
.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active>span {
    visibility: visible
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div>ul.ABB_CommonUX_TabControl__showArrowButtons>li.ABB_CommonUX_TabControl__fillerLi {
    background: none;
    padding-left: 8px;
    pointer-events: none
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div.ABB_CommonUX_TabControl__mainTabs {
    overflow: hidden;
    position: relative
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>div.ABB_CommonUX_TabControl__mainTabs.ABB_CommonUX_TabControl__allowAdd {
    max-width: calc(100% - 40px)
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 32px;
    z-index: 1
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:not(.ABB_CommonUX_TabControl__disabled):after {
    content: " ";
    height: 32px;
    position: absolute;
    top: 0;
    width: 8px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft:after {
    left: 0;
    transform: translateX(31px)
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight:after {
    right: 0;
    transform: translateX(-32px)
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton>span {
    align-items: center;
    display: inline-flex;
    padding: 0 4px
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled {
    pointer-events: none
}

.ABB_CommonUX_TabControl__root>.ABB_CommonUX_TabControl__content {
    flex: 2 0 auto;
    max-height: calc(100% - 32px);
    overflow: auto;
    position: relative
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__defaultPadLeft>.ABB_CommonUX_TabControl__header {
    padding-left: 32px;
    transition: padding .3s
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div.ABB_CommonUX_TabControl__mainTabs>ul {
    margin-right: 1px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div.ABB_CommonUX_TabControl__mainTabs>ul>li:not(.ABB_CommonUX_TabControl__lastTab):not(.ABB_CommonUX_TabControl__fillerLi) {
    margin-right: 8px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div:not(.ABB_CommonUX_TabControl__mainTabs)>ul {
    margin-left: 8px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:after {
    background: transparent;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s cubic-bezier(.18, .24, .29, .96);
    width: 100%
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>a {
    height: 32px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    border-top: 1px solid transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul {
    background: transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li {
    border-top: 1px solid transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li:after {
    top: -1px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li>a {
    height: 31px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButtonLeft {
    border-left: 1px solid transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButtonRight {
    border-right: 1px solid transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButtonRight:after {
    right: 0;
    transform: translateX(-31px)
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul {
    background: transparent
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li {
    position: relative
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active {
    z-index: 1
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active+li:after,
.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:hover:not(.ABB_CommonUX_TabControl__active)+li.ABB_CommonUX_TabControl__active:after {
    border-left: 0
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li>a {
    height: 32px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:after,
.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:before {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 2px solid transparent;
    height: 100%
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:after {
    transition: none
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:before {
    height: 3px
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li>:after {
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    color: #1f1f1f
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft {
    left: 0
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft:after {
    background: radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .2), transparent);
    border-left: 1px solid #bababa
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight {
    right: 0
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight:after {
    background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), transparent);
    border-right: 1px solid #bababa
}

.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled)>a,
.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover>a,
.ABB_CommonUX_TabControl__light>.ABB_CommonUX_TabControl__header>div>ul>li>a {
    color: #1f1f1f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: #dbdbdb
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:not(.ABB_CommonUX_TabControl__disabled):hover {
    background-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:not(.ABB_CommonUX_TabControl__disabled):hover:after {
    border-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li {
    background: #dbdbdb
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:hover>span {
    color: #1f1f1f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:before {
    background: rgba(31, 31, 31, .1)
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled) {
    background: #fff
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #36f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active) {
    background-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary.ABB_CommonUX_TabControl__monochrome>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #0f0f0f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: inherit;
    border-top-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:hover:not(.ABB_CommonUX_TabControl__disabled) {
    background-color: #ebebeb
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li {
    border-top-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled) {
    background-color: transparent
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #36f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active) {
    background-color: #ebebeb
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__secondary.ABB_CommonUX_TabControl__monochrome>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #0f0f0f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header:before {
    background: #bababa;
    bottom: 0;
    content: " ";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: inherit;
    border-bottom: 1px solid #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft:hover {
    border-left-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight:hover {
    border-right-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:hover:before {
    background: #9f9f9f;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:before {
    background: #fff;
    bottom: -3px;
    content: " ";
    height: 3px;
    left: 1px;
    position: absolute;
    right: 1px
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__active) {
    border-bottom: 1px solid #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    border-left-color: #bababa;
    border-right-color: #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled)>:after {
    background: #1f1f1f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active):after {
    border-left-color: #bababa;
    border-right-color: #bababa;
    border-top-color: #9f9f9f
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__content {
    border-top: 1px solid #bababa
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__content.ABB_CommonUX_TabControl__borderOnContent {
    border: 1px solid #bababa;
    border-top-color: transparent
}

.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__content {
    background-color: #fff
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft {
    left: 0
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft:after {
    background: radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .8), transparent);
    border-left: 1px solid #dbdbdb
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight {
    right: 0
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight:after {
    background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .8), transparent);
    border-right: 1px solid #dbdbdb
}

.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled)>a,
.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover>a,
.ABB_CommonUX_TabControl__dark>.ABB_CommonUX_TabControl__header>div>ul>li>a {
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: #292929
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:not(.ABB_CommonUX_TabControl__disabled):hover {
    background-color: #333
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:not(.ABB_CommonUX_TabControl__disabled):hover:after {
    border-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:after {
    border-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li {
    background: #292929
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:hover>span {
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:before {
    background: rgba(31, 31, 31, .1)
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled) {
    background: #333
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background: #36f
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active) {
    background-color: #333
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary.ABB_CommonUX_TabControl__monochrome>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #fff
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: inherit;
    border-top-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:after {
    border-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:hover:not(:disabled) {
    background-color: #333;
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li {
    border-top-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled) {
    background-color: transparent
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background: #36f
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active) {
    background-color: #333;
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__secondary.ABB_CommonUX_TabControl__monochrome>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    background-color: #fff
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header:before {
    background: #696969;
    bottom: 0;
    content: " ";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton {
    background: inherit;
    border-bottom: 1px solid #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonLeft:hover {
    border-left: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__scrollButtonRight:hover {
    border-right: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:after {
    border-color: #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton:hover:before {
    background-color: #696969;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>.ABB_CommonUX_TabControl__scrollButton.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:before {
    background: #333;
    bottom: -3px;
    content: " ";
    height: 3px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__active) {
    border-bottom: 1px solid #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__active:not(.ABB_CommonUX_TabControl__disabled):after {
    border-left-color: #696969;
    border-right-color: #696969;
    border-top-color: #dbdbdb
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li:not(.ABB_CommonUX_TabControl__disabled):hover:not(.ABB_CommonUX_TabControl__active):after {
    border-color: #868686 #696969 #696969
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__header>div>ul>li.ABB_CommonUX_TabControl__disabled>* {
    opacity: .5
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__content {
    border-top: 1px solid #1f1f1f
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__panel>.ABB_CommonUX_TabControl__content.ABB_CommonUX_TabControl__borderOnContent {
    border: 1px solid #696969;
    border-top-color: transparent
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__content {
    background-color: #333;
    color: #dbdbdb
}

.ABB_CommonUX_TabControl__tabItemRoot {
    display: none;
    padding: 16px
}

.ABB_CommonUX_TabControl__tabItemRoot.ABB_CommonUX_TabControl__activeContent {
    display: block
}

@font-face {
    font-family: ABBVoice-light;
    font-style: normal;
    font-weight: 300;
    src: url(bundled_assets/ABBvoice_W_Lt-df5a6a9662427e2acc74.eot);
    src: url(bundled_assets/ABBvoice_W_Lt-df5a6a9662427e2acc74.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABBvoice_W_Lt-641d23bcdbe8883e34a9.woff2) format("woff2"), url(bundled_assets/ABBvoice_W_Lt-7cbbe22ae430802eaca5.woff) format("woff"), url(bundled_assets/ABBvoice_W_Lt-e07661110378f7d455f8.ttf) format("truetype")
}

@font-face {
    font-family: ABBVoice;
    font-style: normal;
    font-weight: 400;
    src: url(bundled_assets/ABBvoice_W_Rg-c7fc6c3b3c97ce0553f0.eot);
    src: url(bundled_assets/ABBvoice_W_Rg-c7fc6c3b3c97ce0553f0.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABBvoice_W_Rg-a248e48f3000d4687ec1.woff2) format("woff2"), url(bundled_assets/ABBvoice_W_Rg-0644f4c09357cd85a2a8.woff) format("woff"), url(bundled_assets/ABBvoice_W_Rg-01dc35084c1de55a4195.ttf) format("truetype")
}

@font-face {
    font-family: ABBVoice-medium;
    font-style: normal;
    font-weight: 500;
    src: url(bundled_assets/ABBvoice_W_Md-5396a191679195392d1b.eot);
    src: url(bundled_assets/ABBvoice_W_Md-5396a191679195392d1b.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABBvoice_W_Md-7af58c7929e7d1983e0a.woff2) format("woff2"), url(bundled_assets/ABBvoice_W_Md-27c4edf813b502648ffd.woff) format("woff"), url(bundled_assets/ABBvoice_W_Md-2a4f6e39c31eefdea33f.ttf) format("truetype")
}

@font-face {
    font-family: ABBVoice-bold;
    font-style: normal;
    font-weight: 700;
    src: url(bundled_assets/ABBvoice_W_Bd-cee5b63cb1e2893f6106.eot);
    src: url(bundled_assets/ABBvoice_W_Bd-cee5b63cb1e2893f6106.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABBvoice_W_Bd-f1810d1e03cdc1787695.woff2) format("woff2"), url(bundled_assets/ABBvoice_W_Bd-8dd00c60e309238b4266.woff) format("woff"), url(bundled_assets/ABBvoice_W_Bd-1b80cddef09aaa1e6778.ttf) format("truetype")
}

@font-face {
    font-display: block;
    font-family: ABB-UI-Icons-16x16;
    font-style: normal;
    font-weight: 400;
    src: url(bundled_assets/ABB-UI-Icons-16x16-f0c3717d65eabf7f178b.eot);
    src: url(bundled_assets/ABB-UI-Icons-16x16-f0c3717d65eabf7f178b.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABB-UI-Icons-16x16-511f630669258cc0261d.woff2) format("woff2"), url(bundled_assets/ABB-UI-Icons-16x16-2709ddb767ba191c7b8d.ttf) format("truetype"), url(bundled_assets/ABB-UI-Icons-16x16-062a95bd50e560a2187d.woff) format("woff"), url(bundled_assets/ABB-UI-Icons-16x16-a8cc0d1e92fe4e84d5e5.svg#ABB-UI-Icons-16x16) format("svg")
}

@font-face {
    font-display: block;
    font-family: ABB-UI-Icons-24x24;
    font-style: normal;
    font-weight: 400;
    src: url(bundled_assets/ABB-UI-Icons-24x24-d7cd07882165d6365b27.eot);
    src: url(bundled_assets/ABB-UI-Icons-24x24-d7cd07882165d6365b27.eot?iefix) format("embedded-opentype"), url(bundled_assets/ABB-UI-Icons-24x24-eb151ca7b9da9b2e2b07.woff2) format("woff2"), url(bundled_assets/ABB-UI-Icons-24x24-609f0ec193e888e849b4.ttf) format("truetype"), url(bundled_assets/ABB-UI-Icons-24x24-e729cebf09a4f624cb03.woff) format("woff"), url(bundled_assets/ABB-UI-Icons-24x24-d7c50551962c40b13e9d.svg#ABB-UI-Icons-24x24) format("svg")
}

@font-face {
    font-display: block;
    font-family: ABB-UI-Icons-32x32;
    font-style: normal;
    font-weight: 400;
    src: url(bundled_assets/ABB-UI-Icons-32x32-dec35a56e4f7a6628202.eot);
    src: url(bundled_assets/ABB-UI-Icons-32x32-dec35a56e4f7a6628202.eot?#iefix) format("embedded-opentype"), url(bundled_assets/ABB-UI-Icons-32x32-01bbb85c739444ce4666.woff2) format("woff2"), url(bundled_assets/ABB-UI-Icons-32x32-092246657dae70258b18.ttf) format("truetype"), url(bundled_assets/ABB-UI-Icons-32x32-4ec6c4533ee4b607aeaf.woff) format("woff"), url(bundled_assets/ABB-UI-Icons-32x32-a4f9981dad78207496df.svg#ABB-UI-Icons-32x32) format("svg")
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: ABBvoice, sans-serif;
    font-size: 14px;
    line-height: 1.5
}

.ABB_CommonUX_AppContainer__root {
    background: #ebebeb;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw
}

.ABB_CommonUX_AppContent__root {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    width: 100%
}

.ABB_CommonUX_AppContent__root.ABB_CommonUX_AppContent__light {
    background: #ebebeb
}

.ABB_CommonUX_AppContent__root.ABB_CommonUX_AppContent__dark {
    background: #0f0f0f
}

.ABB_CommonUX_AppFooter__root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    line-height: 16px;
    min-height: 32px;
    padding: 16px 8px
}

.ABB_CommonUX_AppFooter__root>.ABB_CommonUX_AppFooter__content {
    flex: 2
}

.ABB_CommonUX_AppFooter__root>.ABB_CommonUX_AppFooter__copyrightOrLogo {
    flex: 0;
    margin-left: 80px;
    white-space: nowrap
}

.ABB_CommonUX_AppFooter__root>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__copyright {
    font-family: ABBVoice-medium
}

.ABB_CommonUX_AppFooter__root>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__logo {
    background-size: contain;
    height: 15px;
    margin: auto;
    width: 40px
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__light {
    background: #ebebeb;
    color: #696969
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__light>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__copyright {
    color: #1f1f1f
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__light>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__logo {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__dark {
    background: #0f0f0f;
    color: #868686
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__dark>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__copyright {
    color: #dbdbdb
}

.ABB_CommonUX_AppFooter__root.ABB_CommonUX_AppFooter__dark>.ABB_CommonUX_AppFooter__copyrightOrLogo>.ABB_CommonUX_AppFooter__logo {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_AppHeader__root {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    justify-content: space-between;
    max-width: 100%;
    min-height: 32px;
    z-index: 2
}

.ABB_CommonUX_AppHeader__root.ABB_CommonUX_AppHeader__light {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_AppHeader__root.ABB_CommonUX_AppHeader__dark {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_AppHeader__spacer {
    flex: 2;
    min-width: 0
}

.ABB_CommonUX_AppLeftPane__root {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    position: relative;
    transition: width .3s ease-in-out
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__hidden {
    width: 8px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__collapsed {
    width: 48px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__semi {
    width: 96px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__expanded {
    min-width: 300px;
    width: auto
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showRightBorder {
    border-right: 1px solid #868686
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__leftPaneContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__search {
    height: 32px;
    margin-right: 8px
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__title {
    font-size: 14px;
    margin-left: 8px;
    margin-top: 36px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle>.ABB_CommonUX_AppLeftPane__leftPaneContent {
    margin-top: 36px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle.ABB_CommonUX_AppLeftPane__searchBelow>.ABB_CommonUX_AppLeftPane__leftPaneContent,
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle.ABB_CommonUX_AppLeftPane__title>.ABB_CommonUX_AppLeftPane__leftPaneContent {
    margin-top: 4px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle.ABB_CommonUX_AppLeftPane__searchInline>.ABB_CommonUX_AppLeftPane__search {
    left: 40px;
    position: absolute;
    right: 0
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle.ABB_CommonUX_AppLeftPane__searchBelow>.ABB_CommonUX_AppLeftPane__search {
    margin-left: 8px;
    margin-top: 36px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__showVisibilityToggle.ABB_CommonUX_AppLeftPane__title.ABB_CommonUX_AppLeftPane__searchBelow>.ABB_CommonUX_AppLeftPane__search {
    margin-bottom: 4px;
    margin-top: 16px
}

.ABB_CommonUX_AppLeftPane__root:not(.ABB_CommonUX_AppLeftPane__showVisibilityToggle)>.ABB_CommonUX_AppLeftPane__search {
    margin-bottom: 4px;
    margin-left: 8px
}

.ABB_CommonUX_AppLeftPane__root:not(.ABB_CommonUX_AppLeftPane__showVisibilityToggle)>.ABB_CommonUX_AppLeftPane__title {
    margin-top: 4px
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__title:not(.ABB_CommonUX_AppLeftPane__showVisibilityToggle)>.ABB_CommonUX_AppLeftPane__search {
    margin-top: 16px
}

.ABB_CommonUX_AppLeftPane__root:not(.ABB_CommonUX_AppLeftPane__showVisibilityToggle)>.ABB_CommonUX_AppLeftPane__leftPaneContent {
    margin-top: 4px
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__collapserIcon {
    height: 32px;
    left: 8px;
    position: absolute;
    top: 4px;
    width: 32px;
    z-index: 1
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__collapserIcon:hover {
    color: #36f
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__collapserIcon>i {
    margin: 4px
}

.ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__collapserIcon>span {
    display: inline-block;
    transform: rotateY(180deg);
    transition: all .6s ease-in-out
}

.ABB_CommonUX_AppLeftPane__root:after {
    bottom: 0;
    content: " ";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__expanded:after {
    -webkit-animation: ABB_CommonUX_AppLeftPane__semiOpacityAnimation .3s ease-in-out 1;
    animation: ABB_CommonUX_AppLeftPane__semiOpacityAnimation .3s ease-in-out 1
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__semi:after {
    -webkit-animation: ABB_CommonUX_AppLeftPane__expandedOpacityAnimation .3s ease-in-out 1;
    animation: ABB_CommonUX_AppLeftPane__expandedOpacityAnimation .3s ease-in-out 1
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__collapsed>.ABB_CommonUX_AppLeftPane__collapserIcon>span,
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__hidden>.ABB_CommonUX_AppLeftPane__collapserIcon>span {
    transform: rotateY(0deg)
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__collapsed:after {
    -webkit-animation: ABB_CommonUX_AppLeftPane__collapsedOpacityAnimation .3s ease-in-out 1;
    animation: ABB_CommonUX_AppLeftPane__collapsedOpacityAnimation .3s ease-in-out 1
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__hidden:after {
    opacity: 1
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__light {
    background: #ebebeb;
    color: #1f1f1f
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__light:after,
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__light>.ABB_CommonUX_AppLeftPane__collapserIcon {
    background: #ebebeb
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__dark {
    background: #0f0f0f;
    color: #dbdbdb
}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__dark:after,
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__dark>.ABB_CommonUX_AppLeftPane__collapserIcon {
    background: #0f0f0f
}

@-webkit-keyframes ABB_CommonUX_AppLeftPane__collapsedOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

@keyframes ABB_CommonUX_AppLeftPane__collapsedOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes ABB_CommonUX_AppLeftPane__semiOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

@keyframes ABB_CommonUX_AppLeftPane__semiOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes ABB_CommonUX_AppLeftPane__expandedOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

@keyframes ABB_CommonUX_AppLeftPane__expandedOpacityAnimation {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    75% {
        opacity: .5
    }

    to {
        opacity: 0
    }
}

.ABB_CommonUX_Input__root {
    display: block;
    font-size: 14px;
    max-width: 100%;
    position: relative;
    text-align: left
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer {
    display: flex;
    white-space: nowrap
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__additionalLabel,
.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__label {
    display: block;
    font-weight: 400;
    margin-bottom: 4px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__additionalLabel {
    font-size: 12px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__requiredAsterisk {
    color: #f03040
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__label {
    overflow: hidden;
    text-overflow: ellipsis
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__labelContainer>.ABB_CommonUX_Input__additionalLabel {
    margin-left: auto;
    padding-left: 8px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper {
    border: 1px solid transparent;
    display: flex;
    flex: 1;
    height: 32px;
    position: relative
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__hasFixedSize {
    width: 100%
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__hasFixedSize) {
    display: inline-flex;
    height: auto;
    width: 100%
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__hasFixedSize)>.ABB_CommonUX_Input__inputElement {
    min-height: 32px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement {
    background-color: inherit;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    color: inherit;
    flex: 1;
    font-family: ABBVoice;
    font-size: 14px;
    height: 100%;
    min-width: 2ch;
    outline: none;
    padding: 4px 8px;
    text-overflow: ellipsis;
    vertical-align: top
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement:not(.ABB_CommonUX_Input__resizable) {
    resize: none
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement:focus {
    outline: none
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date],
.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time] {
    -webkit-appearance: none
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::-webkit-inner-spin-button {
    display: none
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=number] {
    -moz-appearance: textfield
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: text;
    height: auto;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__prefix {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    margin: 7px 0 7px 8px;
    min-width: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__clearIcon {
    height: 20px;
    margin: auto 4px;
    width: 20px;
    z-index: 1
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__iconSeparator {
    height: 24px;
    margin: auto 8px auto 0;
    width: 1px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__suffix {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    margin: 7px 8px 7px 0;
    min-width: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__iconWrapper {
    box-sizing: border-box;
    cursor: text;
    height: 16px;
    margin: 8px 8px 8px 0;
    width: 16px;
    z-index: 1
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__iconWrapper.ABB_CommonUX_Input__clickableIcon {
    cursor: pointer
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer {
    height: auto
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer.ABB_CommonUX_Input__hidden {
    opacity: 0
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer.ABB_CommonUX_Input__validationCollapsed {
    height: 0;
    visibility: hidden
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer {
    color: #464646;
    display: flex;
    flex-direction: row;
    margin-top: 4px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer>.ABB_CommonUX_Input__validationIconWrapper {
    align-items: center;
    color: #f03040;
    cursor: default;
    display: flex;
    height: 16px;
    margin-right: 6px
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer>.ABB_CommonUX_Input__validationMessage {
    font-size: 12px;
    font-weight: 500
}

.ABB_CommonUX_Input__root:not(.ABB_CommonUX_Input__isTextarea)>.ABB_CommonUX_Input__inputWrapper:after {
    background: transparent;
    bottom: -1px;
    content: " ";
    height: 2px;
    left: -1px;
    pointer-events: none;
    position: absolute;
    right: -1px
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__isValid.ABB_CommonUX_Input__showValidationResult>.ABB_CommonUX_Input__inputWrapper:after {
    background: #0ca919
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__hasChanged>.ABB_CommonUX_Input__inputWrapper:after {
    background: #36f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__isInvalid.ABB_CommonUX_Input__showValidationResult>.ABB_CommonUX_Input__inputWrapper:after {
    background: #f03040
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__description {
    font-size: 12px;
    margin-top: 4px
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__disabled {
    pointer-events: none
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement {
    background: transparent
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__description,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__labelContainer {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__isEmpty>.ABB_CommonUX_Input__inputWrapper {
    color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper {
    color: #1f1f1f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__prefix,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__suffix {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::-moz-placeholder {
    color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::placeholder {
    color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date],
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time] {
    -webkit-appearance: none
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::-moz-placeholder,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::-moz-placeholder {
    color: transparent
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::placeholder,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::placeholder {
    color: transparent
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus):before,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus):before {
    color: #bababa;
    content: attr(placeholder);
    width: 100%
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__disabled {
    color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled) .ABB_CommonUX_Input__iconSeparator {
    background: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconSeparator,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):hover>.ABB_CommonUX_Input__iconSeparator {
    background: #9f9f9f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconWrapper {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconWrapper.ABB_CommonUX_Input__clickableIcon {
    color: #1f1f1f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper {
    background: #fff;
    border-color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__disabled {
    background-color: #f5f5f5;
    border-color: #dbdbdb
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):not(.ABB_CommonUX_Input__focus):hover {
    background-color: #ebebeb;
    border-color: #9f9f9f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled) {
    border-color: #36f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper {
    border-color: transparent transparent #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__disabled {
    border-bottom-color: #dbdbdb
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):not(.ABB_CommonUX_Input__focus):hover {
    border-bottom-color: #9f9f9f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled) {
    border-bottom-color: #36f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__description,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__labelContainer {
    color: #868686
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__isEmpty {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper {
    color: #dbdbdb
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__prefix,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark:not(.ABB_CommonUX_Input__isEmpty)>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__suffix {
    color: #bababa
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::-moz-placeholder {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement::placeholder {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::-moz-placeholder,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::-moz-placeholder {
    color: transparent
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::placeholder,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus)::placeholder {
    color: transparent
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=date][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus):before,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper>.ABB_CommonUX_Input__inputElement[type=time][placeholder]:not(.ABB_CommonUX_Input__hasValue):not(.ABB_CommonUX_Input__hasFocus):before {
    color: #696969;
    content: attr(placeholder);
    width: 100%
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__disabled {
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled) .ABB_CommonUX_Input__iconSeparator {
    background: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconSeparator,
.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):hover>.ABB_CommonUX_Input__iconSeparator {
    background: #868686
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconWrapper {
    color: #868686
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark>.ABB_CommonUX_Input__inputWrapper:not(.ABB_CommonUX_Input__disabled)>.ABB_CommonUX_Input__iconWrapper.ABB_CommonUX_Input__clickableIcon {
    color: #dbdbdb
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper {
    background: #1f1f1f;
    border-color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__disabled {
    background-color: #333;
    color: #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):not(.ABB_CommonUX_Input__focus):hover {
    background-color: #333;
    border-color: #868686
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled) {
    border-color: #36f
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper {
    border-color: transparent transparent #696969
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__mouseOverInput:not(.ABB_CommonUX_Input__disabled):not(.ABB_CommonUX_Input__focus):hover {
    border-bottom-color: #868686
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__dark.ABB_CommonUX_Input__discreet>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__focus:not(.ABB_CommonUX_Input__disabled) {
    border-bottom-color: #36f
}

.ABB_CommonUX_AppMainContent__root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    position: relative
}

.ABB_CommonUX_AppMainContent__root.ABB_CommonUX_AppMainContent__spaceOnTop {
    padding-top: 38px
}

.ABB_CommonUX_AppMainContent__root.ABB_CommonUX_AppMainContent__light {
    background: #ebebeb
}

.ABB_CommonUX_AppMainContent__root.ABB_CommonUX_AppMainContent__dark {
    background: #0f0f0f
}

.ABB_CommonUX_AppTopNavi__root {
    flex: 1;
    margin-right: 2px;
    position: relative
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__allowScroll {
    min-width: 90px
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__widthExceeds {
    overflow: hidden
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 40px;
    position: absolute;
    top: 0;
    width: 40px;
    z-index: 1
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton:after {
    bottom: 4px;
    content: " ";
    position: absolute;
    top: 4px;
    width: 8px
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonLeft {
    left: 0
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonLeft:after {
    left: 0;
    transform: translateX(39px)
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonRight {
    right: 0
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonRight:after {
    right: 0;
    transform: translateX(-39px)
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__scrollButton>span {
    align-items: center;
    display: inline-flex;
    padding: 0 8px
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer.ABB_CommonUX_AppTopNavi__alignLeft {
    justify-content: flex-start
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer.ABB_CommonUX_AppTopNavi__alignCenter {
    justify-content: center
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer.ABB_CommonUX_AppTopNavi__alignRight {
    justify-content: flex-end
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer.ABB_CommonUX_AppTopNavi__touchscreen {
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer.ABB_CommonUX_AppTopNavi__touchscreen::-webkit-scrollbar {
    height: 0;
    width: 0
}

.ABB_CommonUX_AppTopNavi__root>.ABB_CommonUX_AppTopNavi__itemContainer:not(.ABB_CommonUX_AppTopNavi__touchscreen)>li:hover>span {
    visibility: visible
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__light.ABB_CommonUX_AppTopNavi__monochrome>.ABB_CommonUX_AppTopNavi__itemContainer>.ABB_CommonUX_AppTopNavi__active>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header:after {
    background: #0f0f0f
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__light>.ABB_CommonUX_AppTopNavi__scrollButton {
    background: #fff
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__light>.ABB_CommonUX_AppTopNavi__scrollButton:hover {
    background: #ebebeb
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__light>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonLeft:after {
    background: radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .2), transparent)
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__light>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonRight:after {
    background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), transparent)
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__dark.ABB_CommonUX_AppTopNavi__monochrome>.ABB_CommonUX_AppTopNavi__itemContainer>.ABB_CommonUX_AppTopNavi__active>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header:after {
    background: #fff
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__dark>.ABB_CommonUX_AppTopNavi__scrollButton {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__dark>.ABB_CommonUX_AppTopNavi__scrollButton:hover {
    background: #696969
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__dark>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonLeft:after {
    background: radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .8), transparent)
}

.ABB_CommonUX_AppTopNavi__root.ABB_CommonUX_AppTopNavi__dark>.ABB_CommonUX_AppTopNavi__scrollButton.ABB_CommonUX_AppTopNavi__scrollButtonRight:after {
    background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .8), transparent)
}

.ABB_CommonUX_AppTopNavi__fontFix {
    font-size: 16px
}

.ABB_CommonUX_AppTopNavi__item {
    height: 100%;
    outline: none;
    position: relative
}

.ABB_CommonUX_AppTopNavi__item:focus {
    outline: none
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container {
    height: 100%
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header {
    align-items: center;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 100%;
    margin: 0;
    min-height: 40px;
    padding: 0 8px 0 0;
    text-decoration: none;
    white-space: nowrap
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconOnly {
    padding-left: 8px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconOnly>div.ABB_CommonUX_AppTopNavi__icon {
    align-items: center;
    display: inline-flex;
    height: 100%;
    margin: 0 8px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__textOnly {
    justify-content: center;
    min-width: 100px;
    padding-left: 16px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__textOnly>div.ABB_CommonUX_AppTopNavi__text {
    align-items: center;
    display: inline-flex;
    height: 100%;
    margin-right: 8px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconAndText {
    padding-left: 8px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconAndText>div.ABB_CommonUX_AppTopNavi__icon {
    align-items: center;
    display: inline-flex;
    height: 100%;
    margin-right: 8px
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconAndText>div.ABB_CommonUX_AppTopNavi__text {
    align-items: center;
    display: inline-flex;
    height: 100%
}

.ABB_CommonUX_AppTopNavi__item>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header>div.ABB_CommonUX_AppTopNavi__caret {
    align-items: center;
    display: inline-flex;
    height: 16px;
    padding: 0 8px
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__active>.ABB_CommonUX_AppTopNavi__container>.ABB_CommonUX_AppTopNavi__header:after {
    background: #36f;
    bottom: 0;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__disabled {
    pointer-events: none
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__disabled>.ABB_CommonUX_AppTopNavi__header {
    cursor: not-allowed
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light {
    color: #1f1f1f
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light:not(.ABB_CommonUX_AppTopNavi__active):not(.ABB_CommonUX_AppTopNavi__disabled)>.ABB_CommonUX_AppTopNavi__container:hover {
    background: #ebebeb
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light:not(.ABB_CommonUX_AppTopNavi__active):not(.ABB_CommonUX_AppTopNavi__disabled)>.ABB_CommonUX_AppTopNavi__container:active {
    background: #dbdbdb
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light.ABB_CommonUX_AppTopNavi__disabled {
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__dark {
    color: #dbdbdb
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__dark:not(.ABB_CommonUX_AppTopNavi__active):not(.ABB_CommonUX_AppTopNavi__disabled)>.ABB_CommonUX_AppTopNavi__container:hover {
    background: #464646
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__dark:not(.ABB_CommonUX_AppTopNavi__active):not(.ABB_CommonUX_AppTopNavi__disabled)>.ABB_CommonUX_AppTopNavi__container:active {
    background: #696969
}

.ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__dark.ABB_CommonUX_AppTopNavi__disabled {
    color: hsla(0, 0%, 53%, .5)
}

.ABB_CommonUX_AppTopNavi__divider {
    background: #9f9f9f;
    height: 32px;
    margin: 4px;
    width: 1px
}

.ABB_CommonUX_Checkbox__root {
    display: block;
    font-family: ABBvoice;
    font-size: 14px;
    height: 32px;
    margin-left: 8px
}

.ABB_CommonUX_Checkbox__root>label {
    cursor: pointer;
    display: inline-block;
    padding-top: 3px;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__disabled>label {
    cursor: not-allowed;
    opacity: .6
}

.ABB_CommonUX_Checkbox__root>label:before {
    border: 2px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    position: absolute;
    transition: border .2s cubic-bezier(.18, .24, .29, .96), color .15s cubic-bezier(.18, .24, .29, .96) .2s
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__checked>label:after {
    color: #fff;
    display: inline-block;
    line-height: 16px;
    position: absolute
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__indeterminate>label:after {
    content: " ";
    display: inline-block;
    position: absolute
}

.ABB_CommonUX_Checkbox__root>label>input {
    left: 12px;
    opacity: 0;
    position: absolute;
    top: 8px;
    width: 0;
    z-index: 1
}

.ABB_CommonUX_Checkbox__root>label>span {
    display: inline-block;
    margin-top: 4px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__largeSize>label {
    padding-left: 32px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__largeSize>label:before {
    height: 24px;
    left: 0;
    margin-left: 0;
    top: 4px;
    width: 24px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__largeSize.ABB_CommonUX_Checkbox__checked>label:after {
    content: "\ea3a";
    font-family: ABB-UI-Icons-24x24 !important;
    font-size: 24px;
    height: 24px;
    left: 0;
    top: 8px;
    width: 24px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__largeSize.ABB_CommonUX_Checkbox__indeterminate>label:after {
    height: 4px;
    left: 4px;
    top: 14px;
    width: 16px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__smallSize>label {
    padding-left: 24px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__smallSize>label:before {
    height: 16px;
    left: 0;
    margin-left: 0;
    top: 9px;
    width: 16px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__smallSize.ABB_CommonUX_Checkbox__checked>label:after {
    content: "\ea3a";
    font-family: ABB-UI-Icons-16x16 !important;
    font-size: 16px;
    height: 16px;
    left: 0;
    top: 9px;
    width: 16px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__smallSize.ABB_CommonUX_Checkbox__indeterminate>label:after {
    height: 2px;
    left: 3px;
    top: 16px;
    width: 10px
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light {
    color: #1f1f1f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light>label:before {
    background-color: #fff;
    border-color: #bababa
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light>label:hover:before {
    background-color: #ebebeb;
    border-color: #9f9f9f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light>label:active:before {
    background-color: #dbdbdb;
    border-color: #868686
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:active:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__disabled>label:hover:before {
    background-color: #fff;
    border-color: #bababa
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__checked>label:before {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__checked>label:hover:before {
    background-color: #2a35ff;
    border-color: #2a35ff
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__checked>label:active:before {
    background-color: #2c20d4;
    border-color: #2c20d4
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__indeterminate>label:after {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #2a35ff;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__indeterminate>label:active:after {
    background-color: #2c20d4;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__indeterminate>label:hover:before {
    background-color: #ebebeb;
    border-color: #9f9f9f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__indeterminate>label:active:before {
    background-color: #dbdbdb;
    border-color: #868686
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label:before {
    border: 1px solid transparent
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label.ABB_CommonUX_Checkbox__indeterminate>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome>label:hover:before {
    background-color: #fff;
    border-color: #0f0f0f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:active:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #0f0f0f;
    border-color: #0f0f0f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled {
    color: #bababa
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label {
    opacity: 1
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label.ABB_CommonUX_Checkbox__indeterminate>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:hover:before {
    background-color: #fff;
    border-color: #dbdbdb
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__light.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:after {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark {
    color: #dbdbdb
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark>label:before {
    background-color: #1f1f1f;
    border-color: #464646
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark>label:hover:before {
    background-color: #333;
    border: 2px solid #696969
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark>label:active:before {
    background-color: #464646;
    border-color: #868686
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:active:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__disabled>label:hover:before {
    background-color: #1f1f1f;
    border-color: #464646
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__checked>label:before {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__checked>label:hover:before {
    background-color: #4c85ff;
    border-color: #4c85ff
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__checked>label:active:before {
    background-color: #77a3fc;
    border-color: #77a3fc
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__indeterminate>label:after {
    background: #36f;
    background-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #4c85ff;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__indeterminate>label:active:after {
    background-color: #77a3fc;
    border-color: #36f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__indeterminate>label:hover:before {
    background-color: #333;
    border-color: #696969
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__indeterminate>label:active:before {
    background-color: #464646;
    border-color: #868686
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome>label:before {
    border: 1px solid transparent
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome>label:hover:before {
    background-color: transparent;
    border-color: #fff
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome>label:after {
    color: #0f0f0f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:active:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:after,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:hover:after {
    background-color: #fff;
    border-color: #fff
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__indeterminate>label:hover:before {
    background-color: transparent;
    border-color: #fff
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label {
    color: #9f9f9f
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label.ABB_CommonUX_Checkbox__indeterminate>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label.ABB_CommonUX_Checkbox__indeterminate>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:hover:before {
    background-color: transparent;
    border-color: #464646
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled>label:after {
    color: #dbdbdb

}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:active:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__checked>label:hover:before,
.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__dark.ABB_CommonUX_Checkbox__monochrome.ABB_CommonUX_Checkbox__disabled.ABB_CommonUX_Checkbox__indeterminate>label:after {
    background-color: #464646;
    border-color: #464646
}

.ABB_CommonUX_Spinner__root {
    -webkit-animation: ABB_CommonUX_Spinner__rotation .75s linear infinite;
    animation: ABB_CommonUX_Spinner__rotation .75s linear infinite;
    display: inline-block;
    position: relative;
    transform-origin: center
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__scaled {
    height: inherit;
    width: inherit
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__small {
    height: 16px;
    width: 16px;
    /* Nagesh Start */
    margin:auto; 
     /* Nagesh End */
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__medium {
    height: 32px;
    width: 32px
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__large {
    height: 40px;
    width: 40px
}

.ABB_CommonUX_Spinner__root>svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ABB_CommonUX_Spinner__root>svg>circle {
    fill: none;
    stroke-width: 10;
    stroke-dasharray: 215
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__darkgrey>svg>circle {
    stroke: #868686
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__blue>svg>circle {
    stroke: #36f
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__red>svg>circle {
    stroke: #ff000f
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__light.ABB_CommonUX_Spinner__grey>svg>circle {
    stroke: #bababa
}

.ABB_CommonUX_Spinner__root.ABB_CommonUX_Spinner__dark.ABB_CommonUX_Spinner__grey>svg>circle {
    stroke: #868686
}

@-webkit-keyframes ABB_CommonUX_Spinner__rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes ABB_CommonUX_Spinner__rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.ABB_CommonUX_Dropdown__root {
    font-size: 14px;
    text-align: left
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label {
    color: #696969;
    margin: 6px 12px;
    float: left;
    width: 190px;
    text-align: right;
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label>.ABB_CommonUX_Dropdown__requiredAsterisk {
    color: #f03040
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__description {
    color: #696969;
    font-size: 12px;
    margin-top: 4px
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__comp {
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__comp:after {
    background: transparent;
    bottom: 0;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__comp>div>div>.ABB_CommonUX_Dropdown__CustomValueContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    height: auto;
    overflow: hidden;
    position: relative
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__validationMessageContainer {
    color: #464646;
    display: flex;
    flex-direction: row;
    height: auto;
    margin-top: 4px
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__validationMessageContainer.ABB_CommonUX_Dropdown__hidden {
    opacity: 0
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__validationMessageContainer.ABB_CommonUX_Dropdown__hideValidationContainer {
    display: none
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__validationMessageContainer>.ABB_CommonUX_Dropdown__validationIconWrapper {
    color: #f03040;
    height: 16px;
    margin-right: 6px
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__validationMessageContainer>.ABB_CommonUX_Dropdown__validationMessage {
    font-size: 12px;
    font-weight: 500
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__statusValid.ABB_CommonUX_Dropdown__showValidationBarWhenValid:not(.ABB_CommonUX_Dropdown__open)>.ABB_CommonUX_Dropdown__comp:after {
    background: #0ca919
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__invalid:not(.ABB_CommonUX_Dropdown__open)>.ABB_CommonUX_Dropdown__comp:after,
.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__statusInvalid.ABB_CommonUX_Dropdown__showValidationBarWhenInvalid:not(.ABB_CommonUX_Dropdown__open)>.ABB_CommonUX_Dropdown__comp:after {
    background: #f03040
}

.ABB_CommonUX_Dropdown__root:focus {
    outline: none
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__light.ABB_CommonUX_Dropdown__hasChanged>.ABB_CommonUX_Dropdown__comp:after {
    background: #36f
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__light>.ABB_CommonUX_Dropdown__label,
.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__light>div:first-child,
.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__light>div:last-child {
    color: #696969
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__dark.ABB_CommonUX_Dropdown__hasChanged>.ABB_CommonUX_Dropdown__comp:after {
    background: #36f
}

.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__dark>.ABB_CommonUX_Dropdown__label,
.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__dark>div:first-child,
.ABB_CommonUX_Dropdown__root.ABB_CommonUX_Dropdown__dark>div:last-child {
    color: #868686
}

.ABB_CommonUX_BarChart__root {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: space-between;
    min-height: 24px;
    width: 100%
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__title {
    font-family: ABBVoice;
    font-size: 16px;
    margin-left: 14px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend>.ABB_CommonUX_BarChart__legendItem {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0 8px;
    transition: opacity .3s
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend>.ABB_CommonUX_BarChart__legendItem.ABB_CommonUX_BarChart__disabled {
    opacity: .4
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend>.ABB_CommonUX_BarChart__legendItem.ABB_CommonUX_BarChart__highlight {
    text-decoration: underline
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend>.ABB_CommonUX_BarChart__legendItem>.ABB_CommonUX_BarChart__legendItemColor {
    border-radius: 3px;
    display: inline-block;
    height: 10px;
    margin-right: 4px;
    width: 10px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__header>.ABB_CommonUX_BarChart__legend>.ABB_CommonUX_BarChart__legendItem>.ABB_CommonUX_BarChart__legendItemLabel {
    color: #1f1f1f;
    font-family: ABBVoice;
    font-size: 14px;
    height: 16px;
    line-height: 16px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart {
    flex: 1;
    margin: 10px;
    position: relative;
    width: calc(100% - 20px)
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg {
    height: 100%;
    left: 0;
    overflow: visible;
    position: absolute;
    top: 0;
    width: 100%
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg.ABB_CommonUX_BarChart__initialHide {
    opacity: 0
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis>g>text {
    fill: #696969;
    font-family: ABBVoice;
    font-size: 12px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis.ABB_CommonUX_BarChart__xAxis {
    text-anchor: end
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis.ABB_CommonUX_BarChart__xAxis>path {
    stroke-width: 2px;
    stroke: #1f1f1f
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis.ABB_CommonUX_BarChart__yAxis>path {
    display: none
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis.ABB_CommonUX_BarChart__yAxis>g>line {
    stroke: #bababa
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axis.ABB_CommonUX_BarChart__yAxis>g>text {
    font-family: ABBVoice;
    padding-right: 30px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axisLabel {
    fill: #1f1f1f;
    font-family: ABBVoice;
    font-size: 14px;
    line-height: 1
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axisLabel.ABB_CommonUX_BarChart__axisLabelX {
    text-anchor: start
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__axisLabel.ABB_CommonUX_BarChart__axisLabelY {
    text-anchor: end
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__thresholdLine {
    stroke: #1f1f1f;
    stroke-width: 2;
    stroke-dasharray: 4 1
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__thresholdLine.ABB_CommonUX_BarChart__warningLine {
    stroke: #ff7300
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__thresholdLine.ABB_CommonUX_BarChart__alarmLine {
    stroke: #f03040
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__valueGroup>rect {
    transition: opacity 1.3s
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__valueGroup>rect.ABB_CommonUX_BarChart__disabled {
    opacity: .2
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart>svg>g>.ABB_CommonUX_BarChart__valueGroup>rect.ABB_CommonUX_BarChart__highlight {
    stroke: #000;
    stroke-width: 3px
}

.ABB_CommonUX_BarChart__root>.ABB_CommonUX_BarChart__chart.ABB_CommonUX_BarChart__vertical>svg>g>.ABB_CommonUX_BarChart__xAxis {
    text-anchor: middle
}

.ABB_CommonUX_Button__root {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: ABBVoice;
    font-size: 14px;
    padding: 0;
    white-space: nowrap
}

.ABB_CommonUX_Button__root>div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative
}

.ABB_CommonUX_Button__root>div>span {
    height: 16px
}

.ABB_CommonUX_Button__root>div>span.ABB_CommonUX_Button__icon,
.ABB_CommonUX_Button__root>div>span.ABB_CommonUX_Button__loadingIndicator {
    position: absolute
}

.ABB_CommonUX_Button__root>div>span.ABB_CommonUX_Button__text {
    line-height: 16px;
    text-align: center
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize {
    height: 32px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div {
    min-width: 28px;
    padding: 0 6px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__icon,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__loadingIndicator {
    height: 16px;
    left: 6px;
    top: 6px;
    width: 16px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText {
    margin: 0 8px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText.ABB_CommonUX_Button__hasIcon {
    margin-left: 24px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize {
    height: 40px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize>div {
    min-width: 36px;
    padding: 0 10px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize>div>span.ABB_CommonUX_Button__icon,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize>div>span.ABB_CommonUX_Button__loadingIndicator {
    height: 16px;
    left: 10px;
    top: 10px;
    width: 16px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText {
    margin: 0 8px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__mediumSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText.ABB_CommonUX_Button__hasIcon {
    margin-left: 28px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize {
    font-size: 16px;
    height: 48px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize>div {
    min-width: 44px;
    padding: 0 10px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize>div>span.ABB_CommonUX_Button__icon,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize>div>span.ABB_CommonUX_Button__loadingIndicator {
    height: 24px;
    left: 10px;
    top: 10px;
    width: 24px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText {
    margin: 0 20px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__largeSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText.ABB_CommonUX_Button__hasIcon {
    margin-left: 36px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__rounded {
    border-radius: 4px;
    transition: border-radius .2s cubic-bezier(.18, .24, .29, .96)
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__pill.ABB_CommonUX_Button__smallSize {
    border-radius: 16px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__pill.ABB_CommonUX_Button__mediumSize {
    border-radius: 20px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__pill.ABB_CommonUX_Button__largeSize {
    border-radius: 24px
}

.ABB_CommonUX_Button__root:focus {
    outline: none
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__focus-visible {
    box-shadow: none;
    outline: 2px solid #36f;
    outline-offset: -2px
}

.ABB_CommonUX_Button__root:focus-visible {
    box-shadow: none;
    outline: 2px solid #36f;
    outline-offset: -2px
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__normal {
    background-color: #dbdbdb;
    border: 2px solid #dbdbdb;
    color: #1f1f1f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #bababa;
    border-color: #bababa
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #9f9f9f;
    border-color: #9f9f9f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue {
    background-color: #36f;
    border: 2px solid #36f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue.ABB_CommonUX_Button__focus-visible {
    outline-color: #2c20d4
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue:focus-visible {
    outline-color: #2c20d4
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue {
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #2a35ff;
    border-color: #2a35ff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #2c20d4;
    border-color: #2c20d4
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblack {
    background-color: #0f0f0f;
    border: 2px solid #0f0f0f;
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #333;
    border-color: #333
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #464646;
    border-color: #464646
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryred {
    background-color: #ff000f;
    border: 2px solid #ff000f;
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #cc0815;
    border-color: #cc0815
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #a30005;
    border-color: #a30005
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost {
    background-color: hsla(0, 0%, 100%, .05);
    border: 2px solid #1f1f1f;
    color: #1f1f1f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):hover,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #ebebeb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblue {
    background-color: transparent;
    border: 2px solid transparent;
    color: #36f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):hover {
    border-color: #36f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #e0f0ff;
    border-color: #36f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblack {
    background-color: transparent;
    border: 2px solid transparent;
    color: #1f1f1f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):hover {
    border-color: #0f0f0f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #ebebeb;
    border-color: #0f0f0f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__disabled:not(.ABB_CommonUX_Button__discreetblue):not(.ABB_CommonUX_Button__discreetblack) {
    background-color: #ebebeb;
    border: 2px solid #ebebeb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__disabled {
    color: rgba(31, 31, 31, .5);
    cursor: not-allowed
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__disabled.ABB_CommonUX_Button__ghost {
    border: 2px solid #bababa
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__normal {
    background-color: #464646;
    border: 2px solid #464646;
    color: #dbdbdb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #696969;
    border-color: #696969
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__normal:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #868686;
    border-color: #868686
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue {
    background-color: #36f;
    border: 2px solid #36f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue.ABB_CommonUX_Button__focus-visible {
    outline-color: #2c20d4
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue:focus-visible {
    outline-color: #2c20d4
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue {
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #4c85ff;
    border-color: #4c85ff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblue:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #77a3fc;
    border-color: #77a3fc
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblack {
    background-color: #fff;
    border: 2px solid #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #ebebeb;
    border-color: #ebebeb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryblack:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryred {
    background-color: #ff000f;
    border: 2px solid #ff000f;
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):hover {
    background-color: #e12e2e;
    border-color: #e12e2e
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__primaryred:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #ff4754;
    border-color: #ff4754
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__ghost {
    background-color: hsla(0, 0%, 6%, .05);
    border: 2px solid #fff;
    color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):hover,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__ghost:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #333
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblue {
    background-color: transparent;
    border: 2px solid transparent;
    color: #4c85ff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):hover {
    border-color: #4c85ff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblue:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #333;
    border-color: #4c85ff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblack {
    background-color: transparent;
    border: 2px solid transparent;
    color: #dbdbdb
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):hover {
    border-color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):active,
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__discreetblack:not(.ABB_CommonUX_Button__disabled):hover:active {
    background-color: #333;
    border-color: #fff
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__disabled:not(.ABB_CommonUX_Button__discreetblue):not(.ABB_CommonUX_Button__discreetblack) {
    background-color: #333;
    border: 2px solid #696969
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__disabled {
    color: hsla(0, 0%, 86%, .5);
    cursor: not-allowed
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__dark.ABB_CommonUX_Button__disabled.ABB_CommonUX_Button__ghost {
    border: 2px solid #696969
}

.ABB_CommonUX_Breadcrumb__root {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
}

.ABB_CommonUX_Breadcrumb__root>.ABB_CommonUX_Breadcrumb__separator {
    color: silver;
    margin: 0 8px 0 4px;
    opacity: .6;
    pointer-events: none
}

.ABB_CommonUX_Breadcrumb__item {
    font-size: 12px
}

.ABB_CommonUX_Breadcrumb__item>a,
.ABB_CommonUX_Breadcrumb__item>a:visited {
    color: inherit
}

.ABB_CommonUX_Breadcrumb__item.ABB_CommonUX_Breadcrumb__light {
    color: #050505
}

.ABB_CommonUX_Breadcrumb__item.ABB_CommonUX_Breadcrumb__dark {
    color: #fafafa
}

.ABB_CommonUX_Card__container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 8px 0 0
}

.ABB_CommonUX_Card__containerColumn {
    display: flex;
    flex: 1;
    flex-direction: column
}

.ABB_CommonUX_Card__containerColumn:not(:first-child) {
    margin-left: 8px
}

.ABB_CommonUX_Card__card {
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px
}

.ABB_CommonUX_Card__card>.ABB_CommonUX_Card__title {
    flex: 0
}

.ABB_CommonUX_Card__card>.ABB_CommonUX_Card__content {
    flex: 1;
    overflow: auto
}

.ABB_CommonUX_Card__card.ABB_CommonUX_Card__light {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Card__card.ABB_CommonUX_Card__dark {
    background: #050505;
    color: #dbdbdb
}

.ABB_CommonUX_Card__card.ABB_CommonUX_Card__light.ABB_CommonUX_Card__inverted {
    background: #f5f5f5
}

.ABB_CommonUX_Card__card.ABB_CommonUX_Card__dark.ABB_CommonUX_Card__inverted {
    background: #1f1f1f
}

.ABB_CommonUX_Collapsible__containerRoot {
    overflow: auto;
    padding: 0;
    white-space: nowrap
}

.ABB_CommonUX_Collapsible__root {
    cursor: pointer;
    font-size: 14px;
    position: relative
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__shouldOpenOnNextCycle {
    background: #36f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__hasChildren>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    align-items: center;
    border-radius: 50%;
    display: inline-block;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    top: 4px;
    transition: background .3s;
    width: 24px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__hasChildren:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 11px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__hasChildren.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    right: 8px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__alarm>.ABB_CommonUX_Collapsible__header:after,
.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__warning>.ABB_CommonUX_Collapsible__header:after {
    bottom: 4px;
    content: " ";
    position: absolute;
    right: 4px;
    top: 4px;
    width: 4px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__warning>.ABB_CommonUX_Collapsible__header:after {
    background: #ff7300
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__alarm>.ABB_CommonUX_Collapsible__header:after {
    background: #f03040
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header {
    align-items: center;
    display: flex;
    height: 32px;
    padding-left: 8px;
    position: relative;
    vertical-align: middle
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__icon {
    margin-right: 8px;
    vertical-align: middle
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__checkbox {
    display: inline-block
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__title {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap
}

.ABB_CommonUX_Collapsible__root:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 16px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__filtered>.ABB_CommonUX_Collapsible__header {
    opacity: .2
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header {
    background: #ebebeb;
    color: #1f1f1f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected {
    background: #dbdbdb
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected:before {
    background: #36f;
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon:hover {
    background-color: hsla(0, 0%, 53%, .3)
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon:active {
    background-color: hsla(0, 0%, 53%, .6)
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header:hover {
    background-color: #dbdbdb
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header:active {
    background-color: #bababa
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>div>div>.ABB_CommonUX_Collapsible__customContent {
    background: #ebebeb
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header {
    background: transparent
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected:before {
    background: #0f0f0f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header:active,
.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header:hover {
    background-color: #ebebeb
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark {
    color: #dbdbdb
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header {
    background: #0f0f0f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected {
    background: #1f1f1f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected:before {
    background: #36f;
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon:hover {
    background-color: hsla(0, 0%, 100%, .3)
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon:active {
    background-color: hsla(0, 0%, 100%, .6)
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header:hover {
    background-color: #1f1f1f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>.ABB_CommonUX_Collapsible__header:active {
    background-color: #333
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark>div>div>.ABB_CommonUX_Collapsible__customContent {
    background: #0f0f0f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header {
    background: transparent
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header.ABB_CommonUX_Collapsible__isSelected:before {
    background: #fff
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header:active,
.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__dark.ABB_CommonUX_Collapsible__monochrome>.ABB_CommonUX_Collapsible__header:hover {
    background-color: #1f1f1f
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth0:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 40px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 64px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth2:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 88px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth3:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 112px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth4:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 136px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth5:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 160px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth6:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 184px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth7:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 208px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth8:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 232px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth9:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header {
    padding-left: 256px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth0>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 40px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 64px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth2>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 88px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth3>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 112px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth4>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 136px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth5>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 160px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth6>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 184px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth7>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 208px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth8>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 232px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth9>div>div>.ABB_CommonUX_Collapsible__customContent {
    padding: 8px 0 8px 256px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth0:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 8px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 32px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth2:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 56px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth3:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 80px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth4:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 104px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth5:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 128px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth6:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 152px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth7:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 176px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth8:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 200px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth9:not(.ABB_CommonUX_Collapsible__expanderOnRight)>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__expanderIcon {
    left: 224px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth0.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 16px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 40px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth2.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 64px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth3.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 88px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth4.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 112px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth5.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 136px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth6.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 160px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth7.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 184px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth8.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 208px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth9.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    padding-left: 232px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1:before {
    width: 8px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth2:before {
    width: 16px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth3:before {
    width: 24px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth4:before {
    width: 32px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth5:before {
    width: 40px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth6:before {
    width: 48px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth7:before {
    width: 56px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth8:before {
    width: 64px
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth9:before {
    width: 72px
}

.ABB_CommonUX_Datagrid__root {
    position: relative
}

.ABB_CommonUX_Datagrid__root table,
.ABB_CommonUX_Datagrid__root tbody,
.ABB_CommonUX_Datagrid__root td,
.ABB_CommonUX_Datagrid__root tfoot,
.ABB_CommonUX_Datagrid__root th,
.ABB_CommonUX_Datagrid__root thead,
.ABB_CommonUX_Datagrid__root tr {
    border: none;
    box-sizing: border-box;
    font-weight: inherit;
    line-height: 1;
    margin: 0;
    padding: 0;
    text-align: left
}

.ABB_CommonUX_Datagrid__root>table {
    border-collapse: collapse;
    border-spacing: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: inherit;
    overflow-x: hidden;
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td {
    line-height: 20px;
    padding-bottom: 4px;
    padding-top: 4px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__smallRowHeight>tbody>tr>td {
    min-height: 40px;
    padding-bottom: 3px;
    padding-top: 4px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__smallRowHeight>tbody>tr>td.ABB_CommonUX_Datagrid__cellHasStatus>.ABB_CommonUX_Datagrid__cellStatusIndicator {
    bottom: 4px;
    top: 4px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__mediumRowHeight>tbody>tr>td {
    min-height: 48px;
    padding-bottom: 7px;
    padding-top: 8px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__mediumRowHeight>tbody>tr>td.ABB_CommonUX_Datagrid__cellHasStatus>.ABB_CommonUX_Datagrid__cellStatusIndicator {
    bottom: 8px;
    top: 8px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__largeRowHeight>tbody>tr>td {
    min-height: 56px;
    padding-bottom: 7px;
    padding-top: 8px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__largeRowHeight>tbody>tr>td.ABB_CommonUX_Datagrid__cellHasStatus>.ABB_CommonUX_Datagrid__cellStatusIndicator {
    bottom: 12px;
    top: 12px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__smallRowHeight:not(.ABB_CommonUX_Datagrid__fitToContentHeight)>tbody>tr>td:not(.ABB_CommonUX_Datagrid__expansionContent):not(.ABB_CommonUX_Datagrid__ghostRowCell) {
    height: 40px;
    max-height: 40px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__mediumRowHeight:not(.ABB_CommonUX_Datagrid__fitToContentHeight)>tbody>tr>td:not(.ABB_CommonUX_Datagrid__expansionContent):not(.ABB_CommonUX_Datagrid__ghostRowCell) {
    height: 48px;
    max-height: 48px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__largeRowHeight:not(.ABB_CommonUX_Datagrid__fitToContentHeight)>tbody>tr>td:not(.ABB_CommonUX_Datagrid__expansionContent):not(.ABB_CommonUX_Datagrid__ghostRowCell) {
    height: 56px;
    max-height: 56px
}

.ABB_CommonUX_Datagrid__root>table>thead {
    display: grid;
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table>thead>tr {
    display: contents
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th {
    align-items: center;
    border-bottom: 1px solid transparent;
    display: flex;
    font-family: ABBVoice-medium;
    font-size: 12px;
    font-style: normal;
    height: 40px;
    line-height: 1;
    max-height: 40px;
    min-height: 40px;
    overflow: hidden;
    padding: 8px 16px;
    position: relative;
    text-transform: uppercase;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__statusIndicatorHeader {
    padding: 0;
    width: 4px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__columnFilterHeader {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>div {
    max-width: 100%;
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__headerText {
    display: block;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__sortOrderControls {
    opacity: 0;
    position: absolute;
    right: -16px;
    top: -2px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__sortOrderControls.ABB_CommonUX_Datagrid__isSorted {
    opacity: 1
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__sortOrderControls.ABB_CommonUX_Datagrid__rightAlign {
    left: -16px;
    right: auto
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th:hover>div>.ABB_CommonUX_Datagrid__sortOrderControls:not(.ABB_CommonUX_Datagrid__isSorted) {
    opacity: .5
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th>.ABB_CommonUX_Datagrid__resizehandle {
    background-clip: content-box;
    bottom: 0;
    box-sizing: border-box;
    cursor: col-resize;
    opacity: 0;
    padding: 0 0 0 13px;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .5s;
    width: 15px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__isSorted:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):after {
    bottom: -1px;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__lastCol>.ABB_CommonUX_Datagrid__headerText {
    margin-right: 25px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__lastCol>.ABB_CommonUX_Datagrid__resizehandle {
    padding: 0 0 0 6px;
    width: 8px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__lastCol:after {
    right: 0
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__headerBeingResized>.ABB_CommonUX_Datagrid__resizehandle {
    opacity: 1
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged {
    opacity: .9;
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged:after {
    bottom: 0;
    content: " ";
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__headerDragOver:not(.ABB_CommonUX_Datagrid__isBuiltIn) {
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__headerDragOver:not(.ABB_CommonUX_Datagrid__isBuiltIn):after {
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    top: 0;
    width: 2px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__lastHheaderDragOverOnRight:not(.ABB_CommonUX_Datagrid__isBuiltIn) {
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__lastHheaderDragOverOnRight:not(.ABB_CommonUX_Datagrid__isBuiltIn):after {
    bottom: 0;
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 2px
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostColumnHeader {
    padding: 0 !important
}

.ABB_CommonUX_Datagrid__root>table>thead>tr.ABB_CommonUX_Datagrid__columnFilterRow>th {
    max-height: 64px;
    min-height: 64px;
    width: auto
}

.ABB_CommonUX_Datagrid__root>table>thead>tr.ABB_CommonUX_Datagrid__columnFilterRow>th:not(.ABB_CommonUX_Datagrid__clearAll)>* {
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table>thead>tr.ABB_CommonUX_Datagrid__columnFilterRow>th.ABB_CommonUX_Datagrid__clearAll {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;

    >* {
        max-width: none
    }
}

.ABB_CommonUX_Datagrid__root>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th>.ABB_CommonUX_Datagrid__isDraggable,
.ABB_CommonUX_Datagrid__root>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th>.ABB_CommonUX_Datagrid__isSortable {
    cursor: pointer
}

.ABB_CommonUX_Datagrid__root>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th:not(.ABB_CommonUX_Datagrid__headerBeingDragged):hover>.ABB_CommonUX_Datagrid__resizehandle {
    opacity: 1
}

.ABB_CommonUX_Datagrid__root>table>tbody {
    border-bottom: 1px solid transparent;
    display: grid;
    grid-template-rows: auto;
    overflow: auto;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr {
    display: contents
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td {
    align-items: center;
    border-bottom: 1px solid transparent;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td:not(.ABB_CommonUX_Datagrid__expansionContent) {
    overflow: hidden
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__lastCol {
    padding-right: 25px
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__ghostColumnCell {
    padding: 0 !important
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__isBuiltIn {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__expansionContent {
    grid-column: 1/-1;
    height: auto;
    line-height: 1;
    max-height: none;
    min-height: 0;
    min-height: auto;
    overflow: auto;
    padding: 16px 0;
    white-space: normal
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__loadingIndicator {
    border: 0;
    grid-column: 1/-1;
    height: auto;
    line-height: inherit;
    margin: 0;
    max-height: none;
    min-height: 0;
    min-height: auto
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__customMessage {
    border: 0;
    grid-column: 1/-1;
    height: auto;
    line-height: inherit;
    margin: 20px 0;
    max-height: none;
    min-height: 0;
    min-height: auto
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__statusIndicatorCell {
    padding-left: 0;
    padding-right: 0
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__statusIndicatorCell>span {
    background: transparent;
    display: block;
    height: 100%;
    width: 4px
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__cellHasStatus {
    position: relative
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__cellHasStatus>.ABB_CommonUX_Datagrid__cellStatusIndicator {
    border: 1px solid transparent;
    left: 4px;
    pointer-events: none;
    position: absolute;
    right: 4px
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td>span.ABB_CommonUX_Datagrid__rowExpansionTrigger {
    display: flex
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr.ABB_CommonUX_Datagrid__ghostRow>td.ABB_CommonUX_Datagrid__ghostRowCell {
    border: 0;
    grid-column: 1/-1;
    height: auto;
    line-height: inherit;
    max-height: none;
    min-height: 0;
    min-height: auto;
    overflow: auto;
    padding: 0
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__fitToContentHeight>tbody>tr>td>div {
    white-space: normal
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter>tbody {
    max-height: calc(100% - 80px);
    min-height: calc(100% - 80px)
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasColumnFilters>tbody {
    max-height: calc(100% - 104px);
    min-height: calc(100% - 104px)
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter.ABB_CommonUX_Datagrid__hasColumnFilters>tbody {
    max-height: calc(100% - 144px);
    min-height: calc(100% - 144px)
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter>tfoot {
    display: grid;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter>tfoot>tr {
    display: contents
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter>tfoot>tr>td {
    align-items: center;
    display: flex;
    font-family: ABBVoice-bold;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 1;
    max-height: 40px;
    min-height: 40px;
    padding: 8px 16px
}

.ABB_CommonUX_Datagrid__root>table.ABB_CommonUX_Datagrid__hasFooter>tfoot>tr>td>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__leftAlign,
.ABB_CommonUX_Datagrid__root>table>tfoot>tr>td.ABB_CommonUX_Datagrid__leftAlign,
.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__leftAlign {
    justify-content: flex-start;
    text-align: left
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__rightAlign,
.ABB_CommonUX_Datagrid__root>table>tfoot>tr>td.ABB_CommonUX_Datagrid__rightAlign,
.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__rightAlign {
    justify-content: flex-end;
    text-align: right
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td.ABB_CommonUX_Datagrid__centerAlign,
.ABB_CommonUX_Datagrid__root>table>tfoot>tr>td.ABB_CommonUX_Datagrid__centerAlign,
.ABB_CommonUX_Datagrid__root>table>thead>tr>th.ABB_CommonUX_Datagrid__centerAlign {
    justify-content: center;
    text-align: center
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>tbody>tr>td:last-child,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>tfoot>tr>td:last-child,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>thead>tr>th:last-child {
    padding-right: 16px
}

.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>tbody>tr>td:first-child,
.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>tfoot>tr>td:first-child,
.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>thead>tr>th:first-child {
    padding-left: 0
}

.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>tbody>tr>td:last-child,
.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>tfoot>tr>td:last-child,
.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__hasSidePaddings)>table>thead>tr>th:last-child {
    padding-right: 0
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>tbody>tr>td:first-child,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>tfoot>tr>td:first-child,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>thead>tr>th:first-child {
    padding-left: 16px
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__hasSidePaddings>table>tbody>tr>td.ABB_CommonUX_Datagrid__statusIndicatorCell:first-child {
    padding-left: 4px
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__allowHorizontalScroll>.ABB_CommonUX_Datagrid__showColumnResetHandle .ABB_CommonUX_Datagrid__lastCol {
    border-right: 1px solid silver
}

.ABB_CommonUX_Datagrid__root:not(.ABB_CommonUX_Datagrid__allowHorizontalScroll)>table>tbody {
    overflow-x: hidden
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light:not(.ABB_CommonUX_Datagrid__transparentBackground)>table,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>tbody,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>tfoot>tr>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>thead>tr>th {
    background-color: #fff
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table {
    color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isDraggable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isResizable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isSortable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover {
    background-color: #ebebeb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th {
    color: #868686
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__sortOrderControls {
    color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th>.ABB_CommonUX_Datagrid__resizehandle {
    background-color: #bababa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__isSorted {
    border-bottom-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__headerBeingResized>.ABB_CommonUX_Datagrid__resizehandle,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__isSorted:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):after {
    background-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__headerBeingDragged {
    background-color: #ebebeb;
    color: hsla(0, 0%, 53%, .5)
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged:after {
    background: hsla(0, 0%, 100%, .9);
    border: 1px solid #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostColumnCell,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostColumnHeader {
    background: #fafafa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__headerDragOver:not(.ABB_CommonUX_Datagrid__isBuiltIn):after,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>thead>tr>th.ABB_CommonUX_Datagrid__lastHheaderDragOverOnRight:not(.ABB_CommonUX_Datagrid__isBuiltIn):after {
    background-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #ebebeb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)>td {
    background-color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #bababa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)+.ABB_CommonUX_Datagrid__expansionRow>td {
    background-color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr>td.ABB_CommonUX_Datagrid__ghostColumnCell {
    background: #fafafa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr.ABB_CommonUX_Datagrid__isRowActive:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)>td {
    background-color: #e0f0ff
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr.ABB_CommonUX_Datagrid__isRowActive:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #c5dff9
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tfoot>tr>td {
    color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__zebra>table>tbody>tr.ABB_CommonUX_Datagrid__zebraHighlight:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td.ABB_CommonUX_Datagrid__highlight {
    background-color: #fafafa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__zebra>table>tbody>tr.ABB_CommonUX_Datagrid__zebraHighlight:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td.ABB_CommonUX_Datagrid__highlight {
    background-color: #f5f5f5
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead>tr:not(.ABB_CommonUX_Datagrid__columnFilterRow)>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):not(.ABB_CommonUX_Datagrid__isSorted) {
    border-bottom-color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody>tr.ABB_CommonUX_Datagrid__isRowExpanded>td:not(.ABB_CommonUX_Datagrid__rowExpansionColumn):not(.ABB_CommonUX_Datagrid__statusIndicatorCell),
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowExpanded):not(:last-child)>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead>tr.ABB_CommonUX_Datagrid__columnFilterRow>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody {
    border-bottom-color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>thead>tr>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowExpanded):not(:last-child)>td {
    border-bottom-color: #ebebeb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>tbody,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNone>table>thead>tr>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark:not(.ABB_CommonUX_Datagrid__transparentBackground)>table,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>tbody,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>tfoot>tr>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark:not(.ABB_CommonUX_Datagrid__transparentBackground)>table>thead>tr>th {
    background-color: #1f1f1f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table {
    color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isDraggable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isResizable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead:not(.ABB_CommonUX_Datagrid__colResizeOngoing)>tr>th.ABB_CommonUX_Datagrid__isSortable:not(.ABB_CommonUX_Datagrid__isBuiltIn):hover {
    background-color: #333
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th {
    color: #868686
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th>div>.ABB_CommonUX_Datagrid__sortOrderControls {
    color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th>.ABB_CommonUX_Datagrid__resizehandle {
    background-color: #696969
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__isSorted {
    border-bottom-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__headerBeingResized>.ABB_CommonUX_Datagrid__resizehandle,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__isSorted:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):after {
    background-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__headerBeingDragged {
    background-color: #333;
    color: hsla(0, 0%, 53%, .5)
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged:after {
    background: rgba(31, 31, 31, .9);
    border: 1px solid #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostColumnCell,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__ghostColumnHeader {
    background: #050505
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__headerDragOver:not(.ABB_CommonUX_Datagrid__isBuiltIn):after,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>thead>tr>th.ABB_CommonUX_Datagrid__lastHheaderDragOverOnRight:not(.ABB_CommonUX_Datagrid__isBuiltIn):after {
    background-color: #36f
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #333
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)>td {
    background-color: #464646
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #696969
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr.ABB_CommonUX_Datagrid__isRowSelected.ABB_CommonUX_Datagrid__isRowSelected:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)+.ABB_CommonUX_Datagrid__expansionRow>td {
    background-color: #464646
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr>td.ABB_CommonUX_Datagrid__ghostColumnCell {
    background: #050505
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr.ABB_CommonUX_Datagrid__isRowActive:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow)>td {
    background-color: #263359
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr.ABB_CommonUX_Datagrid__isRowActive:not(.ABB_CommonUX_Datagrid__ghostRow):not(.ABB_CommonUX_Datagrid__expansionRow):hover>td {
    background-color: #374980
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tfoot>tr>td {
    color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__zebra>table>tbody>tr.ABB_CommonUX_Datagrid__zebraHighlight:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td.ABB_CommonUX_Datagrid__highlight {
    background-color: #242424
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__zebra>table>tbody>tr.ABB_CommonUX_Datagrid__zebraHighlight:not(.ABB_CommonUX_Datagrid__isRowSelected):not(.ABB_CommonUX_Datagrid__isRowActive):not(:hover)>td.ABB_CommonUX_Datagrid__highlight {
    background-color: #292929
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead>tr:not(.ABB_CommonUX_Datagrid__columnFilterRow)>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged):not(.ABB_CommonUX_Datagrid__isSorted) {
    border-bottom-color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowExpanded):not(:last-child)>td,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNormal>table>thead>tr.ABB_CommonUX_Datagrid__columnFilterRow>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #696969
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody {
    border-bottom-color: #dbdbdb
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>thead>tr>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #696969
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>tbody>tr:not(.ABB_CommonUX_Datagrid__isRowExpanded):not(:last-child)>td {
    border-bottom-color: #333
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleDiscreet>table>tbody,
.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__dark.ABB_CommonUX_Datagrid__borderStyleNone>table>thead>tr>th:not(.ABB_CommonUX_Datagrid__ghostForHeaderBeingDragged) {
    border-bottom-color: #696969
}

.ABB_CommonUX_Dialog__root {
    margin: 8px;
    max-height: 100%
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header {
    position: relative
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__title {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 18px 32px 4px
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__title.ABB_CommonUX_Dialog__titleWithCloseButton {
    margin-right: 56px
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__closeButton {
    margin: 16px;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__closeButton.ABB_CommonUX_Dialog__hasStandardButtonsOnTop {
    margin: 8px 16px
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__content {
    background: inherit;
    padding: 16px 16px 8px 32px
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__content.ABB_CommonUX_Dialog__smallRightPadding {
    padding-right: 32px
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnBottom {
    display: flex;
    flex-direction: column
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnBottom>header {
    flex: 0
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnBottom>.ABB_CommonUX_Dialog__content {
    flex: 1
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnBottom>.ABB_CommonUX_Dialog__standardButtonsOnBottom {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 48px;
    padding: 8px 16px 8px 0
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnBottom>.ABB_CommonUX_Dialog__standardButtonsOnBottom>* {
    flex: 0;
    margin-left: 16px
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnTop>.ABB_CommonUX_Dialog__header {
    border-bottom: 1px solid silver;
    display: flex
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnTop>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__title {
    flex: 1;
    margin-right: 8px
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnTop>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__standardButtonsOnTop {
    display: inline-block;
    margin: 8px 0
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnTop>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__standardButtonsOnTop.ABB_CommonUX_Dialog__hasCloseButton {
    margin-right: 64px
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__withStandardButtonsOnTop>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__standardButtonsOnTop>* {
    margin: 0 8px
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__light {
    background: #fff;
    border-top: 4px solid #0f0f0f;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .35);
    color: #1f1f1f
}

.ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__dark {
    background: #333;
    border-top: 4px solid #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, .5);
    color: #dbdbdb
}

.ABB_CommonUX_Dialog__dialog {
    margin: auto;
    max-width: calc(100% - 16px);
    outline: none;
    pointer-events: all
}

.ABB_CommonUX_Dialog__dialog.ABB_CommonUX_Dialog__light {
    color: #1f1f1f
}

.ABB_CommonUX_Dialog__dialog.ABB_CommonUX_Dialog__dark {
    color: #dbdbdb
}

.ABB_CommonUX_Dialog__overlay {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000
}

.ABB_CommonUX_Dialog__overlay.ABB_CommonUX_Dialog__transparentBackdrop {
    background: transparent;
    pointer-events: none
}

.ABB_CommonUX_Dialog__overlay.ABB_CommonUX_Dialog__fullOpacityBackdrop.ABB_CommonUX_Dialog__light {
    background: #ebebeb
}

.ABB_CommonUX_Dialog__overlay.ABB_CommonUX_Dialog__fullOpacityBackdrop.ABB_CommonUX_Dialog__dark {
    background: #333
}

.ABB_CommonUX_Dialog__overlay.ABB_CommonUX_Dialog__halfOpacityBackdrop.ABB_CommonUX_Dialog__light {
    background: rgba(51, 51, 51, .5)
}

.ABB_CommonUX_Dialog__overlay.ABB_CommonUX_Dialog__halfOpacityBackdrop.ABB_CommonUX_Dialog__dark {
    background: hsla(0, 0%, 6%, .5)
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__standardButtonsOnBottom {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 48px;
    padding: 8px 16px 8px 0
}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__standardButtonsOnBottom>* {
    flex: 0;
    margin-left: 16px
}

.ABB_CommonUX_DonutChart__root {
    display: flex;
    max-height: 100%;
    max-width: 100%;
    /* position: relative */
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__topLegend {
    flex-direction: column
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__topLegend>.ABB_CommonUX_DonutChart__legend {
    flex: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 32px
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__bottomLegend {
    flex-direction: column
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__bottomLegend>.ABB_CommonUX_DonutChart__legend {
    flex: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 32px
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__leftLegend {
    flex-direction: row;
    justify-content: flex-start
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__leftLegend>.ABB_CommonUX_DonutChart__legend {
    align-content: flex-start;
    flex: 0;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 24px
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__rightLegend {
    flex-direction: row;
    justify-content: flex-start
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__rightLegend>.ABB_CommonUX_DonutChart__legend {
    align-content: flex-start;
    flex: 1 0 0%;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 24px
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart {
    display: block;
    flex: 1 0 0%;
    max-height: 100%;
    max-width: 100%;
    min-height: 96px;
    min-width: 96px;
    overflow: hidden;
    /* position: relative */
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg {
    height: 100%;
    left: 0;
    overflow: visible;
    /* position: absolute; */
    top: 0;
    width: 100%
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg>g {
    shape-rendering: geometricPrecision
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg>g>text {
    dominant-baseline: middle;
    text-anchor: middle
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__label,
.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__valueText {
    font-weight: 700
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg>g>path {
    stroke-width: 0;
    transition: opacity .3s
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend {
    display: inline-block;
    display: flex
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow {
    margin-right: 16px
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span {
    align-items: center;
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span.ABB_CommonUX_DonutChart__isClickable {
    cursor: pointer
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span>.ABB_CommonUX_DonutChart__dot {
    border-radius: 2px;
    display: inline-block;
    height: 8px;
    margin: 4px;
    width: 8px
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span>.ABB_CommonUX_DonutChart__legendRowText {
    margin-left: 8px
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow.ABB_CommonUX_DonutChart__legendHover:not(.ABB_CommonUX_DonutChart__hiddenValue) {
    text-decoration: underline
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow.ABB_CommonUX_DonutChart__hiddenValue {
    opacity: .5
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__light>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__label,
.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__light>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__valueText {
    fill: #1f1f1f
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__light>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__description {
    fill: #696969
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__light>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span>.ABB_CommonUX_DonutChart__legendRowText {
    color: #1f1f1f
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__dark>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__label,
.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__dark>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__valueText {
    fill: #dbdbdb
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__dark>.ABB_CommonUX_DonutChart__chart>svg>g>text.ABB_CommonUX_DonutChart__description {
    fill: #868686
}

.ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__dark>.ABB_CommonUX_DonutChart__legend>.ABB_CommonUX_DonutChart__legendRow>span>.ABB_CommonUX_DonutChart__legendRowText {
    color: #dbdbdb
}

.ABB_CommonUX_Expander__root {
    border-bottom: 2px solid #1f1f1f;
    box-sizing: border-box;
    padding-bottom: 16px;
    transition: max-height .3s ease
}

.ABB_CommonUX_Expander__root.ABB_CommonUX_Expander__isNested {
    margin-left: 16px
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 700
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span {
    box-sizing: border-box;
    display: inline-block
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span:first-child>* {
    margin: 16px;
    transition: all .3s
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header>span:last-child {
    height: 48px;
    line-height: 16px;
    margin: 0 40px 0 0;
    padding: 16px;
    vertical-align: bottom
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__header:hover {
    background: silver
}

.ABB_CommonUX_Expander__root>.ABB_CommonUX_Expander__content {
    transition: opacity .3s ease
}

.ABB_CommonUX_Expander__root:not(.ABB_CommonUX_Expander__open) {
    max-height: 48px
}

.ABB_CommonUX_Expander__root:not(.ABB_CommonUX_Expander__open)>.ABB_CommonUX_Expander__content {
    opacity: 0;
    pointer-events: none
}

.ABB_CommonUX_Expander__root.ABB_CommonUX_Expander__open {
    max-height: 100vh
}

.ABB_CommonUX_Expander__root.ABB_CommonUX_Expander__open>.ABB_CommonUX_Expander__header {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Expander__root.ABB_CommonUX_Expander__open>.ABB_CommonUX_Expander__header>span:first-child>* {
    transform: rotate(90deg)
}

.ABB_CommonUX_Expander__root.ABB_CommonUX_Expander__open>.ABB_CommonUX_Expander__content {
    opacity: 1
}

.ABB_CommonUX_FilePicker__root {
    border: 1px dashed transparent;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    min-height: 32px;
    min-width: 32px;
    transition: background .3s
}

.ABB_CommonUX_FilePicker__root>form {
    height: 100%;
    width: 100%
}

.ABB_CommonUX_FilePicker__root>form>label {
    align-items: center;
    cursor: pointer;
    display: block;
    display: flex;
    font-size: 16px;
    height: 100%;
    justify-content: center;
    width: 100%
}

.ABB_CommonUX_FilePicker__root>form>label>input {
    display: none
}

.ABB_CommonUX_FilePicker__root>form>label>div {
    align-items: center;
    display: flex;
    flex-direction: column
}

.ABB_CommonUX_FilePicker__root>form>label>div>div {
    margin: 16px 16px 8px
}

.ABB_CommonUX_FilePicker__root>form>label>div>div.ABB_CommonUX_FilePicker__selectedFiles>p {
    margin: 0
}

.ABB_CommonUX_FilePicker__root.ABB_CommonUX_FilePicker__light {
    border-color: #868686;
    color: #696969
}

.ABB_CommonUX_FilePicker__root.ABB_CommonUX_FilePicker__light.ABB_CommonUX_FilePicker__hover {
    background: rgba(0, 0, 0, .05)
}

.ABB_CommonUX_FilePicker__root.ABB_CommonUX_FilePicker__dark {
    border-color: #868686;
    color: #9f9f9f
}

.ABB_CommonUX_FilePicker__root.ABB_CommonUX_FilePicker__dark.ABB_CommonUX_FilePicker__hover {
    background: hsla(0, 0%, 100%, .05)
}

.ABB_CommonUX_Gauge__root {
    height: 100%;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header {
    display: flex;
    flex-direction: row;
    line-height: 16px;
    margin-bottom: 16px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header span>a {
    color: inherit
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header span>a[href]:hover {
    text-decoration: underline
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header span {
    font-family: ABBVoice-bold;
    font-size: 16px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:first-child {
    flex: 10
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child {
    flex: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__value {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__unit {
    margin-left: 4px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar {
    height: 14px;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__bg {
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__fg {
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits {
    position: absolute
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel {
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div {
    bottom: 0;
    position: absolute;
    top: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowWarn {
    border-top: 2px dashed #ff7300;
    left: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowAlarm {
    background: #f03040;
    left: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highWarn {
    border-top: 2px dashed #ff7300;
    right: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highAlarm {
    background: #f03040;
    right: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal {
    height: 16px;
    left: 0;
    right: 0;
    top: -4px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div {
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowWarn {
    border-right: 1px dashed #1f1f1f;
    width: 1px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowAlarm {
    border-right: 2px solid #1f1f1f;
    width: 2px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highWarn {
    border-left: 1px dashed #1f1f1f;
    width: 1px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highAlarm {
    border-left: 2px solid #1f1f1f;
    width: 2px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__minMax {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 16px;
    margin-top: 2px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__minMax>span {
    font-size: 14px
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g {
    shape-rendering: geometricPrecision
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>a[href]:hover {
    text-decoration: underline
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>a>.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label {
    text-anchor: middle;
    alignment-baseline: before-edge;
    font-weight: 700
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__value {
    text-anchor: middle;
    alignment-baseline: baseline;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    font-weight: 700
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__unit {
    text-anchor: middle;
    alignment-baseline: central
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__min {
    text-anchor: start;
    alignment-baseline: text-before-edge
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__max {
    text-anchor: end;
    alignment-baseline: text-before-edge
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__background,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__foreground {
    stroke: none
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelHighAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelLowAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelLowWarningLimit {
    fill: none
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__parallelLowWarningLimit {
    stroke-dasharray: 4;
    stroke-dashoffset: 2
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowWarningLimit {
    stroke-width: 2px;
    fill: none
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowWarningLimit {
    stroke-dasharray: 2
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header span {
    color: #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__unit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__value {
    color: #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__bg {
    background: #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__fg {
    background: orange
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowWarn {
    border-top: 2px dashed #ff7300
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowAlarm {
    background: #f03040
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highWarn {
    border-top: 2px dashed #ff7300
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highAlarm {
    background: #f03040
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowWarn {
    border-right: 1px dashed #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowAlarm {
    border-right: 2px solid #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highWarn {
    border-left: 1px dashed #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highAlarm {
    border-left: 2px solid #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__minMax>span {
    color: #696969
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__value,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__value {
    fill: #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__max,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__min,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__unit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__max,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__min,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__unit {
    fill: #696969
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__background {
    fill: #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__light.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowWarningLimit {
    stroke: #1f1f1f
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header span {
    color: #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__unit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__header>span:last-child>.ABB_CommonUX_Gauge__value {
    color: #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__bg {
    background: #464646
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__fg {
    background: orange
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowWarn {
    border-top: 2px dashed #ff7300
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__lowAlarm {
    background: #f03040
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highWarn {
    border-top: 2px dashed #ff7300
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__parallel>div.ABB_CommonUX_Gauge__highAlarm {
    background: #f03040
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowWarn {
    border-right: 1px dashed #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__lowAlarm {
    border-right: 2px solid #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highWarn {
    border-left: 1px dashed #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__bar>.ABB_CommonUX_Gauge__limits.ABB_CommonUX_Gauge__orthogonal>div.ABB_CommonUX_Gauge__highAlarm {
    border-left: 2px solid #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__linear>.ABB_CommonUX_Gauge__minMax>span {
    color: #868686
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__value,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__value {
    fill: #dbdbdb
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__max,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__min,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>a.ABB_CommonUX_Gauge__unit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__max,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__min,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__unit {
    fill: #868686
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__background {
    fill: #464646
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallHighWarningLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowAlarmLimit,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__dark.ABB_CommonUX_Gauge__radial>svg>g>.ABB_CommonUX_Gauge__orthogonallLowWarningLimit {
    stroke: #dbdbdb
}

.ABB_CommonUX_Heading__root {
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_Heading__root:before {
    content: normal
}

.ABB_CommonUX_Heading__root.ABB_CommonUX_Heading__withCursor:after {
    color: #ff000f;
    content: "\2014";
    display: block;
    left: 0;
    position: absolute
}

.ABB_CommonUX_Heading__root.ABB_CommonUX_Heading__light,
.ABB_CommonUX_Heading__root.ABB_CommonUX_Heading__light.ABB_CommonUX_Heading__withCursor.ABB_CommonUX_Heading__blackwhite:after {
    color: #1f1f1f
}

.ABB_CommonUX_Heading__root.ABB_CommonUX_Heading__dark,
.ABB_CommonUX_Heading__root.ABB_CommonUX_Heading__dark.ABB_CommonUX_Heading__withCursor.ABB_CommonUX_Heading__blackwhite:after {
    color: #dbdbdb
}

h1.ABB_CommonUX_Heading__root {
    font-size: 72px;
    line-height: 144px
}

h1.ABB_CommonUX_Heading__root:after {
    top: -72px
}

h2.ABB_CommonUX_Heading__root {
    font-size: 36px;
    line-height: 72px
}

h2.ABB_CommonUX_Heading__root:after {
    top: -36px
}

h3.ABB_CommonUX_Heading__root {
    font-size: 24px;
    line-height: 48px
}

h3.ABB_CommonUX_Heading__root:after {
    top: -24px
}

h4.ABB_CommonUX_Heading__root {
    font-size: 18px;
    line-height: 36px
}

h4.ABB_CommonUX_Heading__root:after {
    top: -18px
}

h5.ABB_CommonUX_Heading__root {
    font-size: 14px;
    line-height: 28px
}

h5.ABB_CommonUX_Heading__root:after {
    top: -14px
}

h6.ABB_CommonUX_Heading__root {
    font-size: 12px;
    line-height: 24px
}

h6.ABB_CommonUX_Heading__root:after {
    top: -12px
}

.ABB_CommonUX_Link__root {
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    text-decoration: none
}

.ABB_CommonUX_Link__root:not(.ABB_CommonUX_Link__preventVisited):visited {
    opacity: .8
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__disabled {
    cursor: not-allowed;
    opacity: .5
}

.ABB_CommonUX_Link__root>.ABB_CommonUX_Link__container,
.ABB_CommonUX_Link__root>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__icon {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: center
}

.ABB_CommonUX_Link__root>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__icon * {
    font-size: inherit !important;
    height: auto !important;
    width: auto !important
}

.ABB_CommonUX_Link__root>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__icon.ABB_CommonUX_Link__iconBefore {
    margin-right: .3em
}

.ABB_CommonUX_Link__root>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__icon.ABB_CommonUX_Link__iconAfter {
    margin-left: .3em
}

.ABB_CommonUX_Link__root:not(.ABB_CommonUX_Link__discreet) {
    text-decoration: none
}

.ABB_CommonUX_Link__root:not(.ABB_CommonUX_Link__discreet):not(.ABB_CommonUX_Link__disabled):hover>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__content {
    text-decoration: underline
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__discreet {
    color: inherit
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__discreet:not(.ABB_CommonUX_Link__disabled):hover>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__content,
.ABB_CommonUX_Link__root.ABB_CommonUX_Link__underlined>.ABB_CommonUX_Link__container>.ABB_CommonUX_Link__content {
    text-decoration: underline
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__light:not(.ABB_CommonUX_Link__discreet) {
    color: #36f
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__light.ABB_CommonUX_Link__disabled {
    color: #1f1f1f
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__dark:not(.ABB_CommonUX_Link__discreet) {
    color: #4c85ff
}

.ABB_CommonUX_Link__root.ABB_CommonUX_Link__dark.ABB_CommonUX_Link__disabled {
    color: #dbdbdb
}

.ABB_CommonUX_LoadingIndicator__root {
    display: block
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar {
    width: 100%
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts {
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 8px;
    position: relative
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts>span {
    left: 0;
    position: absolute;
    top: 0;
    will-change: transform
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts>span.ABB_CommonUX_LoadingIndicator__text {
    opacity: 1
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts>span.ABB_CommonUX_LoadingIndicator__textWhenReady {
    opacity: 0;
    transform: translateY(20px)
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts>span.ABB_CommonUX_LoadingIndicator__fadeOutToAbove {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 2s, transform 1s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__texts>span.ABB_CommonUX_LoadingIndicator__fadeInFromBelow {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 2s, transform 1s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container {
    overflow: hidden;
    position: relative
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back {
    background: transparent;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: calc(100% + 10px)
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div {
    flex: 1;
    margin: 0 10px 0 0;
    overflow: hidden;
    position: relative
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div {
    -webkit-animation: ABB_CommonUX_LoadingIndicator__barSlide 1.2s linear infinite forwards;
    animation: ABB_CommonUX_LoadingIndicator__barSlide 1.2s linear infinite forwards;
    background: #dbdbdb;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: transform
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div:not(.ABB_CommonUX_LoadingIndicator__isInternetExplorer) {
    transform: translate3d(calc(-100% - 1px), 0, 0)
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div.ABB_CommonUX_LoadingIndicator__isInternetExplorer {
    transform: translate3d(-100%, 0, 0)
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:first-child>div {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(2)>div {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(3)>div {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(4)>div {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(5)>div {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(6)>div {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(7)>div {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(8)>div {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(9)>div {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(10)>div {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(11)>div {
    -webkit-animation-delay: 2.2s;
    animation-delay: 2.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(12)>div {
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(13)>div {
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(14)>div {
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(15)>div {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(16)>div {
    -webkit-animation-delay: 3.2s;
    animation-delay: 3.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(17)>div {
    -webkit-animation-delay: 3.4s;
    animation-delay: 3.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(18)>div {
    -webkit-animation-delay: 3.6s;
    animation-delay: 3.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(19)>div {
    -webkit-animation-delay: 3.8s;
    animation-delay: 3.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(20)>div {
    -webkit-animation-delay: 4s;
    animation-delay: 4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(21)>div {
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(22)>div {
    -webkit-animation-delay: 4.4s;
    animation-delay: 4.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(23)>div {
    -webkit-animation-delay: 4.6s;
    animation-delay: 4.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(24)>div {
    -webkit-animation-delay: 4.8s;
    animation-delay: 4.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(25)>div {
    -webkit-animation-delay: 5s;
    animation-delay: 5s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(26)>div {
    -webkit-animation-delay: 5.2s;
    animation-delay: 5.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(27)>div {
    -webkit-animation-delay: 5.4s;
    animation-delay: 5.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(28)>div {
    -webkit-animation-delay: 5.6s;
    animation-delay: 5.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(29)>div {
    -webkit-animation-delay: 5.8s;
    animation-delay: 5.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(30)>div {
    -webkit-animation-delay: 6s;
    animation-delay: 6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(31)>div {
    -webkit-animation-delay: 6.2s;
    animation-delay: 6.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(32)>div {
    -webkit-animation-delay: 6.4s;
    animation-delay: 6.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(33)>div {
    -webkit-animation-delay: 6.6s;
    animation-delay: 6.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(34)>div {
    -webkit-animation-delay: 6.8s;
    animation-delay: 6.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(35)>div {
    -webkit-animation-delay: 7s;
    animation-delay: 7s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(36)>div {
    -webkit-animation-delay: 7.2s;
    animation-delay: 7.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(37)>div {
    -webkit-animation-delay: 7.4s;
    animation-delay: 7.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(38)>div {
    -webkit-animation-delay: 7.6s;
    animation-delay: 7.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(39)>div {
    -webkit-animation-delay: 7.8s;
    animation-delay: 7.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(40)>div {
    -webkit-animation-delay: 8s;
    animation-delay: 8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(41)>div {
    -webkit-animation-delay: 8.2s;
    animation-delay: 8.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(42)>div {
    -webkit-animation-delay: 8.4s;
    animation-delay: 8.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(43)>div {
    -webkit-animation-delay: 8.6s;
    animation-delay: 8.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(44)>div {
    -webkit-animation-delay: 8.8s;
    animation-delay: 8.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(45)>div {
    -webkit-animation-delay: 9s;
    animation-delay: 9s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(46)>div {
    -webkit-animation-delay: 9.2s;
    animation-delay: 9.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(47)>div {
    -webkit-animation-delay: 9.4s;
    animation-delay: 9.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(48)>div {
    -webkit-animation-delay: 9.6s;
    animation-delay: 9.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(49)>div {
    -webkit-animation-delay: 9.8s;
    animation-delay: 9.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(50)>div {
    -webkit-animation-delay: 10s;
    animation-delay: 10s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(51)>div {
    -webkit-animation-delay: 10.2s;
    animation-delay: 10.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(52)>div {
    -webkit-animation-delay: 10.4s;
    animation-delay: 10.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(53)>div {
    -webkit-animation-delay: 10.6s;
    animation-delay: 10.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(54)>div {
    -webkit-animation-delay: 10.8s;
    animation-delay: 10.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(55)>div {
    -webkit-animation-delay: 11s;
    animation-delay: 11s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(56)>div {
    -webkit-animation-delay: 11.2s;
    animation-delay: 11.2s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(57)>div {
    -webkit-animation-delay: 11.4s;
    animation-delay: 11.4s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(58)>div {
    -webkit-animation-delay: 11.6s;
    animation-delay: 11.6s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(59)>div {
    -webkit-animation-delay: 11.8s;
    animation-delay: 11.8s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div:nth-child(60)>div {
    -webkit-animation-delay: 12s;
    animation-delay: 12s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width .3s;
    width: 0
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front.ABB_CommonUX_LoadingIndicator__noMotion {
    transition: none
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__small>.ABB_CommonUX_LoadingIndicator__container {
    height: 4px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__small>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div {
    margin-right: 2px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__medium>.ABB_CommonUX_LoadingIndicator__container {
    height: 8px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__medium>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div {
    margin-right: 1px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__large>.ABB_CommonUX_LoadingIndicator__container {
    height: 16px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__large>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div {
    margin-right: 1px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__red>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front {
    background: #ff000f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__blue>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front {
    background: #36f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__grey>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front {
    background: #bababa
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__indeterminate.ABB_CommonUX_LoadingIndicator__red>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div {
    background: #ff000f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__indeterminate.ABB_CommonUX_LoadingIndicator__blue>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div {
    background: #36f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__indeterminate.ABB_CommonUX_LoadingIndicator__grey>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div {
    background: #dbdbdb
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__light {
    color: #1f1f1f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__dark {
    color: #dbdbdb
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial {
    border-radius: 100%;
    color: #1f1f1f;
    display: block;
    position: relative
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div.ABB_CommonUX_LoadingIndicator__isInternetExplorer {
    -webkit-animation: ABB_CommonUX_LoadingIndicator__rotation 3s linear infinite;
    animation: ABB_CommonUX_LoadingIndicator__rotation 3s linear infinite;
    transform-origin: center
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg {
    position: relative
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg>g {
    transform-origin: center
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg>g>circle {
    fill: transparent;
    transform-origin: center
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg>g>circle.ABB_CommonUX_LoadingIndicator__back {
    stroke: #dbdbdb
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg>.ABB_CommonUX_LoadingIndicator__front {
    fill: transparent;
    transform: rotate(270deg);
    transform-origin: center;
    transition: stroke-dashoffset .3s linear
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>div>svg>.ABB_CommonUX_LoadingIndicator__front.ABB_CommonUX_LoadingIndicator__noMotion {
    transition: none
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__text,
.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__textWhenReady {
    height: 0;
    margin-top: calc(50% - 1em);
    overflow: visible;
    position: absolute;
    text-align: center;
    width: 100%
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__text {
    opacity: 1
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__textWhenReady {
    opacity: 0;
    transform: translateY(20px)
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__fadeOutToAbove {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 2s, transform 1s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial>.ABB_CommonUX_LoadingIndicator__value>.ABB_CommonUX_LoadingIndicator__fadeInFromBelow {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 2s, transform 1s
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__small {
    height: 32px;
    width: 32px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__small>div>svg circle {
    stroke-width: 3px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__medium {
    font-size: 12px;
    height: 64px;
    width: 64px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__medium>div>svg circle {
    stroke-width: 4px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__large {
    font-size: 14px;
    height: 128px;
    width: 128px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__large>div>svg circle {
    stroke-width: 8px
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__red>div>svg>.ABB_CommonUX_LoadingIndicator__front {
    stroke: #ff000f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__blue>div>svg>.ABB_CommonUX_LoadingIndicator__front {
    stroke: #36f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__determinate.ABB_CommonUX_LoadingIndicator__grey>div>svg>.ABB_CommonUX_LoadingIndicator__front {
    stroke: #bababa
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__red:not(.ABB_CommonUX_LoadingIndicator__determinate)>div>svg>g>.ABB_CommonUX_LoadingIndicator__back {
    stroke: #ff000f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__blue:not(.ABB_CommonUX_LoadingIndicator__determinate)>div>svg>g>.ABB_CommonUX_LoadingIndicator__back {
    stroke: #36f
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__grey:not(.ABB_CommonUX_LoadingIndicator__determinate)>div>svg>g>.ABB_CommonUX_LoadingIndicator__back {
    stroke: #bababa
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark {
    color: #dbdbdb
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__radial>div>svg>g>.ABB_CommonUX_LoadingIndicator__back {
    stroke: #464646
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__grey:not(.ABB_CommonUX_LoadingIndicator__determinate)>div>svg>g>.ABB_CommonUX_LoadingIndicator__back {
    stroke: #868686
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__bar.ABB_CommonUX_LoadingIndicator__grey>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__front {
    background: #868686
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__bar>.ABB_CommonUX_LoadingIndicator__container>.ABB_CommonUX_LoadingIndicator__back>div>div {
    background: #464646
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__barSlide {
    0% {
        transform: translate3d(calc(-100% - 1px), 0, 0);
        will-change: transform
    }

    25% {
        transform: translateZ(0);
        will-change: transform
    }

    to {
        transform: translate3d(100%, 0, 0);
        will-change: transform
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__barSlide {
    0% {
        transform: translate3d(calc(-100% - 1px), 0, 0);
        will-change: transform
    }

    25% {
        transform: translateZ(0);
        will-change: transform
    }

    to {
        transform: translate3d(100%, 0, 0);
        will-change: transform
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small1 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small1 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small2 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small2 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small3 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small3 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small4 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small4 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small5 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small5 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small6 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small6 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small7 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small7 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small8 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small8 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small9 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small9 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small10 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small10 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small11 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small11 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small12 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small12 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small13 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small13 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 75.1;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small14 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small14 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 80.4;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 75.1;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small15 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(1turn)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small15 {
    0.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 80.4;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 75.1;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 80.4;
        transform: rotate(1turn)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium1 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium1 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium2 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium2 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium3 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium3 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium4 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium4 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium5 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium5 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium6 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium6 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium7 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium7 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium8 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium8 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium9 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium9 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium10 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium10 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium11 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium11 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium12 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium12 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium13 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium13 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 150.1;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium14 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium14 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 160.8;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 150.1;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium15 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(1turn)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium15 {
    0.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 160.8;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 150.1;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 160.8;
        transform: rotate(1turn)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large1 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large1 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(0deg)
    }

    3.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(0deg)
    }

    33.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large2 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large2 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    3.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(24deg)
    }

    6.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(24deg)
    }

    36.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large3 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large3 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    6.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(48deg)
    }

    10.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(48deg)
    }

    40.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large4 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large4 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    10.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(72deg)
    }

    13.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(72deg)
    }

    43.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large5 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large5 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    13.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(96deg)
    }

    16.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(96deg)
    }

    46.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large6 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large6 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    16.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(120deg)
    }

    20.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(120deg)
    }

    50.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large7 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large7 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    20.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(144deg)
    }

    23.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(144deg)
    }

    53.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large8 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large8 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    23.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(168deg)
    }

    26.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(168deg)
    }

    56.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large9 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large9 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    26.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(192deg)
    }

    30.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(192deg)
    }

    60.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large10 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large10 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    30.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(216deg)
    }

    33.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(216deg)
    }

    63.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large11 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large11 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    33.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(240deg)
    }

    36.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(240deg)
    }

    66.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large12 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large12 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    36.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(264deg)
    }

    40.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(264deg)
    }

    70.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large13 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large13 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    40.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(288deg)
    }

    43.3% {
        stroke-dashoffset: 300.3;
        transform: rotate(288deg)
    }

    73.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large14 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large14 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    43.3% {
        stroke-dashoffset: 321.7;
        transform: rotate(312deg)
    }

    46.7% {
        stroke-dashoffset: 300.3;
        transform: rotate(312deg)
    }

    76.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large15 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(1turn)
    }
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large15 {
    0.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    46.7% {
        stroke-dashoffset: 321.7;
        transform: rotate(336deg)
    }

    50.0% {
        stroke-dashoffset: 300.3;
        transform: rotate(336deg)
    }

    80.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(1turn)
    }

    100.0% {
        stroke-dashoffset: 321.7;
        transform: rotate(1turn)
    }
}

.ABB_CommonUX_LoginScreen__root {
    max-height: 100vh;
    min-height: 360px;
    overflow: hidden;
    position: relative;
    width: 720px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__loadingIndicator {
    position: absolute;
    width: 100%
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header {
    display: block;
    height: 16px;
    position: absolute;
    text-align: center;
    top: 16px;
    width: 100%
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
    background: url("data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23ff000f%27 viewBox=%27-1 -1 88.2 35%27><path d=%27M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27 y=%2717%27/><path d=%27M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27/><path d=%27M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27 y=%2717%27/><path d=%27M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27/><polygon points=%275.7,17 0,33 8.3,33 10.7,26 16,26 16,17%27/><polygon points=%2716,0 11.7,0 6,16 16,16%27/><polygon points=%2717,26 22.3,26 24.7,33 33,33 27.3,17 17,17%27/><polygon points=%2727,16 21.3,0 17,0 17,16%27/></svg>") no-repeat 0;
    background-size: contain;
    height: 22px;
    position: absolute;
    right: 32px;
    top: 22px;
    white-space: nowrap;
    width: 61px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo>span {
    line-height: 50px;
    padding: 0 15px 0 100px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper {
    box-sizing: border-box;
    min-height: 360px;
    padding: 64px 32px 0;
    position: relative
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    min-height: 64px;
    position: relative;
    white-space: nowrap
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName:before {
    color: #ff000f;
    content: "\2014";
    display: block;
    font-size: inherit
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productSubName {
    font-size: 14px;
    font-weight: 700;
    margin: 12px 0 0;
    min-height: 20px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__form {
    min-width: 240px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__form>.ABB_CommonUX_LoginScreen__formRow {
    margin-top: 32px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__form>.ABB_CommonUX_LoginScreen__formRow:first-child {
    margin-top: 42px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__form>.ABB_CommonUX_LoginScreen__formRow:last-child {
    margin-bottom: 40px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__form>.ABB_CommonUX_LoginScreen__formRow>.ABB_CommonUX_LoginScreen__password {
    margin-top: 8px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__buttons>.ABB_CommonUX_LoginScreen__button {
    margin-bottom: 8px;
    margin-right: 16px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__flexSpring {
    flex: 10
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__copyright {
    font-size: 12px;
    margin-bottom: 24px;
    margin-right: 32px;
    white-space: nowrap
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__footer {
    padding: 12px 16px
}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__footer>div {
    font-size: 12px
}

.ABB_CommonUX_LoginScreen__root:not(.ABB_CommonUX_LoginScreen__loading) {
    transition: all .8s ease-in-out
}

.ABB_CommonUX_LoginScreen__root:not(.ABB_CommonUX_LoginScreen__loading)>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content {
    transition: all .6s
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__loading:not(.ABB_CommonUX_LoginScreen__noMaxHeight) {
    max-height: 360px
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__loading>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content {
    transform: translateY(146px)
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light {
    background: #fff;
    background: url(bundled_assets/bg-light-6a790b869cd0a22cb0b4.png) no-repeat top;
    background-color: #fff
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__copyright,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productSubName,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__header {
    color: #1f1f1f
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light>.ABB_CommonUX_LoginScreen__footer {
    background: #fff;
    border-top: 1px solid silver;
    color: #1f1f1f
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__light.ABB_CommonUX_LoginScreen__monochrome>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName:before {
    color: #1f1f1f
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark {
    background: #333;
    background: url(bundled_assets/bg-dark-22dcaf1008ef540f45d6.png) no-repeat top;
    background-color: #333
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content .ABB_CommonUX_LoginScreen__bottom>.ABB_CommonUX_LoginScreen__copyright,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productSubName,
.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark>.ABB_CommonUX_LoginScreen__header {
    color: #dbdbdb
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__dark>.ABB_CommonUX_LoginScreen__footer {
    background: #333;
    border-top: 1px solid silver;
    color: #dbdbdb
}

.ABB_CommonUX_LoginScreen__root.ABB_CommonUX_LoginScreen__monochrome>.ABB_CommonUX_LoginScreen__contentWrapper>.ABB_CommonUX_LoginScreen__content>.ABB_CommonUX_LoginScreen__productName:before {
    color: #dbdbdb
}

.ABB_CommonUX_Logo__root {
    background-size: contain;
    height: 100%;
    margin: auto;
    width: 100%
}

.ABB_CommonUX_Logo__root.ABB_CommonUX_Logo__red {
    background: url("data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23ff000f%27 viewBox=%27-1 -1 88.2 35%27><path d=%27M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27 y=%2717%27/><path d=%27M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2736%27/><path d=%27M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27 y=%2717%27/><path d=%27M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z%27/><rect width=%2710%27 height=%2716%27 x=%2763%27/><polygon points=%275.7,17 0,33 8.3,33 10.7,26 16,26 16,17%27/><polygon points=%2716,0 11.7,0 6,16 16,16%27/><polygon points=%2717,26 22.3,26 24.7,33 33,33 27.3,17 17,17%27/><polygon points=%2727,16 21.3,0 17,0 17,16%27/></svg>") no-repeat
}

.ABB_CommonUX_Logo__root.ABB_CommonUX_Logo__light {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_Logo__root.ABB_CommonUX_Logo__dark {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="-1 -1 88.2 35"><path d="M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z"/><rect width="10" height="16" x="36" y="17"/><path d="M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z"/><rect width="10" height="16" x="36"/><path d="M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z"/><rect width="10" height="16" x="63" y="17"/><path d="M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z"/><rect width="10" height="16" x="63"/><polygon points="5.7,17 0,33 8.3,33 10.7,26 16,26 16,17"/><polygon points="16,0 11.7,0 6,16 16,16"/><polygon points="17,26 22.3,26 24.7,33 33,33 27.3,17 17,17"/><polygon points="27,16 21.3,0 17,0 17,16"/></svg>') no-repeat
}

.ABB_CommonUX_Menu__root {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 0;
    position: absolute;
    z-index: 1
}

.ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__alignLeft {
    left: 0
}

.ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__alignRight {
    right: 0
}

.ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__light {
    background-color: #fff;
    box-shadow: 0 6px 12px hsla(0, 0%, 6%, .35);
    color: #1f1f1f
}

.ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__dark {
    background-color: #1f1f1f;
    border: 1px solid #696969;
    box-shadow: 0 6px 18px hsla(0, 0%, 6%, .5);
    color: #dbdbdb
}

.ABB_CommonUX_Menu__subMenuPopup {
    position: absolute !important
}

.ABB_CommonUX_Menu__subMenuPopup.ABB_CommonUX_Menu__light {
    background: #fff;
    box-shadow: 0 6px 12px hsla(0, 0%, 6%, .35)
}

.ABB_CommonUX_Menu__subMenuPopup.ABB_CommonUX_Menu__dark {
    background: #1f1f1f;
    border: 1px solid #696969;
    box-shadow: 0 6px 18px hsla(0, 0%, 6%, .5)
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__light {
    color: #1f1f1f
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__light:not(.ABB_CommonUX_Menu__disabled)>div:hover {
    background: #ebebeb
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__disabled {
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__dark {
    color: #dbdbdb
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__dark:not(.ABB_CommonUX_Menu__disabled)>div:hover {
    background: #333
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__disabled {
    color: hsla(0, 0%, 86%, .5)
}

.ABB_CommonUX_Menu__subMenuHeader {
    cursor: pointer
}

.ABB_CommonUX_Menu__subMenuHeader.ABB_CommonUX_Menu__disabled {
    cursor: not-allowed
}

.ABB_CommonUX_Menu__subMenuHeader>div {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 32px;
    white-space: nowrap
}

.ABB_CommonUX_Menu__subMenuHeader>div .ABB_CommonUX_Menu__subMenuHeaderIcon {
    display: inline-flex;
    margin-left: 16px
}

.ABB_CommonUX_Menu__subMenuHeader>div .ABB_CommonUX_Menu__subMenuHeaderTitle {
    flex: 2;
    margin-left: 16px;
    margin-right: 8px;
    text-align: left
}

.ABB_CommonUX_Menu__subMenuHeader>div .ABB_CommonUX_Menu__expandIcon {
    display: inline-flex;
    margin-right: 8px
}

.ABB_CommonUX_Menu__menuItem {
    cursor: pointer;
    height: 32px;
    padding: 0;
    position: relative
}

.ABB_CommonUX_Menu__menuItem,
.ABB_CommonUX_Menu__menuItem>* {
    align-items: center;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    width: 100%
}

.ABB_CommonUX_Menu__menuItem>* {
    height: 100%
}

.ABB_CommonUX_Menu__menuItem>*>* {
    display: inline-block;
    flex: 0
}

.ABB_CommonUX_Menu__menuItem>*>.ABB_CommonUX_Menu__menuItemIcon {
    display: inline-flex;
    margin-left: 16px
}

.ABB_CommonUX_Menu__menuItem>*>.ABB_CommonUX_Menu__menuItemTitle {
    flex: 2;
    margin-left: 16px;
    margin-right: 8px;
    text-align: left
}

.ABB_CommonUX_Menu__menuItem>a,
.ABB_CommonUX_Menu__menuItem>a:visited {
    color: inherit;
    text-decoration: none
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__disabled {
    cursor: not-allowed;
    pointer-events: none
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light:not(.ABB_CommonUX_Menu__disabled) {
    color: #1f1f1f
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light:not(.ABB_CommonUX_Menu__disabled):hover {
    background: #ebebeb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light:not(.ABB_CommonUX_Menu__disabled):active {
    background: #dbdbdb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__disabled {
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__selected {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__selected:hover {
    background: #36f
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected {
    background: #dbdbdb;
    color: #1f1f1f
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected:hover {
    background: #dbdbdb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected.ABB_CommonUX_Menu__disabled {
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__monochrome:not(.ABB_CommonUX_Menu__disabled):active,
.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__light.ABB_CommonUX_Menu__monochrome:not(.ABB_CommonUX_Menu__disabled):hover {
    background: #ebebeb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark:not(.ABB_CommonUX_Menu__disabled) {
    color: #dbdbdb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark:not(.ABB_CommonUX_Menu__disabled):hover {
    background: #333
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark:not(.ABB_CommonUX_Menu__disabled):active {
    background: #464646
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__disabled {
    color: hsla(0, 0%, 86%, .5)
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__selected {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__selected:hover {
    background: #36f
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected {
    background: #464646;
    color: #dbdbdb
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected:hover {
    background: #464646
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__monochrome.ABB_CommonUX_Menu__selected.ABB_CommonUX_Menu__disabled {
    color: hsla(0, 0%, 86%, .5)
}

.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__monochrome:not(.ABB_CommonUX_Menu__disabled):active,
.ABB_CommonUX_Menu__menuItem.ABB_CommonUX_Menu__dark.ABB_CommonUX_Menu__monochrome:not(.ABB_CommonUX_Menu__disabled):hover {
    background: #333
}

.ABB_CommonUX_Menu__divider {
    background-color: #bababa;
    height: 1px;
    line-height: 0;
    margin: 8px 0;
    overflow: hidden;
    padding: 0
}

.rc-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.rc-menu:focus {
    outline: none
}

.rc-menu.rc-menu-hidden {
    display: none
}

.ABB_CommonUX_MenuBar__root {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
}

.ABB_CommonUX_MenuBar__itemRoot {
    flex: 0;
    font-size: 12px;
    padding: 8px 12px
}

.ABB_CommonUX_MenuBar__itemRoot.ABB_CommonUX_MenuBar__open,
.ABB_CommonUX_MenuBar__itemRoot:hover {
    background: #36f
}

.ABB_CommonUX_MessageDialog__messageDialogDimensions {
    min-width: 200px
}

.ABB_CommonUX_MessageDialog__messageDialogRoot {
    padding-bottom: 8px;
    padding-right: 16px
}

.ABB_CommonUX_MessageDialog__messageDialogRoot>.ABB_CommonUX_MessageDialog__message {
    margin-right: 24px;
    min-height: 40px
}

.ABB_CommonUX_MessageDialog__messageDialogRoot>.ABB_CommonUX_MessageDialog__buttons {
    margin-left: 32px;
    text-align: right
}

.ABB_CommonUX_MessageDialog__messageDialogRoot>.ABB_CommonUX_MessageDialog__buttons>.ABB_CommonUX_MessageDialog__messageDialogButton {
    margin-left: 16px;
    margin-top: 8px;
    min-width: 100px
}

.ABB_CommonUX_Notification__root {
    font-size: 16px;
    margin-right: 16px;
    margin-top: 8px;
    max-width: 520px;
    overflow: hidden;
    pointer-events: auto;
    transition: max-height .5s, margin .5s
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__closing {
    margin-bottom: 0;
    margin-top: 0;
    max-height: 0 !important
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner {
    max-height: 200px;
    min-width: 320px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner>div:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 48px;
    text-align: center;
    vertical-align: middle
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner>div:first-child>.ABB_CommonUX_Notification__iconContainer {
    height: 24px;
    margin: 12px;
    vertical-align: middle
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner>div:first-child>.ABB_CommonUX_Notification__text {
    flex: 2;
    margin: 12px 16px 12px 0;
    max-height: 176px;
    overflow: auto
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner>div:first-child>.ABB_CommonUX_Notification__controls {
    margin-right: 20px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__success {
    background: #0ca919;
    color: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__info {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__warn {
    background: #ff7300;
    color: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__alarm {
    background: #f03040;
    color: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__discreet {
    background: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__discreet.ABB_CommonUX_Notification__success>div>.ABB_CommonUX_Notification__iconContainer {
    color: #0ca919
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__discreet.ABB_CommonUX_Notification__info>div>.ABB_CommonUX_Notification__iconContainer {
    color: #36f
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__discreet.ABB_CommonUX_Notification__warn>div>.ABB_CommonUX_Notification__iconContainer {
    color: #ff7300
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner.ABB_CommonUX_Notification__discreet.ABB_CommonUX_Notification__alarm>div>.ABB_CommonUX_Notification__iconContainer {
    color: #f03040
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation {
    max-height: 400px;
    min-width: 400px;
    position: relative
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation>div:first-child {
    display: flex;
    flex-direction: row
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation>div:first-child>.ABB_CommonUX_Notification__iconContainer {
    height: 32px;
    margin: 20px 20px 0 18px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation>div:first-child>.ABB_CommonUX_Notification__text {
    flex: 1;
    margin-top: 24px;
    max-height: 120px;
    min-height: 48px;
    min-width: 266px;
    overflow: auto;
    padding-right: 64px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation:after {
    content: " ";
    height: 4px;
    left: -1px;
    position: absolute;
    right: -1px;
    top: 0
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__success:after {
    background: #0ca919
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__success>div>.ABB_CommonUX_Notification__iconContainer {
    color: #0ca919
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__info:after {
    background: #36f
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__info>div>.ABB_CommonUX_Notification__iconContainer {
    color: #36f
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__warn:after {
    background: #ff7300
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__warn>div>.ABB_CommonUX_Notification__iconContainer {
    color: #ff7300
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__alarm:after {
    background: #f03040
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation.ABB_CommonUX_Notification__alarm>div>.ABB_CommonUX_Notification__iconContainer {
    color: #f03040
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation>.ABB_CommonUX_Notification__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 8px 8px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__confirmation>.ABB_CommonUX_Notification__buttons>* {
    margin-left: 16px
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__light {
    box-shadow: 0 6px 12px 0 hsla(0, 0%, 6%, .35)
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__light.ABB_CommonUX_Notification__discreet .ABB_CommonUX_Notification__text {
    color: #1f1f1f
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__light.ABB_CommonUX_Notification__confirmation {
    background: #fff
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__dark {
    box-shadow: 0 6px 18px 0 hsla(0, 0%, 6%, .5)
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__dark.ABB_CommonUX_Notification__discreet .ABB_CommonUX_Notification__text {
    color: #dbdbdb
}

.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__dark.ABB_CommonUX_Notification__confirmation,
.ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__dark.ABB_CommonUX_Notification__discreet {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_NotificationContainer__root {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    position: fixed;
    
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 32px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container .ABB_CommonUX_Pagination__popupLinks {
    font-feature-settings: "tnum";
    border-bottom: 1px solid #bababa;
    cursor: pointer;
    display: inline-block;
    font-variant-numeric: tabular-nums;
    white-space: nowrap
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container .ABB_CommonUX_Pagination__popupLinks:hover {
    border-bottom-color: #9f9f9f
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container .ABB_CommonUX_Pagination__popupLinks.ABB_CommonUX_Pagination__isOpen {
    border-bottom-color: #36f
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 32px;
    margin-right: 32px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount>span:not(last-child) {
    margin-right: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation {
    display: flex;
    flex-wrap: wrap
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageCount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 32px;
    margin-right: 32px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageCount>span:not(last-child) {
    margin-right: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons {
    display: flex
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__ellipsis {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    text-align: center;
    width: 50px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__ellipsis .ABB_CommonUX_Pagination__centeredDots {
    height: 100%;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__ellipsis .ABB_CommonUX_Pagination__centeredDots>span {
    left: 5px;
    line-height: 0px;
    position: absolute;
    top: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton {
    font-feature-settings: "tnum";
    display: inline-block;
    font-variant-numeric: tabular-nums;
    margin: 0 4px;
    position: relative
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__leftCaret {
    margin-right: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__firstPageButton {
    margin-left: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__lastPageButton {
    margin-right: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__rightCaret {
    margin-left: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton:last-child {
    margin-right: 0
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__firstNumberButton {
    margin-left: 8px
}

.ABB_CommonUX_Pagination__root>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageButtons>.ABB_CommonUX_Pagination__pageButton.ABB_CommonUX_Pagination__selected:after {
    background: #36f;
    bottom: 0;
    content: " ";
    height: 2px;
    left: calc(50% - 8px);
    position: absolute;
    width: 16px
}

.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__light>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageCount,
.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__light>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount {
    color: #1f1f1f
}

.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__light>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount .ABB_CommonUX_Pagination__rowsPerPagePopup>p {
    color: #696969
}

.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__dark>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__pageNavigation>.ABB_CommonUX_Pagination__pageCount,
.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__dark>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount {
    color: #dbdbdb
}

.ABB_CommonUX_Pagination__root.ABB_CommonUX_Pagination__dark>.ABB_CommonUX_Pagination__container>.ABB_CommonUX_Pagination__rowCount .ABB_CommonUX_Pagination__rowsPerPagePopup>p {
    color: #868686
}

.ABB_CommonUX_Pagination__rowsPerPagePopup {
    font-family: ABBVoice;
    font-size: 14px;
    padding: 8px 0
}

.ABB_CommonUX_Pagination__rowsPerPagePopup>p {
    line-height: 16px;
    margin: 0;
    padding: 8px 16px
}

.ABB_CommonUX_Pagination__rowsPerPagePopup>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.ABB_CommonUX_Pagination__rowsPerPagePopup>ul>li {
    cursor: pointer;
    line-height: 16px;
    margin: 0;
    padding: 8px 16px
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__light>ul>li {
    background: #fff
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__light>ul>li:hover {
    background: #ebebeb
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__light>ul>li.ABB_CommonUX_Pagination__selected {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__dark>ul>li {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__dark>ul>li:hover {
    background: #464646
}

.ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__dark>ul>li.ABB_CommonUX_Pagination__selected {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Pagination__goToPage>div:last-child {
    align-items: flex-end;
    display: flex
}

abb-popup.ABB_CommonUX_Pagination__pagination .ABB_CommonUX_Pagination__rowsPerPagePopup {
    font-family: ABBVoice;
    font-size: 14px;
    padding: 8px 0
}

abb-popup.ABB_CommonUX_Pagination__pagination .ABB_CommonUX_Pagination__rowsPerPagePopup>p {
    line-height: 16px;
    margin: 0;
    padding: 8px 16px
}

abb-popup.ABB_CommonUX_Pagination__pagination .ABB_CommonUX_Pagination__rowsPerPagePopup>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

abb-popup.ABB_CommonUX_Pagination__pagination .ABB_CommonUX_Pagination__rowsPerPagePopup>ul>li {
    cursor: pointer;
    line-height: 16px;
    margin: 0;
    padding: 8px 16px
}

.ABB_CommonUX_Popup__root:focus {
    outline: none
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__hasPaddings {
    padding: 16px
}

.ABB_CommonUX_Popup__root>.ABB_CommonUX_Popup__header {
    position: absolute;
    right: 4px;
    top: 4px
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__hasCloseButton {
    min-width: 100px
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__hasCloseButton.ABB_CommonUX_Popup__hasPaddings {
    padding-right: 32px
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__hasCloseButton>.ABB_CommonUX_Popup__header {
    height: 24px;
    width: 24px
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__light {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__dark {
    background: #333;
    color: #dbdbdb
}

.ABB_CommonUX_PhasorDiagram__root {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 100%
}

.ABB_CommonUX_PhasorDiagram__root>table {
    flex: 0;
    margin-top: 16px
}

.ABB_CommonUX_PhasorDiagram__root>table>tbody>tr>td {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    min-width: 70px;
    padding: 0 8px
}

.ABB_CommonUX_PhasorDiagram__root>svg {
    flex: 1
}

.ABB_CommonUX_PhasorDiagram__root>svg>.ABB_CommonUX_PhasorDiagram__title {
    font-size: 12pt
}

.ABB_CommonUX_PhasorDiagram__root>svg>.ABB_CommonUX_PhasorDiagram__subtitle {
    font-size: 8pt
}

.ABB_CommonUX_PhasorDiagram__root>svg>defs>radialGradient>stop {
    stop-opacity: 0
}

.ABB_CommonUX_PhasorDiagram__root>svg>defs>radialGradient>stop:last-child {
    stop-opacity: .15
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>text {
    stroke-width: 0;
    font-size: 7pt
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgCircle {
    stroke-width: 1;
    fill: none
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgLine {
    stroke-width: 1
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__opacity20 {
    stroke-opacity: .2
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>g>.ABB_CommonUX_PhasorDiagram__ruler {
    stroke-width: 1;
    stroke-dasharray: 3, 3;
    stroke-opacity: .5
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>svg {
    overflow: visible
}

.ABB_CommonUX_PhasorDiagram__root>svg>g.ABB_CommonUX_PhasorDiagram__background>svg>text {
    text-anchor: end;
    font-size: 8pt
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>.ABB_CommonUX_PhasorDiagram__title {
    fill: #1f1f1f
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>.ABB_CommonUX_PhasorDiagram__subtitle {
    fill: #696969
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>defs>radialGradient>stop {
    stop-color: #000
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>g.ABB_CommonUX_PhasorDiagram__background>text {
    fill: #696969
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgCircle,
.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgLine {
    stroke: #696969
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__light>svg>g.ABB_CommonUX_PhasorDiagram__background>svg>text {
    fill: #1f1f1f
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>.ABB_CommonUX_PhasorDiagram__title {
    fill: #dbdbdb
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>.ABB_CommonUX_PhasorDiagram__subtitle {
    fill: #868686
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>defs>radialGradient>stop {
    stop-color: #fff
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>g.ABB_CommonUX_PhasorDiagram__background>text {
    fill: #868686
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgCircle,
.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>g.ABB_CommonUX_PhasorDiagram__background>.ABB_CommonUX_PhasorDiagram__bgLine {
    stroke: #868686
}

.ABB_CommonUX_PhasorDiagram__root.ABB_CommonUX_PhasorDiagram__dark>svg>g.ABB_CommonUX_PhasorDiagram__background>svg>text {
    fill: #dbdbdb
}

.ABB_CommonUX_RadioGroup__root {
    font-family: ABBvoice;
    font-size: 14px
}

.ABB_CommonUX_RadioGroup__root>label {
    cursor: pointer;
    display: block;
    margin-left: 8px;
    position: relative;
    transition: all .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.ABB_CommonUX_RadioGroup__root>label.ABB_CommonUX_RadioGroup__disabled {
    cursor: not-allowed;
    opacity: .6
}

.ABB_CommonUX_RadioGroup__root>label>span:nth-child(2):before {
    box-sizing: border-box;
    content: " ";
    position: absolute
}

.ABB_CommonUX_RadioGroup__root>label>span:nth-child(2):after {
    box-sizing: border-box;
    content: " ";
    position: absolute;
    transform: scale(0);
    transition: transform .3s
}

.ABB_CommonUX_RadioGroup__root>label>input {
    opacity: 0;
    position: absolute
}

.ABB_CommonUX_RadioGroup__root>label>input:checked+span:nth-child(2):after {
    transform: scale(1)
}

.ABB_CommonUX_RadioGroup__root>label:hover>span:nth-child(2):before {
    content: " "
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__smallSize>label {
    padding: 2px 0
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__smallSize>label>span:nth-child(2):before {
    border-radius: 8px;
    height: 16px;
    left: 2px;
    top: 4px;
    width: 16px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__smallSize>label>span:nth-child(2):after {
    border-radius: 5px;
    height: 6px;
    left: 7px;
    top: 9px;
    width: 6px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__smallSize>label>span:nth-child(3) {
    padding-left: 24px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__smallSize>label:hover>span:nth-child(2):before {
    border-radius: 10px;
    height: 16px;
    width: 16px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label {
    padding: 6px 0
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label>input {
    left: 2px;
    top: 6px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label>span:nth-child(2):before {
    border-radius: 12px;
    height: 24px;
    left: 0;
    top: 4px;
    width: 24px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label>span:nth-child(2):after {
    border-radius: 8px;
    height: 8px;
    left: 8px;
    top: 12px;
    width: 8px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label:hover>span:nth-child(2):before {
    border-radius: 18px;
    height: 24px;
    width: 24px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__largeSize>label>span:nth-child(3) {
    padding-left: 32px
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__horizontal {
    display: flex;
    flex-direction: row
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light {
    color: #1f1f1f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label>span:nth-child(2):before {
    background-color: #fff;
    border: 2px solid #bababa
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label:hover>span:nth-child(2):before {
    background-color: #ebebeb;
    border: 2px solid #9f9f9f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label:active>span:nth-child(2):before {
    background-color: #dbdbdb;
    border: 2px solid #868686
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):before {
    background-color: #ebebeb;
    border: 2px solid #dbdbdb
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):after {
    background-color: #1f1f1f;
    border-color: #1f1f1f;
    opacity: .5
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked+span:nth-child(2):before {
    background-color: #36f;
    border: 2px solid #36f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked:hover+span:nth-child(2):before {
    background-color: #2a35ff;
    border: 2px solid #2a35ff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked:active+span:nth-child(2):before {
    background-color: #2c20d4;
    border: 2px solid #2c20d4
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light>label>span:nth-child(2):after {
    background-color: #fff;
    border: 3px solid #fff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label:active>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label:hover>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>span:nth-child(2):before {
    background-color: #fff;
    border: 1px solid #0f0f0f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:active+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:hover+span:nth-child(2):before {
    background-color: #0f0f0f;
    border: 1px solid #0f0f0f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked+span:nth-child(2):before.ABB_CommonUX_RadioGroup__disabled,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:active+span:nth-child(2):before.ABB_CommonUX_RadioGroup__disabled,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:hover+span:nth-child(2):before.ABB_CommonUX_RadioGroup__disabled {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled {
    color: #bababa;
    opacity: 1
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled:active>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled:hover>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):before {
    border: 1px solid #dbdbdb
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked:active+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__light.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked:hover+span:nth-child(2):before {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark {
    color: #dbdbdb
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label>span:nth-child(2):before {
    background-color: #1f1f1f;
    border: 2px solid #464646
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label:hover>span:nth-child(2):before {
    background-color: #333;
    border: 2px solid #696969
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label:active>span:nth-child(2):before {
    background-color: #464646;
    border: 2px solid #868686
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):before {
    background-color: #333;
    border: 2px solid #464646
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):after {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    opacity: .5
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked+span:nth-child(2):before {
    background-color: #36f;
    border: 2px solid #36f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked:hover+span:nth-child(2):before {
    background-color: #4c85ff;
    border: 2px solid #4c85ff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label:not(.ABB_CommonUX_RadioGroup__disabled)>input:checked:active+span:nth-child(2):before {
    background-color: #77a3fc;
    border: 2px solid #77a3fc
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark>label>span:nth-child(2):after {
    background-color: #fff;
    border: 3px solid #fff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label:active>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label:hover>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label>span:nth-child(2):before {
    background-color: #0f0f0f;
    border: 1px solid #fff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label>input:checked+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:active+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label>input:checked:hover+span:nth-child(2):before {
    background-color: #fff;
    border: 1px solid #fff
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label>span:nth-child(2):after {
    background-color: #0f0f0f;
    border: 3px solid #0f0f0f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled {
    color: #9f9f9f
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled:active>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled:hover>span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):before {
    background-color: transparent;
    border: 1px solid #464646
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked:active+span:nth-child(2):before,
.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>input:checked:hover+span:nth-child(2):before {
    background-color: #464646;
    border: 1px solid #464646
}

.ABB_CommonUX_RadioGroup__root.ABB_CommonUX_RadioGroup__dark.ABB_CommonUX_RadioGroup__monochrome>label.ABB_CommonUX_RadioGroup__disabled>span:nth-child(2):after {
    background-color: #dbdbdb;
    border: 3px solid #dbdbdb
}

.ABB_CommonUX_SidebarNavi__root {
    display: flex;
    flex-direction: column
}

.ABB_CommonUX_SidebarNavi__root.ABB_CommonUX_SidebarNavi__light {
    background: #ebebeb
}

.ABB_CommonUX_SidebarNavi__root.ABB_CommonUX_SidebarNavi__dark {
    background: #0f0f0f
}

.ABB_CommonUX_SidebarNaviTile__root {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%
}

.ABB_CommonUX_SidebarNaviTile__root>p {
    font-size: 12px;
    margin: 0
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__thumbs {
    height: 48px;
    width: 48px
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__pictograms {
    height: 88px;
    width: 96px
}

.ABB_CommonUX_SidebarNaviTile__root:after {
    background: transparent;
    bottom: 0;
    content: " ";
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 4px
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light {
    background: #ebebeb
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light.ABB_CommonUX_SidebarNaviTile__active,
.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light.ABB_CommonUX_SidebarNaviTile__secondary {
    background: #dbdbdb
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light.ABB_CommonUX_SidebarNaviTile__active:after {
    background: #36f
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light:hover {
    background: #dbdbdb
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light:active {
    background: #bababa
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark,
.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__light.ABB_CommonUX_SidebarNaviTile__monochrome.ABB_CommonUX_SidebarNaviTile__active:after {
    background: #0f0f0f
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark.ABB_CommonUX_SidebarNaviTile__secondary {
    background: #333
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark.ABB_CommonUX_SidebarNaviTile__active {
    background: #1f1f1f
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark.ABB_CommonUX_SidebarNaviTile__active:after {
    background: #36f
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark:hover {
    background: #1f1f1f
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark:active {
    background: #333
}

.ABB_CommonUX_SidebarNaviTile__root.ABB_CommonUX_SidebarNaviTile__dark.ABB_CommonUX_SidebarNaviTile__monochrome.ABB_CommonUX_SidebarNaviTile__active:after {
    background: #fff
}

.ABB_CommonUX_Slider__root {
    margin: 10px
}

.ABB_CommonUX_Slider__root>.ABB_CommonUX_Slider__minMaxValues>span:last-child {
    float: right
}

.ABB_CommonUX_Slider__root .ABB_CommonUX_Slider__customTooltip {
    left: 50%;
    margin-bottom: 32px;
    padding: 4px 8px;
    position: absolute;
    transform: translate(-50%, -40px);
    white-space: nowrap
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light {
    color: #696969
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light .rc-slider-track {
    background-color: #dbdbdb
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    background-color: #fff;
    border: 2px solid #36f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #36f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    background-color: #fff;
    border: 2px solid #0f0f0f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #0f0f0f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__minMaxValues {
    color: #1f1f1f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light .ABB_CommonUX_Slider__customTooltip {
    background-color: #fff;
    border: 1px solid #bababa;
    color: #1f1f1f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot {
    background-color: transparent
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    border: 2px solid #9f9f9f !important
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #fff !important
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light.ABB_CommonUX_Slider__double>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background-color: #fff
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light .rc-slider-mark-text,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__light .rc-slider-mark-text-active {
    color: #696969
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark {
    color: #868686
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark .rc-slider-track {
    background-color: #464646
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    background-color: #1f1f1f;
    border: 2px solid #36f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #36f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    background-color: #1f1f1f;
    border: 2px solid #fff
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #fff
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__monochrome>.ABB_CommonUX_Slider__minMaxValues {
    color: #dbdbdb
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark .ABB_CommonUX_Slider__customTooltip {
    background-color: #333;
    border: 1px solid #464646;
    color: #dbdbdb
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark .ABB_CommonUX_Slider__customTooltip:active {
    border: 1px solid #36f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot {
    background-color: transparent
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    border: 2px solid #464646 !important
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__disabled>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background: #333 !important
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark.ABB_CommonUX_Slider__double>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    background-color: #1f1f1f
}

.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark .rc-slider-mark-text,
.ABB_CommonUX_Slider__root.ABB_CommonUX_Slider__dark .rc-slider-mark-text-active {
    color: #868686
}

.ABB_CommonUX_Slider__root>.ABB_CommonUX_Slider__rcSliderRoot {
    font-size: 12px
}

.ABB_CommonUX_Slider__root>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle {
    height: 16px;
    margin-left: -7px;
    margin-top: -6px;
    width: 16px
}

.ABB_CommonUX_Slider__root>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:active,
.ABB_CommonUX_Slider__root>.ABB_CommonUX_Slider__rcSliderRoot>.ABB_CommonUX_Slider__rcSliderHandle:focus {
    box-shadow: none
}

.ABB_CommonUX_Slider__root .rc-slider-tooltip,
.ABB_CommonUX_Slider__root .rc-slider-tooltip * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.ABB_CommonUX_Slider__root .rc-slider-tooltip>.rc-slider-tooltip-content {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    padding: 0
}

.ABB_CommonUX_Slider__root .rc-slider-tooltip>.rc-slider-tooltip-content>div:first-child {
    opacity: 0
}

.ABB_CommonUX_Slider__root .rc-slider-tooltip>.rc-slider-tooltip-content>div:last-child {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    height: auto;
    padding: 4px
}

.ABB_CommonUX_NumericInput__root {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__label {
    display: block;
    font-weight: 400;
    margin-bottom: 4px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__label>.ABB_CommonUX_NumericInput__requiredAsterisk {
    color: #f03040
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__compact {
    min-width: 48px
}

.ABB_CommonUX_NumericInput__root:not(.ABB_CommonUX_NumericInput__compact) {
    min-width: 96px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container {
    border: 1px solid transparent;
    display: flex;
    height: 32px;
    position: relative
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper {
    align-items: center;
    border: none;
    border-bottom: 1px solid transparent;
    display: flex;
    flex: 1;
    flex-direction: row
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input {
    border: none;
    display: block;
    flex: 1;
    font-size: 14px;
    height: 30px;
    line-height: 16px;
    margin: 1px 8px 0;
    min-width: 16px;
    padding: 0
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input:focus {
    outline: none !important
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input::-webkit-inner-spin-button {
    display: none
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input[type=number] {
    -moz-appearance: textfield
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    flex: 0;
    line-height: 1
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix {
    margin-left: 4px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    margin-right: 4px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper:not(.ABB_CommonUX_NumericInput__compact) {
    width: calc(100% - 64px)
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper:not(.ABB_CommonUX_NumericInput__compact)>input {
    text-align: center
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper.ABB_CommonUX_NumericInput__compact {
    width: calc(100% - 24px)
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 24px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons:not(.ABB_CommonUX_NumericInput__compact) {
    align-items: center;
    justify-content: center;
    margin: 0 4px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__minus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__plus.ABB_CommonUX_NumericInput__disabled {
    pointer-events: none
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__minus:hover,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__plus:hover {
    cursor: pointer
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__up {
    border-radius: 2px;
    display: flex;
    height: 12px;
    justify-content: center
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__down.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__up.ABB_CommonUX_NumericInput__disabled {
    pointer-events: none
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__down:hover,
.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__up:hover {
    cursor: pointer
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__up {
    margin: 2px 4px 1px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__up>.ABB_CommonUX_NumericInput__upIcon {
    position: relative;
    top: -1px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__down {
    margin: 1px 4px 2px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons.ABB_CommonUX_NumericInput__compact>.ABB_CommonUX_NumericInput__down>.ABB_CommonUX_NumericInput__downIcon {
    bottom: 2px;
    position: relative
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__container:after {
    background: transparent;
    bottom: 0;
    bottom: -1px;
    content: " ";
    height: 2px;
    left: -1px;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: calc(100% + 2px)
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__validationMessageContainer {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-top: 4px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__validationMessageContainer.ABB_CommonUX_NumericInput__hidden {
    opacity: 0
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__validationMessageContainer.ABB_CommonUX_NumericInput__validationCollapsed {
    height: 0;
    visibility: hidden
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__validationMessageContainer>.ABB_CommonUX_NumericInput__validationIconWrapper {
    align-items: center;
    color: #f03040;
    cursor: default;
    display: flex;
    height: 16px;
    margin-right: 6px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__validationMessageContainer>.ABB_CommonUX_NumericInput__validationMessage {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px
}

.ABB_CommonUX_NumericInput__root>.ABB_CommonUX_NumericInput__description {
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__hasChanged>.ABB_CommonUX_NumericInput__container:after {
    background: #36f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__isValid>.ABB_CommonUX_NumericInput__container:after {
    background: #0ca919
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__isInvalid>.ABB_CommonUX_NumericInput__container:after {
    background: #f03040
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__isInvalid>.ABB_CommonUX_NumericInput__validationMessageContainer>.ABB_CommonUX_NumericInput__validationMessage {
    color: #f03040
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light>.ABB_CommonUX_NumericInput__description,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light>.ABB_CommonUX_NumericInput__label,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light>.ABB_CommonUX_NumericInput__validationMessageContainer {
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container {
    background-color: #fff;
    border-color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet {
    background-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact) {
    border-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__hover:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #9f9f9f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact.ABB_CommonUX_NumericInput__focused,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__focused:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #36f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__hover:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #ebebeb;
    border-color: #9f9f9f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__focused:not(.ABB_CommonUX_NumericInput__discreet) {
    border-color: #36f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input {
    background-color: inherit;
    color: #1f1f1f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input::-moz-placeholder {
    color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input::placeholder {
    color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #fff
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up {
    color: #0f0f0f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up:hover {
    background-color: #ebebeb;
    border-color: #9f9f9f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up:active {
    background-color: #dbdbdb
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up.ABB_CommonUX_NumericInput__disabled {
    border-color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up {
    color: #1f1f1f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up.ABB_CommonUX_NumericInput__disabled {
    opacity: .5
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #f5f5f5;
    border-color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__inputWrapper {
    background-color: #f5f5f5
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input {
    background-color: inherit;
    color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__light.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    color: #bababa
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark>.ABB_CommonUX_NumericInput__description,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark>.ABB_CommonUX_NumericInput__label,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark>.ABB_CommonUX_NumericInput__validationMessageContainer {
    color: #868686
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container {
    background-color: #1f1f1f;
    border-color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet {
    background-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact) {
    border-color: transparent
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__hover:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #868686
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact.ABB_CommonUX_NumericInput__focused,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__focused:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #36f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__hover:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #333;
    border-color: #868686
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__focused:not(.ABB_CommonUX_NumericInput__discreet) {
    border-color: #36f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input {
    background-color: inherit;
    color: #dbdbdb
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input::-moz-placeholder {
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input::placeholder {
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #1f1f1f
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up {
    color: #fff
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus:hover,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up:hover {
    background-color: #333;
    border-color: #868686
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus:active,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up:active {
    background-color: #464646
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up.ABB_CommonUX_NumericInput__disabled {
    border-color: #464646
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up {
    color: #dbdbdb
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down.ABB_CommonUX_NumericInput__disabled,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark:not(.ABB_CommonUX_NumericInput__disabled)>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up.ABB_CommonUX_NumericInput__disabled {
    opacity: .5
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet) {
    background-color: #333;
    border-color: #464646
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet.ABB_CommonUX_NumericInput__compact,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet:not(.ABB_CommonUX_NumericInput__compact)>.ABB_CommonUX_NumericInput__inputWrapper {
    border-bottom-color: #464646
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>input {
    background-color: inherit;
    color: #696969
}

.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container.ABB_CommonUX_NumericInput__discreet>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__down,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__minus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__plus,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container:not(.ABB_CommonUX_NumericInput__discreet)>.ABB_CommonUX_NumericInput__buttons>.ABB_CommonUX_NumericInput__up,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__prefix,
.ABB_CommonUX_NumericInput__root.ABB_CommonUX_NumericInput__dark.ABB_CommonUX_NumericInput__disabled>.ABB_CommonUX_NumericInput__container>.ABB_CommonUX_NumericInput__inputWrapper>.ABB_CommonUX_NumericInput__suffix {
    color: #696969
}

.ABB_CommonUX_SystemControls__root {
    -webkit-app-region: drag;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
}

.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__close,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__maximize,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__minimize,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__restore {
    flex: 0 0 auto;
    float: left;
    height: 32px;
    line-height: 30px;
    text-align: center;
    transition: background-color .2s;
    width: 45px
}

.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__close>svg,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__maximize>svg,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__minimize>svg,
.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__restore>svg {
    shape-rendering: crispEdges;
    height: 10px;
    width: 10px
}

.ABB_CommonUX_SystemControls__root>.ABB_CommonUX_SystemControls__close>svg>polygon {
    transition: fill .2s
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__minimize>svg * {
    stroke: #1f1f1f
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__close>svg *,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__maximize>svg *,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__restore>svg * {
    fill: #1f1f1f
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__restore>svg>mask>rect {
    fill: #fff
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__restore>svg>mask>path {
    fill: #000
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__maximize:hover,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__minimize:hover,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__restore:hover {
    background-color: #ebebeb
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__close:hover {
    background-color: rgba(232, 17, 35, .9)
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__light>.ABB_CommonUX_SystemControls__close:hover>svg>polygon {
    fill: #000
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__minimize>svg * {
    stroke: #dbdbdb
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__close>svg *,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__maximize>svg *,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__restore>svg * {
    fill: #dbdbdb
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__restore>svg>mask>rect {
    fill: #1f1f1f
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__restore>svg>mask>path {
    fill: #fff
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__maximize:hover,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__minimize:hover,
.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__restore:hover {
    background-color: #333
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__close:hover {
    background-color: rgba(232, 17, 35, .9)
}

.ABB_CommonUX_SystemControls__root.ABB_CommonUX_SystemControls__dark>.ABB_CommonUX_SystemControls__close:hover>svg>polygon {
    fill: #000
}

.ABB_CommonUX_Table__container {
    background: inherit;
    max-height: 100%
}

.ABB_CommonUX_Table__container>div {
    background: inherit;
    height: 100%
}

.ABB_CommonUX_Table__root {
    background: inherit;
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: ABBVoice;
    margin: 0;
    padding: 0;
    table-layout: fixed;
    vertical-align: baseline
}

.ABB_CommonUX_Table__root * {
    box-sizing: border-box
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__scrollableBody {
    border-collapse: inherit;
    table-layout: auto;
    width: 100%
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__scrollableBody>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__scrollableBody>thead>tr>th {
    position: -webkit-sticky;
    position: sticky;
    top: 0
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__scrollableBody>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__scrollableBody>tfoot>tr>th {
    bottom: 0;
    position: -webkit-sticky;
    position: sticky
}

.ABB_CommonUX_Table__root>thead {
    background: inherit;
    text-transform: uppercase
}

.ABB_CommonUX_Table__root>thead>tr {
    background: inherit
}

.ABB_CommonUX_Table__root>thead>tr>th {
    background: inherit;
    border-bottom: 2px solid transparent;
    font-family: ABBVoice-medium;
    font-size: 12px;
    font-style: normal;
    height: 40px;
    line-height: 1;
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Table__root>thead>tr>th.ABB_CommonUX_Table__activeHeader {
    position: relative
}

.ABB_CommonUX_Table__root>thead>tr>th.ABB_CommonUX_Table__activeHeader:after {
    background: transparent;
    bottom: -1px;
    content: " ";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0
}

.ABB_CommonUX_Table__root>tbody {
    width: 100%
}

.ABB_CommonUX_Table__root>tbody>tr>td:first-child {
    margin-top: 20px
}

.ABB_CommonUX_Table__root>tbody>tr>td:not(:last-child) {
    border-right: 1px solid transparent
}

.ABB_CommonUX_Table__root>tbody>tr>td,
.ABB_CommonUX_Table__root>tbody>tr>th {
    border-bottom: 1px solid transparent;
    font-size: 14px;
    line-height: 20px
}

.ABB_CommonUX_Table__root>tfoot,
.ABB_CommonUX_Table__root>tfoot>tr {
    background: inherit
}

.ABB_CommonUX_Table__root>tfoot>tr>td,
.ABB_CommonUX_Table__root>tfoot>tr>th {
    background: inherit;
    border-top: 2px solid transparent;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 1;
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Table__root tr>td,
.ABB_CommonUX_Table__root tr>td.ABB_CommonUX_Table__leftAlign,
.ABB_CommonUX_Table__root tr>th,
.ABB_CommonUX_Table__root tr>th.ABB_CommonUX_Table__leftAlign {
    text-align: left
}

.ABB_CommonUX_Table__root tr>td.ABB_CommonUX_Table__rightAlign,
.ABB_CommonUX_Table__root tr>th.ABB_CommonUX_Table__rightAlign {
    text-align: right
}

.ABB_CommonUX_Table__root tr>td.ABB_CommonUX_Table__centerAlign,
.ABB_CommonUX_Table__root tr>th.ABB_CommonUX_Table__centerAlign {
    text-align: center
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr {
    height: 32px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>th {
    padding: 4px 16px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__medium>tbody>tr {
    height: 40px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__medium>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__medium>tbody>tr>th {
    padding: 8px 16px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__large>tbody>tr {
    height: 48px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__large>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__large>tbody>tr>th {
    padding: 12px 16px
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__customHeight>tbody>tr {
    height: var(--custom-row-height)
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__customHeight>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__customHeight>tbody>tr>th {
    padding-left: 16px;
    padding-right: 16px
}

.ABB_CommonUX_Table__root:not(.ABB_CommonUX_Table__fitToContent)>tbody>tr>td,
.ABB_CommonUX_Table__root:not(.ABB_CommonUX_Table__fitToContent)>tbody>tr>th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light {
    color: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground {
    background: #fff
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>tfoot>tr>th,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>thead>tr>th {
    background-color: #fff
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground .ABB_CommonUX_Table__highlight {
    background: #fafafa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr {
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr:nth-child(odd) {
    background: #fafafa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr:nth-child(odd)>.ABB_CommonUX_Table__highlight {
    background: #f5f5f5
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>tbody>tr.ABB_CommonUX_Table__zebraRow {
    background: #fafafa;
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__solidBackground>tbody>tr.ABB_CommonUX_Table__zebraRow>.ABB_CommonUX_Table__highlight {
    background: #f5f5f5
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>thead>tr>th {
    color: #696969
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>thead>tr>td.ABB_CommonUX_Table__activeHeader:after,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>thead>tr>th.ABB_CommonUX_Table__activeHeader:after {
    background: #36f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>tbody>tr:hover>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>tbody>tr:hover>th {
    background: #ebebeb !important
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light>tfoot>tr>th {
    color: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>thead>tr>th {
    border-bottom-color: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>tbody>tr:not(:last-child)>th {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernormal>tfoot>tr>th {
    border-top-color: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>thead>tr>th {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>tbody>tr:not(:last-child)>th {
    border-bottom-color: #ebebeb
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__borderdiscreet>tfoot>tr>th {
    border-top-color: #bababa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>thead>tr>th {
    border-bottom-color: #bababa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>tbody>tr:last-child>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>tbody>tr:last-child>th,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>tbody>tr:not(:last-child)>th {
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>foot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__light.ABB_CommonUX_Table__bordernone>foot>tr>th {
    border-top-color: #bababa
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark {
    color: #dbdbdb
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground {
    background: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>tfoot>tr>th,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>thead>tr>th {
    background-color: #1f1f1f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground .ABB_CommonUX_Table__highlight {
    background: #242424
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr {
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr:nth-child(odd) {
    background: #242424
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground.ABB_CommonUX_Table__zebra>tbody>tr:nth-child(odd)>.ABB_CommonUX_Table__highlight {
    background: #292929
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>tbody>tr.ABB_CommonUX_Table__zebraRow {
    background: #242424;
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__solidBackground>tbody>tr.ABB_CommonUX_Table__zebraRow>.ABB_CommonUX_Table__highlight {
    background: #292929
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>thead>tr>th {
    color: #9f9f9f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>thead>tr>td.ABB_CommonUX_Table__activeHeader:after,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>thead>tr>th.ABB_CommonUX_Table__activeHeader:after {
    background: #36f
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>tbody>tr:hover>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>tbody>tr:hover>th {
    background: #333 !important
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark>tfoot>tr>th {
    color: #dbdbdb
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>thead>tr>th {
    border-bottom-color: #dbdbdb
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>tbody>tr:not(:last-child)>th {
    border-bottom-color: #696969
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernormal>tfoot>tr>th {
    border-top-color: #dbdbdb
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>thead>tr>th {
    border-bottom-color: #696969
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>tbody>tr:not(:last-child)>th {
    border-bottom-color: #333
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>tfoot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__borderdiscreet>tfoot>tr>th {
    border-top-color: #696969
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>thead>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>thead>tr>th {
    border-bottom-color: #696969
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>tbody>tr:last-child>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>tbody>tr:last-child>th,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>tbody>tr:not(:last-child)>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>tbody>tr:not(:last-child)>th {
    border-bottom-color: transparent
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>foot>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__dark.ABB_CommonUX_Table__bordernone>foot>tr>th {
    border-top-color: #696969
}

.ABB_CommonUX_Tag__root {
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    line-height: 14px;
    min-height: 24px;
    min-width: 32px;
    padding: 0 4px 0 8px;
    text-align: center;
    white-space: nowrap
}

.ABB_CommonUX_Tag__root:not(.ABB_CommonUX_Tag__closeable) {
    padding-right: 8px
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__success {
    background: #0ca919;
    color: #fff
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__info {
    background: #36f;
    color: #fff
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__warn {
    background: #ff7300;
    color: #fff
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__alarm {
    background: #f03040;
    color: #fff
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__discreet,
.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__ghost {
    background: transparent;
    border: 1px solid transparent
}

.ABB_CommonUX_Tag__root>span:first-child {
    overflow: hidden;
    text-overflow: ellipsis
}

.ABB_CommonUX_Tag__root>.ABB_CommonUX_Tag__closeIcon {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 8px
}

.ABB_CommonUX_Tag__root>.ABB_CommonUX_Tag__closeIcon:hover {
    background: hsla(0, 0%, 53%, .3)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light.ABB_CommonUX_Tag__normal {
    background: #dbdbdb;
    color: #1f1f1f
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light.ABB_CommonUX_Tag__discreet {
    border-color: #1f1f1f;
    color: #1f1f1f
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light.ABB_CommonUX_Tag__discreet:hover {
    background: hsla(0, 0%, 53%, .2)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light.ABB_CommonUX_Tag__ghost {
    color: #1f1f1f
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light.ABB_CommonUX_Tag__ghost:hover {
    background: hsla(0, 0%, 53%, .2);
    border-color: #1f1f1f
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light>.ABB_CommonUX_Tag__closeIcon:hover {
    background: hsla(0, 0%, 53%, .3)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__light>.ABB_CommonUX_Tag__closeIcon:active {
    background: hsla(0, 0%, 53%, .6)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark.ABB_CommonUX_Tag__normal {
    background: #464646;
    color: #dbdbdb
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark.ABB_CommonUX_Tag__discreet {
    border-color: #dbdbdb;
    color: #dbdbdb
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark.ABB_CommonUX_Tag__discreet:hover {
    background: hsla(0, 0%, 62%, .2)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark.ABB_CommonUX_Tag__ghost {
    color: #dbdbdb
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark.ABB_CommonUX_Tag__ghost:hover {
    background: hsla(0, 0%, 62%, .2);
    border-color: #dbdbdb
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark>.ABB_CommonUX_Tag__closeIcon:hover {
    background: hsla(0, 0%, 62%, .3)
}

.ABB_CommonUX_Tag__root.ABB_CommonUX_Tag__dark>.ABB_CommonUX_Tag__closeIcon:active {
    background: hsla(0, 0%, 62%, .6)
}

.ABB_CommonUX_ToggleButton__root {
    border-radius: 0;
    border-right: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: bottom
}

.ABB_CommonUX_ToggleButton__root>label {
    cursor: pointer;
    display: block;
    height: 100%;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.ABB_CommonUX_ToggleButton__root>label>input {
    position: absolute;
    visibility: hidden
}

.ABB_CommonUX_ToggleButton__root>label>span {
    display: inline-block
}

.ABB_CommonUX_ToggleButton__root>label>span.ABB_CommonUX_ToggleButton__text {
    font-family: ABBVoice;
    line-height: 16px;
    vertical-align: bottom
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__textOnly {
    min-width: 100px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__textOnly>label>.ABB_CommonUX_ToggleButton__text {
    text-align: center;
    width: 100%
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__iconAndText {
    min-width: 100px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__first {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__first.ABB_CommonUX_ToggleButton__selected:after {
    border-radius: 0 0 0 4px
}

.ABB_CommonUX_ToggleButton__root:not(.ABB_CommonUX_ToggleButton__first) {
    margin-left: -1px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__last {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__last.ABB_CommonUX_ToggleButton__selected:after {
    border-radius: 0 0 4px 0
}

.ABB_CommonUX_ToggleButton__root:not(.ABB_CommonUX_ToggleButton__last):after {
    content: " ";
    height: calc(100% + 2px);
    position: absolute;
    right: -1px;
    top: -1px;
    width: 1px;
    z-index: 1
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__disabled,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__disabled>label {
    cursor: not-allowed
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight {
    height: 32px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight.ABB_CommonUX_ToggleButton__iconOnly {
    width: 32px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight.ABB_CommonUX_ToggleButton__iconOnly>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 6px 7px 7px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight.ABB_CommonUX_ToggleButton__textOnly>label {
    padding: 7px 16px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 0 8px 7px 7px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__smallHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__text {
    padding: 7px 15px 7px 0
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight {
    height: 40px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight.ABB_CommonUX_ToggleButton__iconOnly {
    width: 40px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight.ABB_CommonUX_ToggleButton__iconOnly>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 10px 11px 11px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight.ABB_CommonUX_ToggleButton__textOnly>label {
    padding: 11px 16px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 0 12px 11px 11px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__mediumHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__text {
    padding: 11px 23px 11px 0
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight {
    font-size: 16px;
    height: 48px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight.ABB_CommonUX_ToggleButton__iconOnly {
    width: 48px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight.ABB_CommonUX_ToggleButton__iconOnly>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 10px 11px 11px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight.ABB_CommonUX_ToggleButton__textOnly>label {
    padding: 15px 32px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__icon {
    padding: 0 12px 11px 11px
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__largeHeight.ABB_CommonUX_ToggleButton__iconAndText>label>.ABB_CommonUX_ToggleButton__text {
    padding: 15px 31px 15px 0
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light {
    background: #fff;
    border: 1px solid #9f9f9f;
    color: #1f1f1f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light:hover {
    background-color: #ebebeb;
    border: 1px solid #868686
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light:active {
    background-color: #dbdbdb;
    border: 1px solid #696969
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected {
    background-color: #36f;
    border-color: #36f;
    color: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected:after {
    background: #36f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: #2a35ff;
    border-color: #2a35ff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #2a35ff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected:active {
    background-color: #2c20d4;
    border-color: #2c20d4
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected:active:after {
    background: #2c20d4
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after {
    background: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected {
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    color: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: #0f0f0f;
    border-color: #0f0f0f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #0f0f0f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after {
    background: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled:active,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled:hover {
    background-color: #ebebeb;
    border-color: #bababa;
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:active,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: hsla(0, 0%, 92%, .5);
    border-color: #bababa;
    color: rgba(31, 31, 31, .5)
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:active:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:hover:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:active:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__light.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #bababa
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark {
    background: #333;
    border: 1px solid #464646;
    color: #dbdbdb
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark:hover {
    background-color: #333;
    border: 1px solid #696969
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark:active {
    background-color: #464646;
    border: 1px solid #868686
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected {
    background-color: #36f;
    border-color: #36f;
    color: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected:after {
    background: #36f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: #4c85ff;
    border-color: #4c85ff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #4c85ff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected:active {
    background-color: #77a3fc;
    border-color: #77a3fc
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected:active:after {
    background: #77a3fc
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after {
    background: #1f1f1f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected {
    background-color: #fff;
    border-color: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: #fff;
    border-color: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #fff
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__monochrome.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after {
    background: #1f1f1f
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled:active,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled:hover {
    background-color: #333;
    border-color: #696969;
    color: hsla(0, 0%, 86%, .5)
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:active,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:hover {
    background-color: #464646;
    border-color: #868686;
    color: hsla(0, 0%, 86%, .5)
}

.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:active:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected.ABB_CommonUX_ToggleButton__nextIsSelected:hover:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:active:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:after,
.ABB_CommonUX_ToggleButton__root.ABB_CommonUX_ToggleButton__dark.ABB_CommonUX_ToggleButton__disabled.ABB_CommonUX_ToggleButton__selected:hover:after {
    background: #696969
}

.ABB_CommonUX_ToggleButtonGroup__container {
    display: block;
    list-style-type: none;
    padding: 0;
    white-space: nowrap
}

.ABB_CommonUX_ToggleSwitch__root {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin: 4px;
    position: relative;
    transition: all .5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap
}

.ABB_CommonUX_ToggleSwitch__root>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #bababa;
    border: 1px solid #bababa;
    border-radius: 12px;
    box-sizing: border-box;
    height: 24px;
    margin-right: 8px;
    min-width: 48px;
    transition: all .3s
}

.ABB_CommonUX_ToggleSwitch__root>.ABB_CommonUX_ToggleSwitch__control:after {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    color: #1f1f1f;
    content: " ";
    font-family: ABB-UI-Icons-16x16;
    font-size: 16px;
    height: 20px;
    left: 2px;
    line-height: 20px;
    padding-left: 2px;
    position: absolute;
    top: 2px;
    transform: translateX(0);
    transition: all .3s cubic-bezier(.18, .24, .29, .96);
    width: 20px
}

.ABB_CommonUX_ToggleSwitch__root>.ABB_CommonUX_ToggleSwitch__label {
    color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after {
    background-color: #fff;
    border-color: #36f;
    color: #36f;
    transform: translateX(24px)
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__disabled {
    cursor: not-allowed
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #1f1f1f;
    opacity: .5
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__label {
    opacity: .5
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__showIcons>.ABB_CommonUX_ToggleSwitch__control:after {
    content: "\e925"
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__showIcons.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after {
    content: "\e922"
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light>.ABB_CommonUX_ToggleSwitch__label {
    color: #1f1f1f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #bababa;
    border-color: #bababa
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #9f9f9f;
    border-color: #9f9f9f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #868686;
    border-color: #868686
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #2a35ff;
    border-color: #2a35ff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #2a35ff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #2c20d4;
    border-color: #2c20d4
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #2c20d4
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover {
    background-color: #fff;
    border-color: #0f0f0f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover:after {
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    color: #fff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #0f0f0f;
    border-color: #0f0f0f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after {
    background-color: #fff;
    color: #0f0f0f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:hover {
    background-color: #fff;
    border-color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:hover:after {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    color: #fff;
    opacity: 1
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:hover {
    background-color: #dbdbdb;
    border-color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:hover:after {
    background-color: #fff;
    border-color: #dbdbdb;
    color: #696969;
    opacity: 1
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark>.ABB_CommonUX_ToggleSwitch__label {
    color: #dbdbdb
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #696969;
    border-color: #696969
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #464646;
    border-color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #868686;
    border-color: #868686
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #9f9f9f;
    border-color: #9f9f9f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #36f;
    border-color: #36f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #464646;
    border-color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #4c85ff;
    border-color: #4c85ff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):hover>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #77a3fc
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control {
    background-color: #77a3fc
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__dark.ABB_CommonUX_ToggleSwitch__switchedOn:not(.ABB_CommonUX_ToggleSwitch__disabled):active>.ABB_CommonUX_ToggleSwitch__control:after {
    color: #77a3fc
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover {
    background-color: #0f0f0f;
    border-color: #fff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover:after {
    background-color: #fff;
    border-color: #fff;
    color: #0f0f0f
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover {
    background-color: #fff;
    border-color: #fff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:not(.ABB_CommonUX_ToggleSwitch__disabled):hover:after {
    background-color: #0f0f0f;
    border-color: #fff;
    color: #fff
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:hover {
    background-color: transparent;
    border-color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled>.ABB_CommonUX_ToggleSwitch__control:hover:after {
    background-color: #464646;
    border-color: #464646;
    color: #9f9f9f;
    opacity: 1
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:active,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:hover {
    background-color: #464646;
    border-color: #464646
}

.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:active:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:after,
.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__monochrome.ABB_CommonUX_ToggleSwitch__disabled.ABB_CommonUX_ToggleSwitch__switchedOn>.ABB_CommonUX_ToggleSwitch__control:hover:after {
    background-color: #9f9f9f;
    border-color: #464646;
    color: #dbdbdb;
    opacity: 1
}

.ABB_CommonUX_Tooltip__trigger {
    display: inline-block
}

.ABB_CommonUX_Tooltip__container {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: none;
    font-size: 14px;
    outline: none;
    padding: 0 !important;
    pointer-events: none;
    transition: opacity .3s ease-out
}

.ABB_CommonUX_Tooltip__container:after,
.ABB_CommonUX_Tooltip__container:before {
    content: normal;
    opacity: 0
}

.ABB_CommonUX_Tooltip__container:focus {
    outline: none
}

.ABB_CommonUX_Tooltip__container.show {
    opacity: 1
}

.ABB_CommonUX_Tooltip__container>div>.ABB_CommonUX_Tooltip__content {
    padding: 4px
}

.ABB_CommonUX_Tooltip__container>div>.ABB_CommonUX_Tooltip__content.ABB_CommonUX_Tooltip__clickable {
    pointer-events: all
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__alignLeft>div {
    transform: translateX(50%)
}

.ABB_CommonUX_Tooltip__container:not(.ABB_CommonUX_Tooltip__alignLeft)>div>.ABB_CommonUX_Tooltip__arrow {
    box-shadow: none;
    height: 16px;
    left: 0;
    margin-left: calc(50% - 12px);
    position: absolute;
    transform: rotate(225deg);
    width: 16px;
    z-index: -1
}

.ABB_CommonUX_Tooltip__container:not(.ABB_CommonUX_Tooltip__alignLeft).place-bottom>div>.ABB_CommonUX_Tooltip__arrow {
    top: -4px
}

.ABB_CommonUX_Tooltip__container:not(.ABB_CommonUX_Tooltip__alignLeft).place-top>div>.ABB_CommonUX_Tooltip__arrow {
    bottom: -4px
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__light>div {
    background: #fff
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__light>div>.ABB_CommonUX_Tooltip__content {
    background: #fff;
    color: #1f1f1f
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__light.ABB_CommonUX_Tooltip__alignLeft>div>.ABB_CommonUX_Tooltip__content {
    border: 1px solid #bababa;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .35) !important
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__light:not(.ABB_CommonUX_Tooltip__alignLeft) {
    background: #fff;
    border: 1px solid #bababa;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .35) !important
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__light:not(.ABB_CommonUX_Tooltip__alignLeft)>div>.ABB_CommonUX_Tooltip__arrow {
    background: #fff;
    border: 1px solid #bababa
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__dark>div {
    background: #1f1f1f
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__dark>div>.ABB_CommonUX_Tooltip__content {
    background: #1f1f1f;
    color: #dbdbdb
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__dark.ABB_CommonUX_Tooltip__alignLeft>div>.ABB_CommonUX_Tooltip__content {
    border: 1px solid #333;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .5) !important
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__dark:not(.ABB_CommonUX_Tooltip__alignLeft) {
    background: #1f1f1f;
    border: 1px solid #333;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .5) !important
}

.ABB_CommonUX_Tooltip__container.ABB_CommonUX_Tooltip__dark:not(.ABB_CommonUX_Tooltip__alignLeft)>div>.ABB_CommonUX_Tooltip__arrow {
    background: #1f1f1f;
    border: 1px solid #333
}

.ABB_CommonUX_Helpers__margins-xs {
    margin: 4px
}

.ABB_CommonUX_Helpers__margins-s {
    margin: 8px
}

.ABB_CommonUX_Helpers__margins-m {
    margin: 16px
}

.ABB_CommonUX_Helpers__margins-l {
    margin: 32px
}

.ABB_CommonUX_Helpers__paddings-xs {
    padding: 4px
}

.ABB_CommonUX_Helpers__paddings-s {
    padding: 8px
}

.ABB_CommonUX_Helpers__paddings-m {
    padding: 16px
}

.ABB_CommonUX_Helpers__paddings-l {
    padding: 32px
}

.ABB_CommonUX_Resizer__root {
    height: 100%;
    position: relative;
    width: 100%
}

.ABB_CommonUX_Resizer__root>div {
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle {
    background: silver;
    position: absolute
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle:after {
    border: 4px solid transparent;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__top {
    cursor: row-resize;
    height: 10px;
    left: 0;
    right: 0;
    top: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__top:after {
    border-bottom: 0;
    border-top: 6px solid #000
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__bottom {
    bottom: 0;
    cursor: row-resize;
    height: 10px;
    left: 0;
    right: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__bottom:after {
    border-bottom: 6px solid #000;
    border-top: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__left {
    bottom: 0;
    cursor: col-resize;
    left: 0;
    top: 0;
    width: 10px
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__left:after {
    border-left: 6px solid #000;
    border-right: 0
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__right {
    bottom: 0;
    cursor: col-resize;
    right: 0;
    top: 0;
    width: 10px
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__handle.ABB_CommonUX_Resizer__right:after {
    border-left: 0;
    border-right: 6px solid #000
}

.ABB_CommonUX_Resizer__root>div>.ABB_CommonUX_Resizer__content {
    box-sizing: border-box;
    flex: 2;
    overflow: auto;
    position: relative
}

.ABB_CommonUX_Resizer__root>div.ABB_CommonUX_Resizer__hori>.ABB_CommonUX_Resizer__content {
    margin-left: 10px;
    margin-right: 10px
}

.ABB_CommonUX_Resizer__root>div.ABB_CommonUX_Resizer__vert>.ABB_CommonUX_Resizer__content {
    margin-bottom: 10px;
    margin-top: 10px
}