.chart-card {
  display: flex;
  flex-direction: column;
  width: 24%;
  max-width: 452px;
  min-width: 342px;
  height: 290px;
  margin: 8px;
  justify-content: left;
  background: #ffffff;
}

.chart-header {
  left: 32px;
  right: 32px;
  top: 11.03%;
  bottom: 78.28%;
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
}

.chart-title {
  padding: 16px 32px 12px 32px;
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  text-transform: uppercase;
  color: #1f1f1f;
}

.chart-card-small-card {
  display: flex;
  flex-direction: column;
  flex: 30%;
  height: 300px;
  justify-content: left;
  background: #ffffff;
  margin: 8px;
  padding: 10px;
}

.chart-card-medium-card {
  display: flex;
  flex-direction: column;
  flex: 30%;
  height: 616px;
  margin: 8px;
  margin-top: 16px;
  padding: 10px;
  justify-content: left;
  background: #ffffff;
}

.chart-card-large-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 911px;
  min-width: 342px;
  height: 50%;
  margin: 8px;
  background: #ffffff;
}

.chart-card-trends-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 905px;
  min-width: 342px;
  height: 450px;
  margin: 8px;
  background: #ffffff;
}

.chart-card-extra-large-card {
  width: 100%;
  height: 730px;
  margin: 8px;
  background: #ffffff;
}

.nz-card-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 8px;
}

.card-content-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  width: 41%;
  padding: 10px 32px;
}

.card-content-dropdown>.ABB_CommonUX_Dropdown__root {
  width: 100%;
}

@media (max-width: 1440px) and (min-width: 1050px) {
  .chart-card-small-card {
    flex: 33%;
  }

  .chart-card-medium-card {
    flex: 33%;
  }

  .chart-card-large-card {
    flex: 33%;
  }

  .chart-card-trends-card {
    flex: 33%;
    height: 380px;
  }



}

@media only screen and (min-width: 1200px) {
  .chart-card-trends-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 802px;
    min-width: 342px;
    height: 450px;
    margin: 8px;
    background: #ffffff;
  }
}