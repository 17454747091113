.eventlog>.ABB_CommonUX_DonutChart__chart>svg {
    max-height: 80%;
}


.ABB_CommonUX_DonutChart__legend {
    overflow-y: hidden;
    /* height: 162px; */
    max-height: 100%;
    text-overflow: ellipsis !important;
    /* overflow-x: scroll; */
}


.ABB_CommonUX_DonutChart__legend {

    max-width: 200px;
    overflow-x: hidden;
}

.ABB_CommonUX_DonutChart__chart {
    max-width: 124px !important;
}