.indicator-container-line {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: -webkit-fill-available;
}



.enpi-block .indicator-container-line>.ABB_CommonUX_Dropdown__root {
    font-size: 14px;
    text-align: left;
    padding: 8px 16px 0px 0;
    max-width: 182px;

}