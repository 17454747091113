.ABB_CommonUX_DonutChart__legend {
  align-content: flex-start;
  flex: 1 0;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto 24px;
}

.ABB_CommonUX_DonutChart__root > .ABB_CommonUX_DonutChart__chart {
  max-width: 400px !important;
}
/* 
.ABB_CommonUX_DonutChart__root > .ABB_CommonUX_DonutChart__chart > svg {
  width: 200px;
  height: 200px;
  max-width: 300px;
  max-height: 100%;
} */

.ABB_CommonUX_DonutChart__root > .ABB_CommonUX_DonutChart__chart {
  display: block;
  flex: 1 0;
  max-height: 100%;
  max-width: 400px !important;
  min-height: 96px;
  min-width: 96px;
  overflow: hidden;
  margin: auto 0px;
}

.ABB_CommonUX_DonutChart__root > .ABB_CommonUX_DonutChart__chart > svg {
  height: 100%;
  left: 0;
  overflow: visible;
  top: 0;
  width: 100%;
  width: 200px;
  height: 200px;
  max-width: 300px;
  max-height: 100%;
}

.resizable
  .ABB_CommonUX_DonutChart__root
  > .ABB_CommonUX_DonutChart__chart
  > svg {
  height: 100%;
  left: 0;
  overflow: visible;
  top: 0;
  width: 100%;
  max-width: 300px;
  max-height: 100%;
}

/* .resizable
  .ABB_CommonUX_DonutChart__root.ABB_CommonUX_DonutChart__light
  > .ABB_CommonUX_DonutChart__legend
  > .ABB_CommonUX_DonutChart__legendRow
  > span
  > .ABB_CommonUX_DonutChart__legendRowText {
  color: #1f1f1f;
  font-size: "20px";
} */

.co2-emission-container {
  width: 100%;
  height: 100%;
  margin: auto;
}
