.voltage-style {
    left: -36px;
    margin-top: 0px;
    /*   
    background: #FFFFFF; */
}

.voltage-style .recharts-legend-wrapper ul {
    padding: 0px !important;
    margin: 0px !important;
    /* text-align: right !important; */
    text-transform: capitalize;
    margin-left: 220px !important;
}

.zoomout_trend {

    border: 1px solid #d7d7d7;
    background: #FFF;
    padding: 2px 4px;
    float: left;
    margin: 5px 30px;
    color: #232323;
}

.highlight-bar-charts .voltage-style svg.recharts-surface {
    margin-top: 0px;

}


.recharts-legend-wrapper svg.recharts-surface {
    margin-top: 0px !important;
}

.recharts-legend-wrapper {
    top: 0px !important;
    width: 100% !important;
}

.highlight-bar-charts {
    overflow: hidden;
}