.tabparent {
  margin: 1rem 0;
  padding: 1rem;
  background: inherit;
}
.sld-img {
  background: #ffffff;
  height: 30%;
  width: 80%;
}
.main-switch-board-img {
  height: 30%;
  width: 80%;
}
