.output {
    color: #dbdbdb;
    background-color: #1f1f1f;
    width: 100%;
}

.logo {
    width: 80px;
    margin-bottom: 20px;
}

h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

h2 {
    font-size: 24px;
    color: #555;
    margin-bottom: 40px;
}


.col-6 {
    flex: 1;
    padding: 10px;
    font-weight: bold;
}

.col-4 {
    flex: 1;
    padding: 10px;
}

.table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #1f1f1f;
    border-collapse: collapse;

}


.table th,
.table td {
    border: 1px solid #1f1f1f;
    padding: 8px;
}



textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.ABB_CommonUX_TabControl__dark.ABB_CommonUX_TabControl__primary>.ABB_CommonUX_TabControl__content {
    color: #dbdbdb;
    background-color: #333333 !important
}

#proofer-tabs-header.ABB_CommonUX_TabControl__tabItemRoot.ABB_CommonUX_TabControl__activeContent {
    background: #1f1f1f;
}

#proofer-tabs-header.ReactModal__Content.ReactModal__Content--after-open.ABB_CommonUX_Dialog__dialog.ABB_CommonUX_Dialog__dark {

    width: 100%;

}

.proofer-header-container {
    width: 100%;
    height: 540px;

}

.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__closeButton {
    display: block;
    color: azure;
}

#proofer-tabs-header {
    font-size: large;
    font-weight: 500;
    font-family: 'ABBvoice';
}

#table tr td:first {
    width: 250px !important;

}

.output {
    padding: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    display: block;
    font-size: 16px;
    font-family: 'ABBvoice';
    /* Adjust the font size for readability */

}

.table thead {
    background-color: darkgray;
    color: black;
    position: sticky;
    top: -1px;
    z-index: 2;
}

button.sticky-btn {
    position: sticky;
    top: 600px;
    /* Set the desired sticky position */
    z-index: 3;
    /* Ensure it's above the table content */
}

.table-container {
    height: 390px;
    /* Fixed height for the table */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.table-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: darkgray;
    z-index: 2;
    color: black;
}

#deviations-table td:nth-child(3) {
    text-align: left;
}

#spec-summary td:nth-child(2) {
    text-align: left;
}

#gtp-table td:nth-child(2) {
    text-align: left;
}