#group-realtime-power thead {

    grid-template-columns: none !important;
    grid-template-columns: 48px 0.5fr 0.3fr 0.3fr 0.3fr !important
}

#group-realtime-power tbody {

    grid-template-columns: none !important;
    grid-template-columns: 48px 0.55fr 0.3fr 0.3fr 0.3fr !important
}