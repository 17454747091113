.indicator-container-line {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: -webkit-fill-available;
    /* padding-left: 8px; */
}

.peak-block .indicator-container-line>.ABB_CommonUX_Dropdown__root {
    font-size: 14px;
    text-align: left;
    padding: 8px 16px 0px 0;
    max-width: 162px;

}