.component-container {
  width: 100%;
  padding: 20px 0px;
}

.feeder-component {
  height: 70px !important;
  text-align: left !important;
}

.ABB_CommonUX_Gauge__fg {
  background: #4FB0A0 !important;
  width: 12.47 !important
}

.ABB_CommonUX_Gauge__minMax {
  display: none !important;
}