.power-container {
    display: flex;
    flex-direction: column;
    /* Align items in a row with space between */
    align-items: left;
    height: 50vh;
    width: -webkit-fill-available;
    /* Optional: Add padding for spacing */
}

.chart-holder {
    height: 300px !important;
    max-height: 245px !important;
}

.chart-container {
    /* width: 800px; */
    /* Take up remaining space */
    height: 300px !important;

    /* Set the height of the chart container */
}

.power-metrics {
    display: flex;
    flex-direction: column;
}


.power-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}