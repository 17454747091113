.chart-card-3 {
    display: flex;
    flex-direction: column;
    width: 44%;
    max-width: 612px;
    min-width: 342px;
    height: 360px;
    margin: 8px;
    justify-content: left;
    /* overflow-y: auto; */
    background: #FFFFFF;

}

.chart-card-3-em-monitoring {
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 1200px !important;
    min-width: 342px;
    height: 360px;
    margin: 8px;
    justify-content: left;
    /* overflow-y: auto; */
    background:#FFFFFF !important;

}


.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label {
    color: #696969;
    margin: 6px 0px;
    float: left;
    text-align: left;
    white-space: nowrap;
}

.chart-header-3 {
    left: 32px;
    right: 32px;
    top: 11.03%;
    bottom: 78.28%;
    width: 100%;
    display: flex;
}


.chart-title-3 {
    padding: 16px 32px 12px 32px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    text-transform: uppercase;
    color: #1F1F1F;
}

.card-update-notification-3 {
    padding: 5% 5% 5% 13%;
    height: 14px;
    left: 60.62%;
    right: 7.08%;
    top: 32px;

    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    display: flex;
    /* align-items: center; */
    text-align: right;

    color: #686868;
}

.card-header-divider {
    border: 1px solid red;

}

.card-content-3 {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    padding: 0 32px;
}

/* .card-content-scroll-3 {
    overflow-y: scroll;

} */

.card-content-scroll-3 .ABB_CommonUX_Table__root {
    width: 100%;
}

hr {
    border: 1px solid black;
    margin: 0 32px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.card-content-even-align-3 {
    justify-content: space-evenly;
}

.event-log-chart-header-update {
    padding-left: 3%;
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>th {
    padding: 8px 0px;
    line-height: 22px;
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td {
    padding: auto 0px;
}


.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>a>.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label {
    font-size: 24px;
    alignment-baseline: auto;
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: visible;
}


.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart {
    display: block;
    flex: 1 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 96px;
    min-width: 96px;
    overflow: hidden;
    margin: auto 0px;
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg {
    height: 100%;
    left: 0;
    overflow: visible;
    top: 0;
    width: 100%;
    max-width: 124px;
    max-height: 124px;
}