.table-style-alarm-noti {
    width: 100%;
    position: relative !important;
    height: 250px !important;
    background: #FFFFFF !important;
    margin: 0px !important;  /* Applied !important to reduce margin */
    padding: 0px !important; /* Applied !important to reduce padding */
   
}

.ABB_CommonUX_Link__content {
    text-decoration: underline !important;
    
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody {
    border-bottom-color: #FFFFFF !important;
    
    
}

.table-style-alarm-noti .ABB_CommonUX_Datagrid__smallRowHeight thead {
    grid-template-columns: 1fr 1fr 0.8fr !important
        /* grid-template-columns: 2.5fr 3fr 1fr !important */
    

    
}


.table-style-alarm-noti .ABB_CommonUX_Datagrid__smallRowHeight tbody {
    grid-template-columns: 1fr 0.9fr 0.8fr !important
        /* grid-template-columns: 2.5fr 3fr 1fr !important */
       
}

