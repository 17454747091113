.closed {
  color: #0CA919;
  margin: 0px 5px;

}

.open {
  color: #CB2BD5;
  margin: 0px 5px;

}

.tripped {
  color: #FF7300;
  margin: 0px 5px;
}

.failed {
  color: #F03040;
  margin: 0px 5px;
}

.charged {
  color: #0CA919;
  margin: 0px 5px;
}

.discharged {
  color: #FF7300;
  margin: 0px 5px;
}

.very-high {
  color: #F03040;
  margin: 0px 5px;
}

/* .warn-icon {
  color: #FF7300;
}

.error-icon {
  color: #F03040;
} */

/* 
    #ops-status > tbody > tr > td{
      width: 65%;
    } */

.equipmentName {
  width: 60%;
}

.equipmentStatus {
  width: 40%;
}

.ABB_CommonUX_Icon__root {
  position: im !important;
}