.co2-saving-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 4px;
}

.feeder-component {
  height: 70px !important;
  text-align: left !important;
}

.co2-saving-container-left-div {
  height: 70px !important;
  text-align: left !important;
  width: 50%;
  margin: 4px;
  flex-direction: column;
}

.co2-saving-container-right-div {
  height: 70px !important;
  width: 50%;
  margin: 4px;
  justify-content: right;
  flex-direction: row;
}

.divider {
  border: 1px solid lightgray;
  padding-left: 5px;
}

.number {
  font-size: -webkit-xxx-large;
  text-align: right;
  width: 86%;
  margin-bottom: 1px;
}
.co2-saving-container-top-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex;
}
.co2-saving-container-bottom-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
}
