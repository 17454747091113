#energy-data-compare thead {


    grid-template-columns: none !important;
    grid-template-columns: 48px 1.5fr 1fr 1fr !important
}

#energy-data-compare tbody {


    grid-template-columns: none !important;
    grid-template-columns: 48px 1.8fr 1fr 1fr !important
}

#energy-asset-data thead {
    grid-template-columns: none !important;
    grid-template-columns: 1fr 1fr 1fr !important
}

#energy-asset-data tbody {
    grid-template-columns: none !important;
    grid-template-columns: 1fr 1fr 1fr !important
}

.energy-data-compare {
    height: 235px;
    width: 100%;
    width: 550px;
    margin-top: 5px;
}

@media only screen and (min-width: 1200px) {
    .energy-data-compare {
        height: 235px;
        width: 100%;
        width: 450px;
        margin-top: 5px;
    }

}