.filter-row {
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    /* padding: 20px 0px; */

    isolation: isolate;
    position: relative;
    width: 100%;
    /* height: 80px; */
    left: 0px;

}

.css-65p3vd {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
    white-space: pre;
    height: 80px;
}

.filters-group .ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label {
    color: #696969;
    margin: 6px 0px;
    float: left;
    text-align: center;
    white-space: nowrap;
}

.filter-header {

    /* width: 315px; */
    /* height: 25px; */
    left: 32px;
    /* top: 112px; */

    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */
    padding: 12px 0;
    display: flex;
    align-items: center;
    position: relative;
    color: #000000;
}

#time-period-dd .css-1llg279-menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
    background-color: hsl(0, 0%, 100%);
    border-radius: 0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
    margin-bottom: 8px;
    margin-top: 1px;
    box-sizing: border-box;
    margin-left: 0px !important;
    height: 100px;
}

#time-period-dd .css-cz4p66-MenuList {
    max-height: 130px !important;
    overflow-y: auto;
    /* position: inherit; */
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
    white-space: pre;
    z-index: 99999 !important;
    height: 100px;
}

/* .css-15g569d-control {
    margin-left: 0px;
}
.css-1llg279-menu
.css-cz4p66-MenuList {
    margin-left: 0px;
} */

/* .filters-group {
  
    float: right;
    
    display: flex;
   
    flex-direction: row;
} */

.filters-group {
    display: flex;
    justify-content: flex-end;
}

/* .filter-dropdown {
    width:  300px;
    display: flex;
    flex-direction: row;

}    */

.filter-dropdown {
    width: 33%;
    flex-direction: row;
    padding: 10px 8px;
    display: inline-flex;
    margin-left: 0px !important;
}

.react-select-3-listbox {
    background-color: white !important;
    z-index: 99 !important;
    position: absolute;

}

.ABB_CommonUX_Dropdown__comp {
    width: 100%;
}

#react-select-3-listbox>div {
    /* height: 37vh !important; */
    max-height: 37vh !important;
}

/* .css-cz4p66-MenuList{
    height: 37vh !important;
    max-height: 37vh !important;
} */

.ABB_CommonUX_Dropdown__label {
    margin-top: auto;
}

/* 
@media only screen and (min-width: 641px) {
    .filter-header {
        width: 100%;
        padding: 0 32px;
        left: 0px;
    }
    .filters-group{
        padding: 0 22px;
    }
    .ABB_CommonUX_Dropdown__root > .ABB_CommonUX_Dropdown__label {
        color: #696969;
        margin: 6px 0px 6px 22px;
float: left;
width: 100%;
text-align: left;
    }
    .filter-dropdown {
        width: 250px;
    }
      
} */
@media only screen and (max-width: 640px) {
    .filter-dropdown {
        width: 100%;
        padding: 0 32px;
    }

    .ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label {
        color: #696969;
        margin: 6px 12px;
        float: left;
        /* width: 190px; */
        text-align: left;
    }

    .filter-header {
        width: 100%;
    }
}

.ABB_CommonUX_Dropdown__root>.ABB_CommonUX_Dropdown__label {
    color: #696969;
    margin: 6px 12px;
    float: left;
    text-align: left;
    white-space: nowrap;
}