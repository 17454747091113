.gauge-chart-container {
    width: 100px;
    height: 50px;
}

.chart-card .card-content-scroll {
    overflow-y: hidden !important;
}


.indicator-container-top.Dropdown {
    font-size: 14px;
    text-align: left;
    padding-left: 12px;

}

.gauge-chart-container .highcharts-container {
    position: relative;
    /* overflow: hidden; */
    /* width: 364px; */
    /* height: 256px !important; */
    text-align: left;
    line-height: normal;
    z-index: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    touch-action: manipulation;
    outline: none;
}

.score {
    font-size: large;
    font-weight: 600;
}

h1 {
    font-size: 67px;
    line-height: 1.5;
    margin: 0;
    padding: 5px 0;
}

hr.ind-line {
    /* border: 0.5px solid black; */
    margin: 0 0px;
    margin-block-start: 0;
    margin-block-end: 0;

}

.calculations {
    display: contents;
    /* flex-direction: row;
    align-items: center; */
}

.indicator-container-top {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: -webkit-fill-available;
}

.indicator-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.inside-metrics {
    display: block;
    width: 100%;
    float: left;
}

.inside-metrics .im1 {
    width: 80px;
    float: left;
    text-align: left;

}

.inside-metrics .im2 {
    width: 200px;
    float: left;
    text-align: left;

}

.inside-metrics .im3 {
    width: 130px;
    float: left;
    text-align: left;

}

.inside-metrics .im4 {
    width: 120px;
    float: left;
    text-align: left;

}




.indicator-container .ABB_CommonUX_Dropdown__label {
    text-align: left !important;
    width: 100px !important
}

@media only screen and (min-width: 1200px) {
    .widget-container .chart-card-3 {
        width: 594px;

    }

    .indicator-container-top .ABB_CommonUX_Dropdown__light {
        min-width: 100px;
        width: 200px;
    }

    .chart-card-3-em-monitoring {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1051px;
        min-width: 342px;
        height: 360px;
        margin: 8px;
        justify-content: left;
        /* overflow-y: auto; */
        background: #FFFFFF;
    }
}